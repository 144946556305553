import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';
import type { InsuranceProductPeriodResponse } from './types';

export const useHandleInsuranceProductPeriod = (code?: string) => {
  const enabled = Boolean(code);

  return useQuery(
    ['get-insurance-product-period'],
    fetchData<InsuranceProductPeriodResponse>({
      url: Endpoints.GET_INSURANCE_PRODUCT_PERIOD,
      method: 'POST',
      data: {
        baseParameters: {
          productVersion: code,
        },
      },
    }),
    { enabled }
  );
};
