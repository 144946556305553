import type { Driver } from '@smart-components/who-is-driver/types';
import { format } from 'date-fns';
import { parsingDocument } from './parsing-document';
import { DATE_FORMAT, LICENSE_REG_EXP } from '@shared/constants';

export const getVehicleDrivers = (drivers: Driver[]) => {
  return drivers?.map((item) => {
    const [series, number] = parsingDocument(
      item?.license ?? '',
      LICENSE_REG_EXP
    );

    return {
      lastName: item.surname,
      firstName: item.name,
      middleName: item?.middlename as string | undefined,
      birthDate: format(item.birthday ?? new Date(), DATE_FORMAT),
      drivingLicense: {
        licenseSeries: series.replace(' ', ''),
        licenseNumber: number.trim(),
        licenseIssueDate: format(item.licenseDate ?? new Date(), DATE_FORMAT),
        drivingStartDate: Number(item.yearOfStart) || 0,
      },
    };
  });
};
