import { styled } from '@pulse-web-ui/theme';

export const FontContainer = styled.div`
  ${({ theme }) => {
    if (theme.common['font-url']) {
      return `
      * {
        font-family: ${theme.common['font-url']}
      }`;
    } else if (theme.common['font-family']) {
      return `
      * {
        font-family: ${theme.common['font-family']}
      }`;
    }
    return '';
  }};
`;
