import { Button } from '@pulse-web-ui/button';
import { Modal } from '@pulse-web-ui/modal';
import { PAYMENT_MODAL_TIMEOUT } from '@shared/constants';
import { useStores } from '@shared/hooks';
import { scrollToIframe, isAppleSafari } from '@shared/utils';
import { memo, Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import {
  ButtonsContainer,
  StyledStateBlock,
  Container,
} from './b2p-info-modal.styles';
import { i18nDefaultValues } from '../i18n';

const InfoIcon = lazy(() => import('@shared/icons/info'));

const DisplayQRButton = lazy(
  () => import('@pulse-web-ui/display-qr-button/lib/lazy')
);

interface Props {
  showModal: boolean;
  setShowModal: VoidFn<boolean>;
}

const handleMobileAppClickForBrowser = () => {
  const deepLink = window.envUrls.INSURANCE_MOBILE_APP_HOT_LINK;

  isAppleSafari()
    ? (window.location.href = deepLink)
    : window.open(deepLink, '_blank');
};

// TODO: вынести внешний функционал в адаптер

export const B2PInfoModal = memo(({ showModal, setShowModal }: Props) => {
  const { t } = useTranslation();
  const {
    MainStore: {
      applicationStore: { hidePaymentComponent, updateFormValue },
      initProductStore: { setInitOrder, setInitOrderError },
      productStore: {
        formState: { CheckPolicy },
      },
    },
  } = useStores();

  const handleSubmit = useCallback(() => {
    setInitOrder(undefined);
    setInitOrderError(undefined);
    updateFormValue('CheckPolicy', {
      ...CheckPolicy,
      ...{ acceptRequirements: false },
    });
    hidePaymentComponent();
    scrollToIframe();
    setShowModal(false);
  }, [
    CheckPolicy,
    setInitOrder,
    updateFormValue,
    hidePaymentComponent,
    scrollToIframe,
    setShowModal,
  ]);

  const [isTimeOut, setIsTimeOut] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (showModal && !timerId) {
      const timer = setTimeout(() => {
        setIsTimeOut(true);
      }, PAYMENT_MODAL_TIMEOUT);

      setTimerId(timer);

      return () => clearTimeout(timer);
    }
  }, [showModal, timerId]);

  return (
    <Modal
      desktopWidth={760}
      showDialog={showModal}
      closeIcon={false}
      dangerouslyBypassScrollLock
    >
      <StyledStateBlock
        title={t('SMART:B2P.title', {
          defaultValue: isTimeOut
            ? i18nDefaultValues.B2P.titleAfterTimeOut
            : i18nDefaultValues.B2P.title,
        })}
        description={t('SMART:B2P.title', {
          defaultValue: isTimeOut
            ? i18nDefaultValues.B2P.subtitleAfterTimeOut
            : i18nDefaultValues.B2P.subtitle,
        })}
        icon={
          <Suspense>
            <InfoIcon />
          </Suspense>
        }
        onSubmit={handleSubmit}
        content={
          <ButtonsContainer>
            {isTimeOut &&
              (isBrowser ? (
                <Container>
                  <DisplayQRButton
                    buttonLabel={t('COMMON:pay.downloadApp')}
                    dropdownSubtitle={t('COMMON:pay.pointYourCamera')}
                  />
                </Container>
              ) : (
                <Container>
                  <Button
                    variant="primary"
                    label={t('COMMON:pay.downloadApp')}
                    onClick={handleMobileAppClickForBrowser}
                    adaptiveWidth
                  />
                </Container>
              ))}
            <Container>
              <Button
                variant={isTimeOut ? 'secondary' : 'primary'}
                onClick={handleSubmit}
                adaptiveWidth={isTimeOut}
              >
                {t('SMART:B2P.labels.submitButtonText', {
                  defaultValue: i18nDefaultValues.B2P.labels.submitButtonText,
                })}
              </Button>
            </Container>
          </ButtonsContainer>
        }
      />
    </Modal>
  );
});
