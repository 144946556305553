import { RISK_VALUE } from '@shared/constants';
import { type Risk, RisksOptionEnum, type RisksType } from '@shared/types';
import { INSURANSE_SUM_DEFAULT } from '../constants';

export const getRisks = ({
  fullOptionsRisks,
  coverageSum,
}: {
  fullOptionsRisks: RisksType[];
  coverageSum: number | undefined;
}): Risk[] => {
  const checkedRisks = fullOptionsRisks.filter(
    (item) => item.checked && item.type === RisksOptionEnum.risk && item.sum
  );

  const risksFromStore = checkedRisks.map((risk) => ({
    code: risk.code,
    amount: Number(risk?.sum),
  }));

  const additionalRisks: Risk[] =
    risksFromStore.length > 0 ? risksFromStore : [];

  const amountForRegularRisk: number = coverageSum
    ? Number(coverageSum)
    : INSURANSE_SUM_DEFAULT;

  const regularRisks: Risk[] = [
    {
      code: RISK_VALUE,
      amount: amountForRegularRisk,
    },
  ];

  return regularRisks.concat(additionalRisks);
};
