import type { DateFieldProps } from './types';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Datepicker } from '@pulse-web-ui/datepicker';

export const DateField = ({
  label,
  value,
  onChange,
  error,
  disabled,
  maxDate,
  testid,
}: DateFieldProps) => {
  return (
    <HelperText
      noMargin
      status={error ? 'error' : 'default'}
      message={error?.message}
    >
      <Datepicker
        onChange={onChange}
        showYearDropdown
        showMonthDropdown
        disabledKeyboardNavigation
        showInput
        selected={value}
        label={label}
        error={!!error}
        disabled={disabled}
        maxDate={maxDate}
        data-testid={testid}
      />
    </HelperText>
  );
};
