import { useEffect } from 'react';

export const useHandlePressKey = (
  key: number,
  handler: () => void,
  ...otherDeps: any[]
) => {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.keyCode === key) {
        event.preventDefault();
        handler();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [...otherDeps]);
};
