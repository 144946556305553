import { type AxiosError, isAxiosError } from 'axios';
import { useEffect } from 'react';

import type { QueryStatus } from 'react-query';

import { useStores } from './use-stores';

interface Props {
  status: QueryStatus;
  refetch: VoidFn;
  error: AxiosError<{ code: string }> | null;
  isLoading: boolean;
}

export const useHandleErrorRequest = ({
  status,
  refetch,
  error,
  isLoading,
}: Props) => {
  const {
    MainStore: {
      errorStore: { setHasError, setRefetch, setIsLoading },
    },
  } = useStores();

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isAxiosError(error)) {
      const status = error.response?.status;
      const code = error.response?.data?.code;

      if (
        (status === 400 && code === 'TECHNICAL_ERROR') ||
        Number(status) >= 500 ||
        !status
      ) {
        setHasError(true);
        setRefetch(refetch);
      }
    } else if (status === 'success') {
      setHasError(false);
      setRefetch(undefined);
    }
  }, [error, refetch, status]);
};
