import type { Option } from '@pulse-web-ui/autocomplete-select';
import type { Car } from '@shared/types';
import type { InferType } from 'yup';

import { getCarSchema } from './schema';

export enum CarDataKey {
  BRAND = 'brand',
  MODEL = 'model',
  MANUFACTURE_YEAR = 'manufactureYear',
  POWER = 'power',
}

export interface CarData {
  [CarDataKey.BRAND]?: Partial<Option>;
  [CarDataKey.MODEL]?: Partial<Option>;
  [CarDataKey.MANUFACTURE_YEAR]?: Partial<Option>;
  [CarDataKey.POWER]?: Partial<Option>;
}

const schema = getCarSchema();

export type CarValues = InferType<typeof schema>;

export interface ValidationContext {
  minPrice?: number;
  maxPrice?: number;
  isManualCarInput?: boolean;
  minProductLimit: number;
  maxProductLimit: number;
  isCarNumberRequired: boolean;
}

export interface CarOptions {
  onFindCar: () => void;
  car: Car;
  minProductLimit: number;
  maxProductLimit: number;
  onFindBrands: () => void;
  brands?: Option[];
  onFindModels: (brandId: string) => void;
  models?: Option[];
  onFindYears: (modelId: string) => void;
  years?: Option[];
  onFindPowers: (yearId: string) => void;
  powers?: Option[];
  onFindCarPrice: (params: CarData) => void;
  isManualCarInput?: boolean;
  hasUnknownCarNumberError: boolean;
  isFoundAllCarData: boolean;
  handleCarNumberIsNotReceived: () => void;
  isCarNumberNotReceived: boolean;
  handleCarNumberIsReceived: () => void;
}
