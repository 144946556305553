import { Datepicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';
import { FIO } from '@shared/constants';
import { capitalizeFirstLetter } from '@shared/utils';
import {
  LICENSE_MASK,
  LICENSE_PLACEHOLDER,
  YEAR_OF_START_MASK,
  YEAR_OF_START_PLACEHOLDER,
} from '@smart-components/who-is-driver/constants';
import { useCallback, type FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';

import type { DriverProps } from './types';
import type { Driver as DriverType } from '@smart-components/who-is-driver/types';

import {
  Container,
  InputContainer,
  InputRow,
  InputDriver,
} from './driver.styles';
import { i18nDefaultValues } from '../../i18n';
import { Title } from '../title';

export const Driver = ({
  serialNumber,
  remove,
  value,
  onChange,
  error,
  fieldsLength,
  disabled,
  maxBirthday,
  maxLicenseDate,
  isMe,
  trigger,
  clearErrors,
  handleAnalyticEventSend,
}: DriverProps) => {
  const { t } = useTranslation();

  const { yearOfStart } = value;

  const handleChange = useCallback(
    (name: keyof DriverType) => (data: string) => {
      onChange({
        ...value,
        [name]: data,
      });

      clearErrors(`drivers.${serialNumber - 1}.${name}`);
    },
    [onChange, clearErrors]
  );

  const handleChangeDate = useCallback(
    (name: keyof DriverType) => (data: Date) => {
      onChange({
        ...value,
        [name]: data,
      });

      clearErrors(`drivers.${serialNumber - 1}.${name}`);

      if (name === 'birthday' && yearOfStart) {
        trigger(`drivers.${serialNumber - 1}.yearOfStart`);
      }

      handleAnalyticEventSend?.(serialNumber - 1);
    },
    [onChange, clearErrors, yearOfStart, trigger]
  );

  const handleRemove = useCallback(() => {
    remove(serialNumber - 1);
  }, [remove, serialNumber]);

  const handleBlur = useCallback(
    (name: keyof DriverType) => (event: FocusEvent<HTMLInputElement>) => {
      if (FIO.has(name)) {
        let newValue = event.target.value.trim();
        newValue = capitalizeFirstLetter(newValue);
        onChange({
          ...value,
          [name]: newValue,
        });
      }

      trigger(`drivers.${serialNumber - 1}.${name}`);

      handleAnalyticEventSend?.(serialNumber - 1);
    },
    [onChange, trigger, handleAnalyticEventSend]
  );

  const handlePipe = useCallback((conformedValue: string) => {
    return conformedValue.toUpperCase();
  }, []);

  const birthday = value?.birthday ? new Date(value.birthday) : null;
  const licenseDate = value?.licenseDate ? new Date(value.licenseDate) : null;

  const disabledField = disabled || (isMe && serialNumber === 1);

  return (
    <Container>
      <Title
        isMe={isMe}
        serialNumber={serialNumber}
        remove={handleRemove}
        fieldsLength={fieldsLength}
      />
      <InputContainer>
        <InputRow>
          <InputDriver>
            <HelperText
              noMargin
              status={error?.surname ? 'error' : 'default'}
              message={error?.surname?.message}
            >
              <Input
                noMargin
                label={t('SMART:WhoIsDriver.labels.surname', {
                  defaultValue: i18nDefaultValues.WhoIsDriver.labels.surname,
                })}
                value={value.surname}
                onChange={handleChange('surname')}
                onBlur={handleBlur('surname')}
                error={!!error?.surname}
                disabled={disabledField}
                data-testid={`sur-name-${serialNumber}`}
              />
            </HelperText>
          </InputDriver>
          <InputDriver>
            <HelperText
              noMargin
              status={error?.name ? 'error' : 'default'}
              message={error?.name?.message}
            >
              <Input
                noMargin
                label={t('SMART:WhoIsDriver.labels.name', {
                  defaultValue: i18nDefaultValues.WhoIsDriver.labels.name,
                })}
                value={value.name}
                onChange={handleChange('name')}
                onBlur={handleBlur('name')}
                error={!!error?.name}
                disabled={disabledField}
                data-testid={`name-${serialNumber}`}
              />
            </HelperText>
          </InputDriver>
        </InputRow>
        <InputRow>
          <InputDriver>
            <HelperText
              noMargin
              status={error?.middlename ? 'error' : 'default'}
              message={error?.middlename?.message}
            >
              <Input
                noMargin
                label={t('SMART:WhoIsDriver.labels.middleName', {
                  defaultValue: i18nDefaultValues.WhoIsDriver.labels.middleName,
                })}
                value={value.middlename ?? ''}
                onChange={handleChange('middlename')}
                onBlur={handleBlur('middlename')}
                error={!!error?.middlename}
                disabled={disabledField}
                data-testid={`middle-name-${serialNumber}`}
              />
            </HelperText>
          </InputDriver>
          <InputDriver>
            <HelperText
              noMargin
              status={error?.birthday ? 'error' : 'default'}
              message={error?.birthday?.message}
            >
              <Datepicker
                label={t('SMART:WhoIsDriver.labels.birthday', {
                  defaultValue: i18nDefaultValues.WhoIsDriver.labels.birthday,
                })}
                maxDate={maxBirthday}
                onChange={handleChangeDate('birthday')}
                selected={birthday}
                error={!!error?.birthday}
                disabled={disabledField}
                showYearDropdown
                showMonthDropdown
                showInput
                disabledKeyboardNavigation
                data-testid={`birthday-${serialNumber}`}
              />
            </HelperText>
          </InputDriver>
        </InputRow>
        <InputRow>
          <InputDriver>
            <HelperText
              noMargin
              status={error?.license ? 'error' : 'default'}
              message={error?.license?.message}
            >
              <Input
                noMargin
                label={t('SMART:WhoIsDriver.labels.license', {
                  defaultValue: i18nDefaultValues.WhoIsDriver.labels.license,
                })}
                value={value.license}
                onChange={handleChange('license')}
                onBlur={handleBlur('license')}
                error={!!error?.license}
                disabled={disabled}
                mask={LICENSE_MASK}
                placeholder={LICENSE_PLACEHOLDER}
                pipe={handlePipe}
                data-testid={`license-${serialNumber}`}
              />
            </HelperText>
          </InputDriver>
          <InputDriver>
            <HelperText
              noMargin
              status={error?.yearOfStart ? 'error' : 'default'}
              message={error?.yearOfStart?.message}
            >
              <Input
                noMargin
                label={t('SMART:WhoIsDriver.labels.yearOfStart', {
                  defaultValue:
                    i18nDefaultValues.WhoIsDriver.labels.yearOfStart,
                })}
                value={value.yearOfStart}
                onChange={handleChange('yearOfStart')}
                onBlur={handleBlur('yearOfStart')}
                error={!!error?.yearOfStart}
                disabled={disabled}
                mask={YEAR_OF_START_MASK}
                placeholder={YEAR_OF_START_PLACEHOLDER}
                data-testid={`year-of-start-${serialNumber}`}
              />
            </HelperText>
          </InputDriver>
        </InputRow>
        <InputRow>
          <InputDriver isHalf>
            <HelperText
              noMargin
              status={error?.licenseDate ? 'error' : 'default'}
              message={error?.licenseDate?.message}
            >
              <Datepicker
                label={t('SMART:WhoIsDriver.labels.licenseDate', {
                  defaultValue:
                    i18nDefaultValues.WhoIsDriver.labels.licenseDate,
                })}
                maxDate={maxLicenseDate}
                onChange={handleChangeDate('licenseDate')}
                selected={licenseDate}
                error={!!error?.licenseDate}
                disabled={disabled}
                showYearDropdown
                showMonthDropdown
                showInput
                disabledKeyboardNavigation
                data-testid={`license-date-${serialNumber}`}
              />
            </HelperText>
          </InputDriver>
        </InputRow>
      </InputContainer>
    </Container>
  );
};
