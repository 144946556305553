import { Button } from '@pulse-web-ui/button';
import { styled, media } from '@pulse-web-ui/theme';

export const OtpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  ${media.desktop} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
    width: 50%;
  }
`;

export const SubmitPhone = styled(Button)`
  min-width: 172px;
  width: 100%;
  ${media.desktop} {
    width: 195px;
  }
`;
