import type { TextFieldProps } from './types';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';

export const TextField = ({
  label,
  value,
  onChange,
  error,
  disabled,
  mask,
  placeholder,
  onBlur,
  testid,
}: TextFieldProps) => {
  return (
    <HelperText
      noMargin
      status={error ? 'error' : 'default'}
      message={error?.message}
    >
      <Input
        noMargin
        onChange={onChange}
        value={value}
        error={!!error}
        disabled={disabled}
        label={label}
        mask={mask}
        placeholder={placeholder}
        onBlur={onBlur}
        data-testid={testid}
      />
    </HelperText>
  );
};
