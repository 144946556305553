import i18n from '@app/i18n/config';
import { object, boolean, number } from 'yup';
import { i18nDefaultValues } from './i18n';
import type { ValidationContext } from './types';

export const getDriversSchema = () =>
  object({
    minDriverAge: number()
      .transform((val, orig) => {
        return orig === '' ? undefined : val;
      })
      .required(
        i18n.t(
          'SMART:Drivers.errors.requiredMinDriverAge',
          i18nDefaultValues.Drivers.errors.requiredMinDriverAge
        )
      )
      .test('is-valid-range', (value, context) => {
        const { options, createError, path } = context;
        const { minAgeOfDriver } = options.context as ValidationContext;

        if (value >= minAgeOfDriver && value <= 100) return true;

        return createError({
          path,
          message: i18n.t('SMART:Drivers.errors.rangeMinDriverAge', {
            defaultValue: i18nDefaultValues.Drivers.errors.rangeMinDriverAge,
            min: minAgeOfDriver,
            max: 100,
          }),
        });
      }),
    minDriverExperience: number()
      .transform((val, orig) => {
        return orig === '' ? undefined : val;
      })
      .required(
        i18n.t(
          'SMART:Drivers.errors.requiredMinDriverExperience',
          i18nDefaultValues.Drivers.errors.requiredMinDriverExperience
        )
      )
      .test('is-valid-range', (value, context) => {
        const { options, createError, path } = context;
        const { minExperienceOfDriver } = options.context as ValidationContext;

        if (value >= minExperienceOfDriver && value <= 100) return true;

        return createError({
          path,
          message: i18n.t('SMART:Drivers.errors.rangeMinDriverExperience', {
            defaultValue:
              i18nDefaultValues.Drivers.errors.rangeMinDriverExperience,
            min: minExperienceOfDriver,
            max: 100,
          }),
        });
      })
      .when('minDriverAge', {
        is: (value: number | undefined) => value != undefined,
        then: (schema) =>
          schema.test('is-valid-max-driver-experience', (value, context) => {
            const {
              options,
              createError,
              path,
              parent: { minDriverAge },
            } = context;
            const { minAgeOfDriver } = options.context as ValidationContext;
            const maxDriverExperience = minDriverAge - minAgeOfDriver;

            if (value <= maxDriverExperience) return true;

            return createError({
              path,
              message: i18n.t(
                'SMART:Drivers.errors.minExperienceShouldNotBeMoreThanMaxExperience',
                {
                  defaultValue:
                    i18nDefaultValues.Drivers.errors
                      .minExperienceShouldNotBeMoreThanMaxExperience,
                  maxDriverExperience,
                }
              ),
            });
          }),
      }),
    isValid: boolean(),
  });
