import { useEffect } from 'react';
import { useIsDesktop, useStores } from '@shared/hooks';

export const useHandleFooter = () => {
  const {
    MainStore: {
      initProductStore: { initOrder },
      applicationStore: {
        activeStep,
        wantNextStep,
        flowConfig: { maxSteps },
        setIsFooterShowing,
      },
      productStore: { isPolicyBanned },
    },
  } = useStores();

  const isDesktop = useIsDesktop();

  useEffect(() => {
    // Скрывать Футер на первом шаге для прдукта Каско
    setIsFooterShowing(activeStep !== 1);

    if (isPolicyBanned) return setIsFooterShowing(false);

    const isPaymentPage = !wantNextStep && activeStep === maxSteps && initOrder;

    const handleHideFooter = (isHide: boolean) => setIsFooterShowing(!isHide);

    if (isPaymentPage && !isDesktop) handleHideFooter(true);

    return () => handleHideFooter(false);
  }, [wantNextStep, activeStep, initOrder, isDesktop, isPolicyBanned]);
};
