import { Checkbox } from '@pulse-web-ui/checkbox';
import { media, styled } from '@pulse-web-ui/theme';

export const StyledCheckbox = styled(Checkbox)`
  div {
    display: flex;
    span {
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledPadding = styled.div`
  padding-bottom: 16px;
  ${media.desktop} {
    padding-bottom: 60px;
  }
`;

export const StyledPaymentBox = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
