const platformExpression = /Mac|iPhone|iPod|iPad/i;
const rejectedExpression = /Chrome|Android|CriOS|FxiOS|EdgiOS/i;
const expectedExpression = /Safari/i;

export const isAppleSafari = () => {
  const agent = navigator.userAgent;

  if (rejectedExpression.test(agent)) {
    return false;
  }

  return platformExpression.test(agent) && expectedExpression.test(agent);
};
