import { useStores } from '@shared/hooks';
import { useCallback } from 'react';

import type { AnalyticEventsMap, BaseEventType } from '@shared/types';

import { sendAnalyticEvent } from '../utils';

export const useHandleSmartComponentsAnalyticEvents = <T extends BaseEventType>(
  config: AnalyticEventsMap<T>
) => {
  const {
    MainStore: {
      applicationStore: { sendSingleAnalyticEvent },
    },
  } = useStores();

  return useCallback((event: T) => {
    if (config[event]) {
      const sendAnalyticEventFunction = config[event].isSingle
        ? sendSingleAnalyticEvent
        : sendAnalyticEvent;

      sendAnalyticEventFunction(config[event].name, config[event].params);
    }
  }, []);
};
