import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import { DriversAnalyticEvent } from '@smart-components/drivers';
import { Drivers } from '@smart-components/index';
import { observer } from 'mobx-react-lite';
import { forwardRef, Suspense, useMemo } from 'react';

import type { AnalyticEventsMap } from '@shared/types';
import type { DriversValues } from '@smart-components/drivers/types';
import type { SmartComponentAdapterProps } from '@smart-components/shared/types';
import type { Ref } from 'react';

const analyticEventsMap: AnalyticEventsMap<DriversAnalyticEvent> = {
  [DriversAnalyticEvent.ON_MIN_AGE_SELECTED]: {
    name: analyticEvents.minAgeSelected,
  },
  [DriversAnalyticEvent.ON_MIN_EXP_SELECTED]: {
    name: analyticEvents.minExperienceSelected,
  },
};

export const DriversAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
      }: SmartComponentAdapterProps<DriversValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          initProductStore: {
            initState: { extraParams },
          },
          applicationStore: { isCarNumberSmartComponentShowing },
        },
      } = useStores();

      const options = useMemo(() => {
        const minAgeOfDriver = Number(
          extraParams?.find(
            (extraParam) => extraParam.code === 'minAgeOfDriver'
          )?.value
        );
        const minExperienceOfDriver = Number(
          extraParams?.find(
            (extraParam) => extraParam.code === 'minExperienceOfDriver'
          )?.value
        );

        return {
          minAgeOfDriver,
          minExperienceOfDriver,
        };
      }, [extraParams]);

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<DriversAnalyticEvent>(
          analyticEventsMap
        );

      if (isCarNumberSmartComponentShowing) return;

      return (
        <Suspense fallback={<FallbackSkeleton height={274} />}>
          <Drivers
            ref={forwardRef}
            isSubmitting={isSubmitting}
            value={value}
            onChange={onChange}
            options={options}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

DriversAdapter.displayName = 'DriversAdapter';
