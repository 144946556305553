// import { useContext, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { StyledAppContent } from './app-content.styles';
import { useAxiosInterceptors, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';

type AppContentType = {
  children: ReactNode;
};

export const AppContent: FC<AppContentType> = observer(({ children }) => {
  const {
    MainStore: {
      authStore: { setAuthTokens },
    },
  } = useStores();
  useAxiosInterceptors(setAuthTokens);

  return <StyledAppContent>{children}</StyledAppContent>;
});
