import { object, string, date, boolean } from 'yup';
import { i18nDefaultValues } from './i18n';
import i18n from '@app/i18n/config';
import { type ValidationContext } from './types';
import { checkDateBefore } from '@shared/utils';
import { eptsRegEx, ptsRegEx, stsRegEx } from '@shared/constants';
import { Document } from './constants';

const errorMessages = i18nDefaultValues.VehicleDocument.errors;
const notFilled = i18n.t(
  'SMART:VehicleDocument.errors.notFilled',
  errorMessages.notFilled
);
const invalidSerieOrNumber = i18n.t(
  'SMART:VehicleDocument.errors.invalidSerieOrNumber',
  errorMessages.invalidSerieOrNumber
);
const invalidNumber = i18n.t(
  'SMART:VehicleDocument.errors.invalidNumber',
  errorMessages.invalidNumber
);
const documentMinDate = i18n.t(
  'SMART:VehicleDocument.errors.documentMinDate',
  errorMessages.documentMinDate
);
const documentMaxDate = i18n.t(
  'SMART:VehicleDocument.errors.documentMaxDate',
  errorMessages.documentMaxDate
);
const EPTSminDateError = i18n.t(
  'SMART:VehicleDocument.errors.EPTSminDate',
  errorMessages.EPTSminDate
);

const EPTS_MIN_DATE = '01.01.2018';

export const schema = object({
  document: string().required(notFilled),
  documentNumber: string()
    .required(notFilled)
    .test('incorrect-doc-number', function (value, context) {
      const { createError, path } = context;
      const { document } = this.parent;
      if (document === Document.STS) {
        const isValid = stsRegEx.test(value);
        if (isValid) return true;
        return createError({
          path,
          message: invalidSerieOrNumber,
        });
      }
      if (document === Document.PTS) {
        const isValid = ptsRegEx.test(value);
        if (isValid) return true;
        return createError({
          path,
          message: invalidSerieOrNumber,
        });
      }
      if (document === Document.EPTS) {
        const isValid = eptsRegEx.test(value);
        if (isValid) return true;
        return createError({
          path,
          message: invalidNumber,
        });
      }
    })
    .test('not-all-zeros', invalidSerieOrNumber, function (value) {
      const { document } = this.parent;
      const SERIE_ZEROS = '0000';
      const NUMBER_ZEROS = '000000';

      if (document === Document.STS || document === Document.PTS) {
        const parts = value.split(' ');
        const serie = parts[0] + parts[1];
        const number = parts[parts.length - 1];

        return serie !== SERIE_ZEROS && number !== NUMBER_ZEROS;
      }

      return true;
    })
    .test('not-eight-in-a-row', invalidNumber, function (value) {
      const { document } = this.parent;

      if (document === Document.EPTS) {
        const condition = /(.)\1{7,}/;
        return !condition.test(value);
      }

      return true;
    })
    .test('check-digit', invalidNumber, function (value) {
      const { document } = this.parent;

      if (document === Document.EPTS) {
        if (value.length !== 15) return false;

        // Вычисляем контрольные число и цифру
        const quotient = Number(value.slice(0, 14));
        const checkDigit = Number(value[14]);

        let remainder = quotient % 11;

        if (remainder === 10) remainder = 0;

        return remainder === checkDigit;
      }

      return true;
    }),

  documentDate: date()
    .required(notFilled)
    .nullable()
    .test('too-early', documentMinDate, (value, context) => {
      const { options } = context;
      const { manufactureYear } = options.context as ValidationContext;

      if (manufactureYear === undefined) return false;

      const documentDate = value ? new Date(value) : new Date();

      const documentYear = documentDate.getFullYear();

      return documentYear >= Number(manufactureYear);
    })
    .test('too-late', documentMaxDate, (value) => {
      const documentDate = value ? new Date(value) : new Date();
      const currentDate = new Date();
      return checkDateBefore(documentDate, currentDate);
    })
    .test('too-early-for-EPTS', EPTSminDateError, function (value) {
      const { document } = this.parent;
      if (document !== Document.EPTS) {
        return true;
      }
      const documentDate = value ? new Date(value) : new Date();
      const minDate = new Date(EPTS_MIN_DATE);

      return checkDateBefore(minDate, documentDate);
    }),
  isValid: boolean(),
});
