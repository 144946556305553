/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { WidgetContainer } from '@pulse-web-ui/containers';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';
import { PhoneAndEmail, Policyholder } from '@shared/components';
import { getFormInsuranceContactsSchema } from '@shared/scheme';
import isEqual from 'lodash.isequal';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  type Ref,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type {
  WhoIsPolicyholderValues,
  WhoIsPolicyholderOptions,
  WhoIsPolicyholderDisableState,
} from './types';
import type { SmartComponentProps } from '@smart-components/shared/types';

import { FastAuthBanner } from './components';
import { WhoIsPolicyholderAnalyticEvent } from './constants';
import { i18nDefaultValues } from './i18n';
import { Form, Container } from './who-is-policyholder.styles';

//TODO: доработать, когда будет готова веб-аналитика

export const WhoIsPolicyholder = memo(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        disabled,
        options: {
          agentLogin,
          deviceType,
          phoneFromMobileApp,
          isOtpError,
          handleAuth,
          handleOtp,
          setRefetchAuthentication,
          isUserNotDefine,
          setIsUserNotDefine,
          setIsFastAuthOpen,
          setOtpErrorCode,
        },
        onAnalyticEventSend,
      }: SmartComponentProps<
        WhoIsPolicyholderValues,
        WhoIsPolicyholderOptions,
        WhoIsPolicyholderAnalyticEvent
      >,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();

      const formInsuranceContactsSchema = useMemo(
        () => getFormInsuranceContactsSchema(agentLogin),
        [agentLogin]
      );

      const methods = useForm<WhoIsPolicyholderValues>({
        defaultValues: value,
        mode: 'onSubmit',
        resetOptions: {
          keepDirtyValues: true,
          keepErrors: true,
        },
        resolver: yupResolver(formInsuranceContactsSchema),
        context: {},
      });

      const {
        trigger,
        watch,
        getValues,
        setValue,
        formState: { isValid },
        reset,
      } = methods;

      useEffect(() => {
        if (!isEqual(value, getValues())) {
          reset(value);
        }
      }, [JSON.stringify(value)]);

      const hasAuthorized = getValues('hasAuthorized');

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        if ((value?.hasAuthorized && !hasAuthorized) || !value) {
          reset(value);
          trigger();
        }
      }, [value]);

      useEffect(() => {
        const subscription = watch((values) => {
          onChange(values as WhoIsPolicyholderValues);
        });

        return () => subscription.unsubscribe();
      }, [watch]);

      useEffect(() => {
        setValue('isValid', hasAuthorized || isValid);
      }, [isValid, hasAuthorized]);

      const handleBlurPolicyholder = useCallback(() => {
        if (
          getValues('surname') &&
          getValues('name') &&
          getValues('middlename') &&
          getValues('birthday') &&
          getValues('passport') &&
          getValues('passportDate') &&
          getValues('passportByWho') &&
          getValues('passportCode') &&
          getValues('address')
        ) {
          onAnalyticEventSend?.(
            WhoIsPolicyholderAnalyticEvent.ON_DATA_SELECTED
          );
        }
      }, [onAnalyticEventSend]);

      const handleBlurPhone = useCallback(() => {
        onAnalyticEventSend?.(WhoIsPolicyholderAnalyticEvent.ON_PHONE_ENTERED);
      }, []);

      const handleBlurEmail = useCallback(() => {
        onAnalyticEventSend?.(WhoIsPolicyholderAnalyticEvent.ON_MAIL_ENTERED);
      }, []);

      const getDisableState = useCallback(
        (name: keyof WhoIsPolicyholderDisableState) => {
          if (hasAuthorized || disabled) {
            return true;
          }
          switch (name) {
            case 'phone':
              return Boolean(deviceType) && Boolean(phoneFromMobileApp);
          }

          return false;
        },
        [hasAuthorized, disabled, deviceType, phoneFromMobileApp]
      );

      if (hasAuthorized) return null;

      return (
        <WidgetContainer ref={forwardRef} data-testid="who-is-policyholder">
          <Form>
            <HeaderWithSubText
              title={t('SMART:WhoIsPolicyholder.title', {
                defaultValue: i18nDefaultValues.WhoIsPolicyholder.title,
              })}
            />
            <Container>
              <FastAuthBanner
                isOtpError={isOtpError}
                handleAuth={handleAuth}
                handleOtp={handleOtp}
                setRefetchAuthentication={setRefetchAuthentication}
                isUserNotDefine={isUserNotDefine}
                setIsUserNotDefine={setIsUserNotDefine}
                setIsFastAuthOpen={setIsFastAuthOpen}
                setOtpErrorCode={setOtpErrorCode}
              />
              <FormProvider {...methods}>
                <Policyholder
                  getDisableState={getDisableState}
                  onBlur={handleBlurPolicyholder}
                />
                <PhoneAndEmail
                  getDisableState={getDisableState}
                  onBlurPhone={handleBlurPhone}
                  onBlurEmail={handleBlurEmail}
                />
              </FormProvider>
            </Container>
          </Form>
        </WidgetContainer>
      );
    }
  )
);
