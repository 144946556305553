import { styled, typography } from '@pulse-web-ui/theme';
import { Button } from '@pulse-web-ui/button';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CountIcon = styled.div`
  ${typography.regular16};
  color: ${({ theme }) => theme?.colors.icon.inverse};
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme?.colors.icon.secondary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteDriver = styled(Button)`
  color: ${({ theme }) => theme?.colors.text.error};
`;

export const TitleDriver = styled.span`
  ${typography.medium20};
  color: ${({ theme }) => theme?.colors.text.primary};
`;

export const NumberDriver = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
