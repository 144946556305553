import { WidgetContainer } from '@pulse-web-ui/containers';
import { styled } from '@pulse-web-ui/theme';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const WidgetContainerStyled = styled(WidgetContainer)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;
