import { Endpoints, PRODUCT_CODE } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

import type { RisksResponse } from '@shared/queries';
import type { AxiosError } from 'axios';

export const useGetRisks = () => {
  return useQuery<RisksResponse, AxiosError<{ code: string }>>(
    ['get-risks'],
    fetchData<RisksResponse>({
      url: Endpoints.GET_RISKS,
      method: 'POST',
      data: {
        baseParameters: {
          productVersion: `${PRODUCT_CODE},1`,
        },
      },
    }),
    { enabled: true }
  );
};
