/* eslint-disable indent */
import { CheckPolicy } from '@entities/import-smart-components/check-policy';
import { useStores, useHandleErrorRequest } from '@shared/hooks';
import {
  getCompletePaymentData,
  useCompletePayment,
  useInitOrder,
} from '@shared/queries';
import {
  getUpdatedProfile,
  useHandleUpdateProfile,
} from '@shared/queries/update-profile';
import { PublicRoutes } from '@shared/routes';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useEffect, type Ref } from 'react';
import { useNavigate } from 'react-router-dom';

import type { CheckPolicyValues } from '@pulse-smart-components-kit/check-policy';
import type { SmartComponentAdapterProps } from '@smart-components/index';

import { Loader } from './components';
import { useGetOptions } from './hooks';
import { schema } from './schema';
import { useGetRequestData } from '../submit-adapter/hooks';
import { useHandleSubmit } from '../submit-adapter/queries';

export const CheckPolicyAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<CheckPolicyValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: {
            setDisabledSubmit,
            updateFormValue,
            activeStep,
            retryPayment,
            flowConfig: { maxSteps },
            stringifiedSubmitData,
          },
          productStore: {
            isPolicyBanned,
            setIsPolicyBanned,
            setOrderData,
            agentLogin,
            formState: { PolicyholderForm },
          },
          authStore: { disableForm, profile },
        },
      } = useStores();
      const navigate = useNavigate();

      const updatedProfile = getUpdatedProfile(
        PolicyholderForm,
        profile?.profile
      );

      const {
        status,
        refetch,
        error,
        isSuccess: updateProfileIsSuccess,
        isLoading: updateProfileIsLoading,
      } = useHandleUpdateProfile(updatedProfile);
      useHandleErrorRequest({
        status,
        refetch,
        error,
        isLoading: updateProfileIsLoading,
      });

      const isSubmitEnabled = !updatedProfile || updateProfileIsSuccess;

      const requestData = useGetRequestData();
      const {
        data: submitData,
        isFetching: submitIsFetching,
        status: submitStatus,
        refetch: refetchSubmit,
        remove: removeSubmit,
        error: submitError,
      } = useHandleSubmit(isSubmitEnabled ? requestData : undefined);

      useHandleErrorRequest({
        status: submitStatus,
        refetch: refetchSubmit,
        error: submitError,
        isLoading: submitIsFetching,
      });

      const { resInit } = useInitOrder(updateFormValue, submitData);

      useCompletePayment(getCompletePaymentData(submitData, resInit));

      useEffect(() => {
        if (
          submitError &&
          submitError.response?.status === 400 &&
          submitError.response?.data?.code === 'CALCULATION_ERROR'
        ) {
          setIsPolicyBanned(true);
          navigate(PublicRoutes.POLICY_BANNED);
        }
      }, [submitError]);

      useEffect(() => {
        if (
          isSubmitEnabled &&
          activeStep === maxSteps &&
          !retryPayment &&
          !agentLogin &&
          (JSON.stringify(requestData) !== stringifiedSubmitData ||
            PolicyholderForm?.email !== profile?.profile.email)
        )
          refetchSubmit();
      }, [activeStep, maxSteps, retryPayment, agentLogin, isSubmitEnabled]);

      useEffect(() => {
        if (submitData) {
          setOrderData(submitData);
        }
      }, [submitData]);

      useEffect(() => {
        setDisabledSubmit(submitIsFetching);

        return () => {
          setDisabledSubmit(false);
        };
      }, [submitIsFetching]);

      useEffect(() => {
        if (isPolicyBanned) removeSubmit();
      }, [isPolicyBanned]);

      const handleAnalyticEventSend = () => {};

      const options = useGetOptions(requestData);

      if (submitIsFetching || updateProfileIsLoading) {
        return <Loader />;
      }

      return (
        <Suspense>
          <CheckPolicy
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={disableForm}
            fieldState={fieldState}
            formSchema={schema}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);
