import { styled, media } from '@pulse-web-ui/theme';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  ${media.desktop} {
    row-gap: 24px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  ${media.desktop} {
    row-gap: 16px;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  ${media.desktop} {
    flex-direction: row;
    column-gap: 12px;
  }
`;

export const InputDriver = styled.div<{ isHalf?: boolean }>`
  width: 100%;

  ${media.desktop} {
    flex: ${(props) => (props.isHalf ? '0 0 calc(50% - 8px)' : 1)};
  }
`;

export const FlexItem = styled.div`
  flex: 1;
`;
