import type { Driver } from '@smart-components/who-is-driver/types';
import { differenceInYears, setYear } from 'date-fns';

const TODAY = new Date();

export const getMinExperienceAge = (drivers: Driver[] = []) => {
  const years = drivers
    .filter((driver) => Number(driver?.yearOfStart))
    .map((driver) => Number(driver.yearOfStart));

  if (!years.length) {
    return null;
  }

  const maxYear = Math.max(...years);

  return differenceInYears(TODAY, setYear(TODAY, maxYear));
};
