import { DataView } from '@pulse-web-ui/data-view';

import { Car as CarIcon } from '@pulse-web-ui/icons';
import { LeftSlotContent } from './car-data-view.styles';

interface CarDataViewProps {
  title: string;
  description: string;
}

export const CarDataView = ({ title, description }: CarDataViewProps) => {
  return (
    <DataView
      data-testid="car-data-view"
      title={title}
      leftSlotContent={
        <LeftSlotContent>
          <CarIcon color="currentColor" />
        </LeftSlotContent>
      }
      description={description}
    />
  );
};
