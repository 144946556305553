import { Endpoints } from '@shared/constants';
import { useStores } from '@shared/hooks';
import { fetchData } from '@shared/utils';
import { useEffect } from 'react';
import { useQuery } from 'react-query';

import type { UpdateProfileRequest } from './types';
import type { UpdateProfileResponse } from '@shared/types';
import type { AxiosError } from 'axios';

import { profile } from './schema';

export const useHandleUpdateProfile = (
  data: UpdateProfileRequest | undefined
) => {
  const {
    MainStore: {
      authStore: { handleProfile },
    },
  } = useStores();
  const enabled = profile.isValidSync(data);

  const query = useQuery<UpdateProfileResponse, AxiosError<{ code: string }>>(
    ['update-profile', data],
    fetchData<UpdateProfileResponse>({
      url: Endpoints.UPDATE_PROFILE,
      method: 'POST',
      data,
    }),
    { enabled, cacheTime: 0 }
  );

  const { data: res } = query;

  useEffect(() => {
    if (res) {
      handleProfile(res);
    }
  }, [res]);

  return query;
};
