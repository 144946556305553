import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '@pulse-web-ui/alert';
import { Button } from '@pulse-web-ui/button';
import { CarNumberInput } from '@pulse-web-ui/car-number-input';
import { Checkbox } from '@pulse-web-ui/checkbox';
import { WidgetContainer } from '@pulse-web-ui/containers';
import { DataView } from '@pulse-web-ui/data-view';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Car as CarIcon } from '@pulse-web-ui/icons';
import { Modal } from '@pulse-web-ui/modal';
import { CurrencyLabel, NumberRangeInput } from '@pulse-web-ui/range-input';
import { VINInput } from '@pulse-web-ui/vin-input';
import { Distance, ZERO_AT_START } from '@shared/constants';
import { useIsDesktop } from '@shared/hooks';
import { removeAllSpaces, formattingPrice } from '@shared/utils';
import isEqual from 'lodash.isequal';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type {
  CarData,
  CarValues,
  CarOptions,
  ValidationContext,
} from './types';
import type { SmartComponentProps } from '@smart-components/shared/types';
import type { ChangeEvent, Ref, KeyboardEvent, ClipboardEvent } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';

import {
  CheckboxWrapper,
  FlexItem,
  FormWrapper,
  InputWrapper,
  LeftSlotContent,
  HeaderWrapper,
  NotRegistredButton,
} from './car.styles';
import { CarOptions as CarOptionsComponent } from './components/car-options';
import { ModalContent } from './components/modal-content';
import { MAX_MILEAGE, CarAnalyticEvent } from './constants';
import { i18nDefaultValues } from './i18n';
import { getCarSchema } from './schema';

export const Car = memo(
  forwardRef(
    (
      {
        onChange,
        value,
        isSubmitting,
        options: {
          onFindCar,
          car,
          maxProductLimit,
          minProductLimit,
          onFindBrands,
          onFindModels,
          brands,
          models,
          powers,
          onFindCarPrice,
          onFindYears,
          years,
          onFindPowers,
          isManualCarInput,
          hasUnknownCarNumberError,
          isFoundAllCarData,
          handleCarNumberIsNotReceived,
          isCarNumberNotReceived,
          handleCarNumberIsReceived,
        },
        onAnalyticEventSend,
      }: SmartComponentProps<CarValues, CarOptions, CarAnalyticEvent>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const isManualVINInput = Boolean(value?.isManualVINInput);

      const [isShowModal, setIsShowModal] = useState(false);

      const firstCharManualVINInputRef = useRef<string>('');

      const { t } = useTranslation();

      const schema = useMemo(() => getCarSchema(), [t]);

      const isDesktop = useIsDesktop();

      const minPrice = car?.price?.minPrice;
      const maxPrice = car?.price?.maxPrice;

      const methods = useForm<CarValues, ValidationContext>({
        defaultValues: value,
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
        context: {
          minPrice,
          maxPrice,
          minProductLimit,
          maxProductLimit,
          isManualCarInput,
          isCarNumberRequired: !isCarNumberNotReceived,
        },
        resetOptions: {
          keepErrors: true,
        },
      });

      const {
        watch,
        control,
        trigger,
        getValues,
        formState: { isValid },
        setValue,
        setError,
        clearErrors,
        reset,
      } = methods;

      useEffect(() => {
        if (!isEqual(value, getValues())) {
          reset(value, { keepErrors: true });
        }
      }, [JSON.stringify(value)]);

      const carData = watch('car');

      const hasCarData = useMemo(
        () =>
          !!carData &&
          !!carData.model?.value &&
          !!carData.brand?.value &&
          !!carData.manufactureYear?.value &&
          !!carData.power?.value,
        [carData]
      );

      const minMarketPrice =
        hasCarData && minPrice && isFoundAllCarData
          ? minPrice
          : minProductLimit;

      const maxMarketPrice =
        hasCarData && maxPrice && isFoundAllCarData
          ? maxPrice
          : maxProductLimit;

      useEffect(() => {
        setValue('isValid', isValid);
      }, [isValid]);

      useEffect(() => {
        const subscription = watch((values) => {
          onChange(values);
        });

        return () => subscription.unsubscribe();
      }, [watch]);

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        if (isCarNumberNotReceived) {
          setValue('carNumber', '');
        }
      }, [isCarNumberNotReceived]);

      const validKasko = getValues('haveValidPolicy');
      const kreditAvto = getValues('carOnCredit');

      useEffect(() => {
        if (validKasko) onAnalyticEventSend?.(CarAnalyticEvent.ON_VALID_KASKO);
      }, [validKasko]);

      useEffect(() => {
        if (kreditAvto) onAnalyticEventSend?.(CarAnalyticEvent.ON_KREDIT_AVTO);
      }, [kreditAvto]);

      useEffect(() => {
        firstCharManualVINInputRef.current = '';
        //TODO: возможно стоит прокидывать carNumber или requestId вместо car целиком
      }, [car]);

      const handleCloseModal = useCallback(() => setIsShowModal(false), []);

      const handleFindCar = useCallback(() => {
        onFindCar();
        onAnalyticEventSend?.(CarAnalyticEvent.ON_REG_NUMBER_SEARCH);
      }, [onFindCar, isFoundAllCarData]);

      const handleNumericChange = useCallback(
        (onChange: (value: number | null) => void) => (value: number) => {
          clearErrors();
          onChange(isNaN(value) ? 0 : value);
        },
        []
      );

      const handleSaveCarData = useCallback(
        (data: CarData) => {
          setValue('car', data);
          setValue('vin', '');
          onFindCarPrice(data);
          onAnalyticEventSend?.(CarAnalyticEvent.ON_SUM_SELECTED);
        },
        [onFindCarPrice]
      );

      const dataViewTitle = useMemo(() => {
        const brand = getValues('car.brand.label');
        const model = getValues('car.model.label');

        return `${brand} ${model}`;
      }, [getValues('car.brand.label'), getValues('car.model.label')]);

      const dataViewDescription = useMemo(() => {
        const year = getValues('car.manufactureYear.label');
        const power = getValues('car.power.label');

        return t('SMART:Car.labels.carDescription', {
          defaultValue: i18nDefaultValues.Car.labels.carDescription,
          year,
          power,
        });
      }, [
        getValues('car.manufactureYear.label'),
        getValues('car.power.label'),
      ]);

      const handleCheckboxChange = useCallback(
        (onChange: (value: boolean) => void) =>
          (e: ChangeEvent<HTMLInputElement>) => {
            clearErrors();
            onChange(e.target.checked);
          },
        []
      );

      const handleVINChange = useCallback(
        (onChange: (value: string) => void) => (value: string) => {
          clearErrors();

          if (isManualVINInput) return onChange(removeAllSpaces(value));

          setValue('isManualVINInput', true);
          onChange(removeAllSpaces(firstCharManualVINInputRef.current));
        },
        [isManualVINInput]
      );

      const handleDismiss = useCallback(() => {}, []);

      const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
          if (isManualVINInput) return;

          const { key } = e;

          //Если пользователь сам вводит VIN, то запоминаем первый символ ручного ввода, чтобы заменить им заполненный VIN с сервера
          if (key === 'Backspace' || key === 'Delete')
            return (firstCharManualVINInputRef.current = '');

          if (key.length === 1) firstCharManualVINInputRef.current = key;
        },
        [isManualVINInput]
      );

      const handlePaste = useCallback(
        (e: ClipboardEvent<HTMLInputElement>) => {
          if (isManualVINInput) return;

          firstCharManualVINInputRef.current = e.clipboardData.getData('Text');
        },
        [isManualVINInput]
      );

      const getValidNumericValue = useCallback(
        (value: string) => (ZERO_AT_START.test(value) ? value.slice(1) : value),
        []
      );

      const priceFormatter = useCallback(
        (value: string) => {
          const val = getValidNumericValue(value);
          return val ? `${val} ${CurrencyLabel.RUB}` : val;
        },
        [getValidNumericValue]
      );

      const mileageFormatter = useCallback(
        (value: string) => {
          const val = getValidNumericValue(value);
          return val ? `${val} ${Distance.KM}` : val;
        },
        [getValidNumericValue]
      );

      useEffect(() => {
        if (hasUnknownCarNumberError) {
          setError('carNumber', {
            message: t('SMART:Car.errors.unknownCarNumber', {
              defaultValue: i18nDefaultValues.Car.errors.unknownCarNumber,
            }),
          });
          onAnalyticEventSend?.(CarAnalyticEvent.ON_REG_NUMBER_NOT_FOUND);
        }
      }, [hasUnknownCarNumberError]);

      const handleChangeInfoClick = useCallback(() => {
        setIsShowModal(true);
        onAnalyticEventSend?.(CarAnalyticEvent.ON_CHANGE_CAR_DATA);
      }, []);

      const handleCarNumberChange = useCallback(
        (onChange: ControllerRenderProps<CarValues>['onChange']) =>
          (value: string) => {
            onChange(value);
            clearErrors();
          },
        []
      );

      const handleCarNumberBlur = useCallback(() => {
        if (getValues('carNumber')) {
          onAnalyticEventSend?.(CarAnalyticEvent.ON_REG_NUMBER_SELECTED);
        }
      }, [onAnalyticEventSend]);

      const handleMilliageBlur = useCallback(() => {
        if (getValues('mileage') !== undefined)
          onAnalyticEventSend?.(CarAnalyticEvent.ON_MILEAGE_SELECTED);
      }, [onAnalyticEventSend]);

      const handleCarSumBlur = useCallback(() => {
        if (getValues('marketPrice') !== undefined)
          onAnalyticEventSend?.(CarAnalyticEvent.ON_SUM_SELECTED);
      }, [onAnalyticEventSend]);

      const handleVINBlur = useCallback(() => {
        if (getValues('vin'))
          onAnalyticEventSend?.(CarAnalyticEvent.ON_VIN_SELECTED);
      }, [onAnalyticEventSend]);

      //TODO: вынести HeaderWrapper и NotRegistredButton в common

      const numberReceivedText = t('SMART:Car.labels.numberReceived', {
        defaultValue: i18nDefaultValues.Car.labels.numberReceived,
      });
      const numberNotReceivedText = t('SMART:Car.labels.numberNotReceived', {
        defaultValue: i18nDefaultValues.Car.labels.numberNotReceived,
      });

      const carNumberIsNotReceivedButtonText = isCarNumberNotReceived
        ? numberReceivedText
        : numberNotReceivedText;

      return (
        <WidgetContainer ref={forwardRef} data-testid="car-block">
          <FormWrapper>
            <HeaderWrapper>
              <HeaderWithSubText
                title={t('SMART:Car.title', {
                  defaultValue: i18nDefaultValues.Car.title,
                })}
                subTitle={t('SMART:CAR.hints.input', {
                  defaultValue: i18nDefaultValues.Car.hints.input,
                })}
              />
              {isDesktop && (
                <NotRegistredButton
                  label={carNumberIsNotReceivedButtonText}
                  variant="text"
                  data-testid="not-registred-button"
                  onClick={
                    isCarNumberNotReceived
                      ? handleCarNumberIsReceived
                      : handleCarNumberIsNotReceived
                  }
                />
              )}
            </HeaderWrapper>
            {(!isCarNumberNotReceived || !isDesktop) && (
              <div>
                {!isCarNumberNotReceived && (
                  <Controller
                    name="carNumber"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <CarNumberInput
                        name="carNumber"
                        buttonLabel={t(
                          'SMART:CAR.labels.findCar.',
                          i18nDefaultValues.Car.labels.findCar
                        )}
                        width={isDesktop ? 354 : undefined}
                        buttonVariant="secondary-2"
                        value={value}
                        onSearchAuto={handleFindCar}
                        isError={!!error}
                        errorMessage={error?.message}
                        onChange={handleCarNumberChange(onChange)}
                        onBlur={handleCarNumberBlur}
                        inputTestId="car-number-input"
                        buttonTestId="find-car-button"
                      />
                    )}
                  />
                )}
                {!isDesktop && (
                  <NotRegistredButton
                    label={carNumberIsNotReceivedButtonText}
                    variant="secondary-2"
                    adaptiveWidth
                    data-testid="not-registred-button"
                    onClick={
                      isCarNumberNotReceived
                        ? handleCarNumberIsReceived
                        : handleCarNumberIsNotReceived
                    }
                  />
                )}
              </div>
            )}
            {!isFoundAllCarData && car?.requestId && (
              <Alert
                data-testid="not-found-all-data-alert"
                type="warning"
                title={t('SMART:Car.alerts.notFoundAllCarData.title', {
                  defaultValue:
                    i18nDefaultValues.Car.alerts.notFoundAllCarData.title,
                })}
                description={t(
                  'SMART:Car.alerts.numberNotReceived.description',
                  {
                    defaultValue:
                      i18nDefaultValues.Car.alerts.notFoundAllCarData
                        .description,
                  }
                )}
              />
            )}
            {isManualCarInput ? (
              <FormProvider {...methods}>
                <CarOptionsComponent
                  brands={brands}
                  models={models}
                  years={years}
                  powers={powers}
                  brandLabel={t('SMART:CAR.labels.brand', {
                    defaultValue: i18nDefaultValues.Car.labels.brand,
                  })}
                  modelLabel={t('SMART:CAR.labels.model', {
                    defaultValue: i18nDefaultValues.Car.labels.model,
                  })}
                  manufactureYearLabel={t('SMART:CAR.labels.manufactureYear', {
                    defaultValue: i18nDefaultValues.Car.labels.manufactureYear,
                  })}
                  powerLabel={t('SMART:CAR.labels.power', {
                    defaultValue: i18nDefaultValues.Car.labels.power,
                  })}
                  onFindBrands={onFindBrands}
                  onFindModels={onFindModels}
                  onFindYears={onFindYears}
                  onFindPowers={onFindPowers}
                  onFindCarPrice={onFindCarPrice}
                  minProductLimit={minProductLimit}
                  onAnalyticEventSend={onAnalyticEventSend}
                />
              </FormProvider>
            ) : (
              <DataView
                title={dataViewTitle}
                rightSlotContent={
                  <Button
                    label={t(
                      'SMART:CAR.labels.changeInfo.',
                      i18nDefaultValues.Car.labels.changeInfo
                    )}
                    variant="setting"
                    buttonSize="m"
                    adaptiveWidth
                    onClick={handleChangeInfoClick}
                    data-testid="change-car-button"
                  />
                }
                leftSlotContent={
                  <LeftSlotContent>
                    {<CarIcon color="currentColor" />}
                  </LeftSlotContent>
                }
                description={dataViewDescription}
              />
            )}
            <Controller
              name="marketPrice"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <HelperText
                  noMargin
                  status={error ? 'error' : 'default'}
                  message={
                    error?.message ??
                    t('SMART:Car.hints.rangePrice', {
                      defaultValue: i18nDefaultValues.Car.hints.rangePrice,
                      minMarketPrice: formattingPrice(minMarketPrice),
                      maxMarketPrice: formattingPrice(maxMarketPrice),
                    })
                  }
                >
                  <NumberRangeInput
                    name="marketPrice"
                    onChange={handleNumericChange(onChange)}
                    inputLabel={t('SMART:Car.labels.currentMarketPrice', {
                      defaultValue:
                        i18nDefaultValues.Car.labels.currentMarketPrice,
                    })}
                    error={!!error}
                    onBlur={handleCarSumBlur}
                    value={value == undefined ? 0 : value}
                    disabled={!hasCarData}
                    min={minMarketPrice}
                    max={maxMarketPrice}
                    step={1}
                    isRangeSliderVisible={false}
                    testId="market-price-input"
                    formatter={priceFormatter}
                  />
                </HelperText>
              )}
            />

            <InputWrapper>
              <FlexItem>
                <Controller
                  name="mileage"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <HelperText
                      noMargin
                      status={error ? 'error' : 'default'}
                      message={error?.message}
                    >
                      <NumberRangeInput
                        name="mileage"
                        onChange={handleNumericChange(onChange)}
                        inputLabel={t('SMART:Car.labels.mileage', {
                          defaultValue: i18nDefaultValues.Car.labels.mileage,
                        })}
                        error={!!error}
                        onBlur={handleMilliageBlur}
                        value={value == undefined ? 0 : value}
                        min={0}
                        max={MAX_MILEAGE}
                        step={1}
                        isRangeSliderVisible={false}
                        testId="mileage-input"
                        formatter={mileageFormatter}
                      />
                    </HelperText>
                  )}
                />
              </FlexItem>
              <FlexItem>
                <Controller
                  name="vin"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <VINInput
                        noMargin
                        name="vin"
                        onKeyDown={handleKeyDown}
                        onChange={handleVINChange(onChange)}
                        label={t('SMART:Car.labels.vin', {
                          defaultValue: i18nDefaultValues.Car.labels.vin,
                        })}
                        error={!!error}
                        onBlur={handleVINBlur}
                        hintObject={{ message: error?.message }}
                        value={value ?? ''}
                        hideVIN={!isManualVINInput}
                        onPaste={handlePaste}
                        data-testid="vin-input"
                      />
                    );
                  }}
                />
              </FlexItem>
            </InputWrapper>
            <CheckboxWrapper>
              <FlexItem>
                <Controller
                  name="haveValidPolicy"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Checkbox
                      label={t('SMART:Car.labels.haveValidPolicy', {
                        defaultValue:
                          i18nDefaultValues.Car.labels.haveValidPolicy,
                      })}
                      name="havePolicy"
                      onChange={handleCheckboxChange(onChange)}
                      checked={value}
                      message={error?.message}
                      status={error && 'error'}
                      data-testid="have-valid-police-checkbox"
                    />
                  )}
                />
              </FlexItem>
              <FlexItem>
                <Controller
                  name="carOnCredit"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Checkbox
                      label={t('SMART:Car.labels.carOnLoan', {
                        defaultValue: i18nDefaultValues.Car.labels.carOnLoan,
                      })}
                      name="carOnCredit"
                      onChange={handleCheckboxChange(onChange)}
                      checked={value}
                      message={error?.message}
                      status={error && 'error'}
                      data-testid="car-on-credit-checkbox"
                    />
                  )}
                />
              </FlexItem>
            </CheckboxWrapper>
            <Modal
              showDialog={isShowModal}
              closeHandler={handleCloseModal}
              onDismiss={handleDismiss} // Отключение закрытие модалки по нажатию на оверлей
            >
              <ModalContent
                title={t('SMART:Car.title', {
                  defaultValue: i18nDefaultValues.Car.title,
                })}
                onCloseModal={handleCloseModal}
                brand={t('SMART:CAR.labels.brand', {
                  defaultValue: i18nDefaultValues.Car.labels.brand,
                })}
                model={t('SMART:CAR.labels.model', {
                  defaultValue: i18nDefaultValues.Car.labels.model,
                })}
                manufactureYear={t('SMART:CAR.labels.manufactureYear', {
                  defaultValue: i18nDefaultValues.Car.labels.manufactureYear,
                })}
                power={t('SMART:CAR.labels.power', {
                  defaultValue: i18nDefaultValues.Car.labels.power,
                })}
                buttonLabel={t('SMART:CAR.labels.apply', {
                  defaultValue: i18nDefaultValues.Car.labels.apply,
                })}
                onFindBrands={onFindBrands}
                brands={brands}
                onFindModels={onFindModels}
                models={models}
                onFindYears={onFindYears}
                years={years}
                onFindPowers={onFindPowers}
                powers={powers}
                onSaveCarData={handleSaveCarData}
                carData={carData}
                onAnalyticEventSend={onAnalyticEventSend}
              />
            </Modal>
          </FormWrapper>
        </WidgetContainer>
      );
    }
  )
);
