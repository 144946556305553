export const i18nDefaultValues = {
  Drivers: {
    title: 'Водители',
    labels: {
      minDriverExperience: 'Минимальный стаж водителей',
      minDriverAge: 'Минимальный возраст водителей',
    },
    errors: {
      requiredMinDriverAge: 'Укажите минимальный возраст водителей',
      requiredMinDriverExperience: 'Укажите минимальный стаж водителей',
      rangeMinDriverAge: 'Укажите значение от {{min}} до {{max}}',
      rangeMinDriverExperience: 'Укажите значение от {{min}} до {{max}}',
      minExperienceShouldNotBeMoreThanMaxExperience:
        'Минимальный стаж не может быть больше {{maxDriverExperience}}',
    },
  },
};
