const ONE_DAY = 1; // Страхователю должно быть полных 18 лет и один день

export const calculateAge = (birthday: Date): number => {
  const today: Date = new Date();

  let age: number = today.getFullYear() - birthday.getFullYear();
  const monthDiff: number = today.getMonth() - birthday.getMonth();

  if (
    age > 0 &&
    (monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() - ONE_DAY < birthday.getDate()))
  ) {
    age--;
  }

  return Math.abs(age);
};
