export const i18nDefaultValues = {
  B2P: {
    title: 'Пожалуйста, не закрывайте эту вкладку\n до завершения оплаты',
    titleAfterTimeOut: 'Мы все еще ожидаем статус оплаты по договору',
    subtitle:
      'Если вы что-то измените в страховке, оплатить ее следует в новой вкладке',
    subtitleAfterTimeOut:
      'Проверьте статус оплаты в мобильном банке или в приложении страховой компании.\n Если оплата не прошла, вернитесь к оформлению договора',
    labels: {
      submitButtonText: 'Вернуться к оформлению',
    },
    errors: {
      acceptRequirements: 'Без этого согласия не можем оформить страховку',
    },
  },
};
