import {
  EPTS_PLACEHOLDER,
  PTS_PLACEHOLDER,
  STS_PLACEHOLDER,
  Document,
} from '../constants';

export const getPlaceholder = (document: string): string => {
  if (document === Document.STS) return STS_PLACEHOLDER;
  if (document === Document.PTS) return PTS_PLACEHOLDER;
  if (document === Document.EPTS) return EPTS_PLACEHOLDER;
  return '';
};
