const currencyFormat = new Intl.NumberFormat('ru', {
  style: 'currency',
  currency: 'RUB',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formattingPrice = (price: number) => {
  return currencyFormat.format(price);
};
