import { RisksOptionEnum, type RisksType } from '@shared/types';

export const getOptions = ({
  fullOptionsRisks,
  traveling,
}: {
  fullOptionsRisks: RisksType[];
  traveling: boolean;
}): string[] => {
  const checkedRisks = fullOptionsRisks.filter(
    (item) => item.checked && item.type === RisksOptionEnum.option
  );

  const options = checkedRisks
    ?.filter((item) => !item.sum)
    .map((option) => option.code);

  if (traveling) {
    options.push('Tour_AlreadyTraveling');
  }

  return options;
};
