import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ThemeContainerIn } from './theme-container-in';

type Props = {
  children: ReactNode;
};

export const ThemeContainer: FC<Props> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [themeUrl, setThemeUrl] = useState<string | undefined>();

  useEffect(() => {
    setThemeUrl(searchParams.get('personalization') || undefined);
  }, []);

  return <ThemeContainerIn themeUrl={themeUrl}>{children}</ThemeContainerIn>;
};
