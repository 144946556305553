export const i18nDefaultValues = {
  WhoIsDriver: {
    title: 'Водители',
    subTitle: '',
    headers: {
      driver: 'Водитель',
      youDriver: 'Водитель – Вы',
    },
    labels: {
      client: 'Страхователь является водителем',
      surname: 'Фамилия',
      name: 'Имя',
      middleName: 'Отчество',
      birthday: 'Дата рождения',
      license: 'Серия и номер ВУ',
      yearOfStart: 'Год начала стажа',
      licenseDate: 'Дата выдачи ВУ',
      addMore: 'Добавить еще',
      delete: 'Удалить',
    },
    hints: {
      addAnother: 'Добавить еще',
    },
    errors: {
      notFilled: 'Поле не заполнено',
      maxLength: 'Максимальная длина 50 символов',
      useCyrillic: 'Используйте кириллицу',
      useNumbers: 'Используйте цифры',
      nameStartWithLetter: 'Имя должно начинаться с буквы',
      lastnameStartWithLetter: 'Фамилия должна начинаться с буквы',
      middleNameStartWithLetter: 'Отчество должно начинаться с буквы',
      incorrectLicense: 'Некорректные серия и/или номер ВУ',
      minAgeOfDriver:
        'Возраст водителя не может быть меньше {{minAgeOfDriver}} лет',
      maxAgeOfDriver: 'Возраст водителя не может быть больше 100 лет',
      minDateOfLicense:
        'Возраст водителя на дату выдачи ВУ не может быть меньше {{minAgeOfDriver}}',
      minYearOfStart:
        'Год начала стажа не может быть меньше {{minYearOfStart}}',
      incorrectYearOfStart: 'Неверный год начала стажа',
    },
  },
};
