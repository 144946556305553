/* eslint-disable indent */
import { useTranslation } from 'react-i18next';

import type { Props } from './types';

import { Submit, SubmitLink } from './submit-button.styles';
import { i18nDefaultValues } from '../../i18n';

export const SubmitButton = ({
  onClick,
  buttonText,
  currentStep,
  disabled,
  b2pRoute,
}: Props) => {
  const { t } = useTranslation();

  if (b2pRoute) {
    return (
      <SubmitLink
        disabled={disabled}
        isSecondStep={currentStep === 1}
        onClick={onClick}
        data-test="Submit"
        route={b2pRoute}
        target="_blank"
      >
        {buttonText
          ? buttonText
          : t('SMART:Submit.labels.continue', {
              defaultValue: i18nDefaultValues.Submit.labels.continue,
            })}
      </SubmitLink>
    );
  }
  return (
    <Submit
      disabled={disabled}
      isSecondStep={currentStep === 1}
      onClick={onClick}
      data-test="Submit"
    >
      {buttonText
        ? buttonText
        : t('SMART:Submit.labels.continue', {
            defaultValue: i18nDefaultValues.Submit.labels.continue,
          })}
    </Submit>
  );
};
