import { Header, Span, Wrapper } from '../common';
import { RadioCardGroup } from '@pulse-web-ui/radio-card-group';
import { i18nDefaultValues } from '../../i18n';
import { useTranslation } from 'react-i18next';
import { useState, type ChangeEvent } from 'react';
import type { Item } from './types';
import { getDescription } from './utils';
import type { FieldError } from 'react-hook-form';
import { HelperText } from '@pulse-web-ui/helper-text';

interface RepairProps {
  value: string;
  onChange: VoidFn<string>;
  items: Item[];
  error?: FieldError;
}

export const Repair = ({ value, onChange, items, error }: RepairProps) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState(() =>
    getDescription(value, items)
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const description = getDescription(value, items);

    onChange(value);
    setDescription(description);
  };

  const errorMessage = error?.message;

  return (
    <Wrapper>
      <Header>
        {t('SMART:InsuranceParameters.labels.repair', {
          defaultValue: i18nDefaultValues.InsuranceParameters.labels.repair,
        })}
      </Header>
      <HelperText
        noMargin
        status={errorMessage ? 'error' : 'default'}
        message={errorMessage}
      >
        <RadioCardGroup
          name="repair"
          value={value}
          onChange={handleChange}
          items={items}
          error={Boolean(errorMessage)}
        />
        {description && <Span>{description}</Span>}
      </HelperText>
    </Wrapper>
  );
};
