export const i18nDefaultValues = {
  CarOwner: {
    title: 'Владелец авто',
    labels: {
      client: 'Страхователь является владельцем',
    },
    errors: {
      notFilled: 'Поле не заполнено',
    },
  },
};
