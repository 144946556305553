export type TBusCallback<T> = (data: T) => void;

export class EventBus<J extends string> {
  subscriptions: { [key: string]: Array<TBusCallback<any>> };

  constructor() {
    this.subscriptions = {};
  }

  subscribe<T>(eventName: J, callback: TBusCallback<T>) {
    if (!this.subscriptions[eventName]) {
      this.subscriptions[eventName] = [];
    }

    this.subscriptions[eventName].push(callback);

    return () => {
      this.subscriptions[eventName] = this.subscriptions[eventName].filter(
        (cb: TBusCallback<T>) => cb !== callback
      );
    };
  }

  publish<T>(eventName: J, data: T) {
    if (this.subscriptions[eventName]) {
      this.subscriptions[eventName].forEach((callback: TBusCallback<T>) => {
        callback(data);
      });
    }
  }

  reset() {
    this.subscriptions = {};
  }
}
