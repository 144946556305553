import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { CarNumber } from '@entities/import-smart-components/car-number';
import {
  type CarNumberValues,
  CarNumberAnalyticEvent,
} from '@pulse-smart-components-kit/car-number';
import { FallbackSkeleton } from '@shared/components';
import { useHandleManualInput, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useMemo } from 'react';

import type { AnalyticEventsMap } from '@shared/types';
import type { SmartComponentAdapterProps } from '@smart-components/index';
import type { Ref } from 'react';

import { useHandleCarNumber } from './queries';

const analyticEventsMap: AnalyticEventsMap<CarNumberAnalyticEvent> = {
  [CarNumberAnalyticEvent.ON_REG_NUMBER_SELECTED]: {
    name: analyticEvents.regNumberSelected,
  },
  [CarNumberAnalyticEvent.ON_REG_NUMBER_SEARCH]: {
    name: analyticEvents.regNumberSearch,
  },
  [CarNumberAnalyticEvent.ON_REG_NUMBER_NOT_RECEIVED]: {
    name: analyticEvents.regNumberNotReceived,
  },
  [CarNumberAnalyticEvent.ON_SELECT_CAR_DATA]: {
    name: analyticEvents.selectCarData,
  },
};

export const CarNumberAdapter = observer(
  forwardRef(
    (
      { value, onChange }: SmartComponentAdapterProps<CarNumberValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: {
            isCarNumberSmartComponentShowing,
            isCarNumberNotReceived,
            setIsCarNumberNotReceived,
          },
          productStore: {
            formState: { Car },
          },
        },
      } = useStores();

      //TODO: попробовать хранить carNumber только в Car
      const carNumber = isCarNumberSmartComponentShowing
        ? value?.carNumber
        : Car?.carNumber;

      const { handleFindAuto } = useHandleCarNumber(carNumber);

      const handleManualInput = useHandleManualInput(carNumber);

      const options = useMemo(
        () => ({
          onFindAuto: handleFindAuto,
          onManualInput: handleManualInput,
          isCarNumberNotReceived,
          setIsCarNumberNotReceived,
        }),
        [
          handleFindAuto,
          handleManualInput,
          isCarNumberNotReceived,
          setIsCarNumberNotReceived,
        ]
      );

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<CarNumberAnalyticEvent>(
          analyticEventsMap
        );

      if (!isCarNumberSmartComponentShowing) return;

      return (
        <Suspense fallback={<FallbackSkeleton height={274} />}>
          <CarNumber
            value={value}
            ref={forwardRef}
            onChange={onChange}
            isSubmitting={false}
            options={options}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

CarNumberAdapter.displayName = 'CarNumberAdapter';
