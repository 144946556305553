import { useStores } from '@shared/hooks';
import { scrollToIframe } from '@shared/utils';
import { useEffect } from 'react';

export const useHandleNextStep = () => {
  const {
    MainStore: {
      applicationStore: {
        activeStep,
        setActiveStep,
        wantNextStep,
        setWantNextStep,
        isBlockNextStep,
        flowConfig: { maxSteps },
        setIsPaymentStep,
      },
      authStore: { setDisableForm },
      productStore: {
        formState: { CheckPolicy },
        formString,
      },
    },
  } = useStores();

  useEffect(() => {
    if (wantNextStep && activeStep < (maxSteps || 0)) {
      scrollToIframe();
      if (wantNextStep && isBlockNextStep) {
        setDisableForm(true);

        return;
      } else {
        setActiveStep(activeStep + 1);
      }
    }

    // автоинициализация init-order по отметке чекбокса о согласии
    // с условиями обработки данных в documents list
    if (CheckPolicy?.acceptRequirements && activeStep === maxSteps) {
      setIsPaymentStep(true);
      setDisableForm(false);
    }

    setWantNextStep(false);
  }, [
    wantNextStep,
    activeStep,
    maxSteps,
    isBlockNextStep,
    CheckPolicy?.acceptRequirements,
    formString,
  ]);
};
