import { Footer } from '@entities/import-smart-components/footer';
import { FallbackSkeleton } from '@shared/components';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { useHandleGetPrices } from './queries';
import { observer } from 'mobx-react-lite';
import { useGetItems, useGetRequestDate } from './hooks';
import { formattingPrice } from '@shared/utils';
import i18n from '@app/i18n/config';
import { useStores } from '@shared/hooks';

export const FooterAdapter = observer(() => {
  const {
    MainStore: {
      productStore: { orderData },
    },
  } = useStores();
  const [prevPrice, setPrevPrice] = useState('0');

  const requestData = useGetRequestDate();
  const { data } = useHandleGetPrices(requestData);

  const items = useGetItems();

  const main = useMemo(() => {
    const price =
      orderData?.premiumAndDelta || data?.premiumAndDelta || prevPrice;

    return {
      title: i18n.t('COMMON:labels.price'),
      value: formattingPrice(Number(price)),
      isMain: true,
    };
  }, [data?.premiumAndDelta, prevPrice, orderData?.premiumAndDelta]);

  useEffect(() => {
    const price = orderData?.premiumAndDelta ?? data?.premiumAndDelta;

    if (price) {
      setPrevPrice(price);
    }
  }, [data?.premiumAndDelta, orderData?.premiumAndDelta]);

  return (
    <Suspense fallback={<FallbackSkeleton height={274} />}>
      <Footer main={main} items={items} />
    </Suspense>
  );
});
