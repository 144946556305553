import { DEFAULT_OPTION } from '@shared/constants';
import { useStores } from './use-stores';
import { useCallback } from 'react';

export const useHandleManualInput = (carNumber: string | undefined) => {
  const {
    MainStore: {
      initProductStore: {
        initState: { minProductLimit },
      },
      applicationStore: { updateFormValue, setIsManualCarInput },
    },
  } = useStores();

  return useCallback(() => {
    setIsManualCarInput(true);

    updateFormValue('Car', {
      carNumber,
      marketPrice: minProductLimit,
      car: {
        model: DEFAULT_OPTION,
        power: DEFAULT_OPTION,
        brand: DEFAULT_OPTION,
        manufactureYear: DEFAULT_OPTION,
      },
      isManualVINInput: true,
      vin: '',
      mileage: null,
    });
  }, [carNumber, minProductLimit]);
};
