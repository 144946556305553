export const i18nDefaultValues = {
  AuthSchema: {
    errors: {
      insuranceFromAgeEighteen:
        'Возраст страхователя не может быть меньше 18 лет',
      insuranceUpToAgeHundred:
        'Возраст страхователя не может быть больше 100 лет',
      passportFromYear1997:
        'Дата выдачи паспорта не может быть ранее 01.10.1997',
      passportFromAgeFourteen:
        'Минимальный возраст на дату выдачи должен быть не менее 14 лет',
      invalidPassportFormat: 'Неверный формат паспорта',
      invalidPassportCodeFormat: 'Неверный формат кода подразделения',
      invalidDateFormat: 'Неверный формат даты',
      authLimitExceeded: 'Превышен лимит попыток авторизации',
      notFilled: 'Поле не заполнено',
      maxLength: 'Максимальная длина 50 символов',
      maxLength100: 'Максимальная длина 100 символов',
      useCyrillic: 'Используйте кириллицу',
      nameStartWithLetter: 'Имя должно начинаться с буквы',
      lastnameStartWithLetter: 'Фамилия должна начинаться с буквы',
      middleNameStartWithLetter: 'Отчество должно начинаться с буквы',
      incorrectEmail: 'Некорректный адрес электронной почты',
      enterEmail: 'Укажите электронную почту',
      enterPhone: 'Введите номер телефона',
      wrongPhone: 'Неверный номер телефона',
      numberMatchesAgent: 'Номер совпадает с номером агента',
      addressIsNotDefine:
        'Не можем найти подходящий адрес. Попробуйте ввести его по другому',
    },
  },
};
