export const i18nDefaultValues = {
  Car: {
    title: 'Автомобиль',
    hints: {
      input: 'Найдите свой авто по госномеру или укажите данные вручную',
      rangePrice: 'от {{minMarketPrice}} до {{maxMarketPrice}}',
    },
    labels: {
      brand: 'Марка',
      model: 'Модель',
      manufactureYear: 'Год выпуска',
      power: 'Мощность',
      apply: 'Сохранить изменения',
      findCar: 'Найти авто',
      changeInfo: 'Изменить',
      currentMarketPrice: 'Текущая рыночная стоимость, руб',
      mileage: 'Приблизительный пробег, км',
      haveValidPolicy: 'Есть действующий полис КАСКО',
      carOnLoan: 'Автомобиль в кредите',
      vin: 'VIN',
      numberNotReceived: 'Госномер не получен',
      numberReceived: 'Есть госномер',
      carDescription: '{{year}} г.в., {{power}} л.c.',
    },
    errors: {
      requiredCarNumber: 'Укажите госномер авто',
      invalidCarNmber: 'Неверный госномер авто',
      requiredMarketPrice: 'Укажите текущую рыночную стоимость авто',
      requiredMileage: 'Укажите пробег авто',
      rangeMileage: 'Укажите значение от 1 до 100 000 000',
      rangeMarketPrice:
        'Укажите значение от {{minMarketPrice}} до {{maxMarketPrice}}',
      requiredVIN: 'Укажите VIN',
      minLengthMileage: 'VIN должен состоять из 17 символов',
      formatVIN: 'VIN не должен состоять только из цифр',
      requiredBrand: 'Укажите марку авто',
      requiredModel: 'Укажите модель авто',
      requiredManufactureYear: 'Укажите год выпуска авто',
      requiredPower: 'Укажите мощность авто',
      unknownCarNumber:
        'Не удалось получить данные авто по этому госномеру. Если номер введен правильно, укажите данные вручную',
    },
    alerts: {
      notFoundAllCarData: {
        title: 'К сожалению мы нашли не все данные по вашему автомобилю',
        description: 'Дополните недостающие сведения вручную',
      },
    },
  },
};
