export const i18nDefaultValues = {
  WhoIsPolicyholder: {
    title: 'Страхователь',
    hints: {
      sendByPhone: 'На него мы отправим проверочный код',
      sendByMail: 'На эту почту мы отправим страховой полис и другие документы',
    },
    errors: {
      incorrectCodeFromSms: 'Неверный код из SMS',
      userIsNotDefine: 'Номер не зарегистрирован',
    },
  },
};
