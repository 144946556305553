import { AutocompleteSelect } from '@pulse-web-ui/autocomplete-select';
import { Button } from '@pulse-web-ui/button';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';
import { useUpdateEffect } from '@shared/hooks';
import React, { useCallback, useEffect, useState } from 'react';

import type { CarData, CarOptions } from '../../types';

import { Block } from './modal-content.style';
import { CarAnalyticEvent } from '../../constants';
import { CarDataKey } from '../../types';

interface Values {
  [CarDataKey.BRAND]: string;
  [CarDataKey.MODEL]: string;
  [CarDataKey.MANUFACTURE_YEAR]: string;
  [CarDataKey.POWER]: string;
}

interface ModalContentProps
  extends Pick<
    CarOptions,
    | 'onFindBrands'
    | 'brands'
    | 'onFindModels'
    | 'models'
    | 'onFindYears'
    | 'years'
    | 'onFindPowers'
    | 'powers'
  > {
  title: string;
  brand: string;
  model: string;
  manufactureYear: string;
  power: string;
  buttonLabel: string;
  onCloseModal: () => void;
  onSaveCarData: (carData: CarData) => void;
  carData?: CarData;
  onAnalyticEventSend?: VoidFn<CarAnalyticEvent>;
}

export const ModalContent = ({
  buttonLabel,
  brand,
  manufactureYear,
  model,
  power,
  title,
  onCloseModal,
  onFindBrands,
  brands = [],
  onFindModels,
  models = [],
  onFindYears,
  years = [],
  onFindPowers,
  powers = [],
  onSaveCarData,
  carData,
  onAnalyticEventSend,
}: ModalContentProps) => {
  const [values, setValues] = useState<Values>({
    brand: carData?.brand?.value ?? '',
    model: carData?.model?.value ?? '',
    manufactureYear: carData?.manufactureYear?.value ?? '',
    power: carData?.power?.value ?? '',
  });

  const findData = useCallback(
    (key: CarDataKey, value: string) => {
      if (key === CarDataKey.BRAND) {
        return brands.find((brand) => brand.value === value);
      }
      if (key === CarDataKey.MODEL) {
        return models.find((model) => model.value === value);
      }
      if (key === CarDataKey.MANUFACTURE_YEAR) {
        return years.find((year) => year.value === value);
      }
      if (key === CarDataKey.POWER) {
        return powers.find((power) => power.value === value);
      }
    },
    [brands, models, years, powers]
  );

  const handleSaveCarData = useCallback(() => {
    onSaveCarData(
      (Object.keys(values) as CarDataKey[]).reduce<CarData>(
        (acc: CarData, key) => ({
          ...acc,
          [key]: findData(key, values[key]),
        }),
        {}
      )
    );
    onAnalyticEventSend?.(CarAnalyticEvent.ON_CHANGE_CAR_DATA_ACCEPTED);
    onCloseModal();
  }, [values, findData]);

  const handleSelectChange = useCallback(
    (key: CarDataKey, fieldsToReset: CarDataKey[]) =>
      (value: string | undefined) => {
        setValues((prev) => ({
          ...prev,
          [key]: value ?? '',
          ...fieldsToReset.reduce(
            (acc, fieldKey) => ({ ...acc, [fieldKey]: '' }),
            {}
          ),
        }));
      },
    []
  );

  useEffect(() => {
    onFindBrands();
  }, []);

  useUpdateEffect(() => {
    if (values.brand) {
      onFindModels(values.brand);
      onAnalyticEventSend?.(CarAnalyticEvent.ON_BRAND_SELECTED);
    }
  }, [values.brand]);

  useUpdateEffect(() => {
    if (values.model) {
      onFindYears(values.model);
      onAnalyticEventSend?.(CarAnalyticEvent.ON_MODEL_SELECTED);
    }
  }, [values.model]);

  useUpdateEffect(() => {
    if (values.manufactureYear) {
      onFindPowers(values.manufactureYear);
      onAnalyticEventSend?.(CarAnalyticEvent.ON_YEAR_SELECTED);
    }
  }, [values.manufactureYear]);

  useUpdateEffect(() => {
    if (values.power) onAnalyticEventSend?.(CarAnalyticEvent.ON_POWER_SELECTED);
  }, [values.power]);

  return (
    <>
      <HeaderWithSubText title={title} />
      <Block>
        <AutocompleteSelect
          options={brands}
          label={brand}
          placeholder={brand}
          onChange={handleSelectChange(CarDataKey.BRAND, [
            CarDataKey.MODEL,
            CarDataKey.MANUFACTURE_YEAR,
            CarDataKey.POWER,
          ])}
          value={values.brand}
        />
        <AutocompleteSelect
          options={models}
          label={model}
          placeholder={model}
          onChange={handleSelectChange(CarDataKey.MODEL, [
            CarDataKey.MANUFACTURE_YEAR,
            CarDataKey.POWER,
          ])}
          value={values.model}
          disabled={!values.brand}
        />
        <AutocompleteSelect
          options={years}
          label={manufactureYear}
          placeholder={manufactureYear}
          onChange={handleSelectChange(CarDataKey.MANUFACTURE_YEAR, [
            CarDataKey.POWER,
          ])}
          value={values.manufactureYear}
          disabled={!values.model}
        />
        <AutocompleteSelect
          options={powers}
          label={power}
          placeholder={power}
          onChange={handleSelectChange(CarDataKey.POWER, [])}
          value={values.power}
          disabled={!values.manufactureYear}
        />
      </Block>
      <Button
        label={buttonLabel}
        onClick={handleSaveCarData}
        disabled={Object.values(values).includes('')}
        adaptiveWidth
      />
    </>
  );
};
