/* eslint-disable indent */
import { useStores } from '@shared/hooks';
import { format } from 'date-fns';
import { DEFAULT_PERIOD } from '../constants';
import type { GetPriceRequest } from '../queries';
import { getRisks, getFranchise } from '@shared/utils';
import { DATE_FORMAT } from '@shared/constants';

export const useGetRequestDate = () => {
  const {
    MainStore: {
      initProductStore: {
        initState: { code },
      },
      productStore: {
        formState: { InsuranceParameters, Car, Drivers, InsuranceRisks },
      },
    },
  } = useStores();

  const productVersion = code ?? '';

  const date = InsuranceParameters?.period?.startDate ?? new Date();
  const dateBegin = format(date, DATE_FORMAT);

  const contractDuration =
    InsuranceParameters?.period?.period ?? DEFAULT_PERIOD;

  const franchise = getFranchise(Number(InsuranceParameters?.franchise));

  const price = Number(Car?.marketPrice) || 0;

  const insuranceObjects = [
    {
      makeId: Car?.car?.brand?.value ?? '',
      make: Car?.car?.brand?.label ?? '',
      modelId: Car?.car?.model?.value,
      model: Car?.car?.model?.label,
      year: Number(Car?.car?.manufactureYear?.value) || 0,
      enginePower: Number(Car?.car?.power?.value) || undefined,
      region: InsuranceParameters?.region ?? '',
      price,
      stoType: InsuranceParameters?.repair ?? '',
      franchise,
    },
  ];

  const minDriverAge = Drivers?.minDriverAge ?? 0;

  const minDriverExperience = Drivers?.minDriverExperience ?? 0;

  const risks = getRisks({ insuranceRisks: InsuranceRisks, amount: price });

  const data: GetPriceRequest = {
    productVersion,
    dateBegin,
    contractDuration,
    insuranceObjects,
    minDriverAge,
    minDriverExperience,
    risks,
  };

  return data;
};
