import { styled, typography } from '@pulse-web-ui/theme';

export const Button = styled.button`
  border: none;
  background-color: unset;
  padding: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const Paragraph = styled.p`
  ${typography.regular16};
`;
