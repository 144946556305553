import { useTranslation } from 'react-i18next';
import {
  SubTitle,
  Title,
  StyledWidgetContainer,
  SpinnerWrapper,
} from './loader.styles';
import { Spinner } from '@pulse-web-ui/spinner';
import { i18nDefaultValues } from '../../i18n';

export const Loader = () => {
  const { t } = useTranslation();

  return (
    <StyledWidgetContainer data-testid="check-policy-loader">
      <SpinnerWrapper>
        <Spinner size={40} />
      </SpinnerWrapper>
      <Title>
        {t('SMART:CheckPolicyAdapter.loader.title', {
          defaultValue: i18nDefaultValues.CheckPolicyAdapter.loader.title,
        })}
      </Title>
      <SubTitle>
        {t('SMART:CheckPolicyAdapter.loader.subTitle', {
          defaultValue: i18nDefaultValues.CheckPolicyAdapter.loader.subTitle,
        })}
      </SubTitle>
    </StyledWidgetContainer>
  );
};
