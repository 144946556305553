/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from '@app/i18n/config';
import type { ThemeConfig } from '@shared/types';
import { useEffect } from 'react';

export const useDictionary = (
  resTexts: ThemeConfig | undefined,
  isLoadingTexts: boolean
) => {
  useEffect(() => {
    if (resTexts && (resTexts as any)?.ru && !isLoadingTexts) {
      Object.keys((resTexts as any).ru)?.forEach((item) => {
        if ((resTexts as any).ru[item]) {
          i18n.addResourceBundle(
            'ru',
            item,
            (resTexts as any).ru[item],
            true,
            true
          );
        }
      });
    }
  }, [resTexts, isLoadingTexts]);
};
