import { FC } from 'react';
import { Wrapper } from './fallback-skeleton.styles';
import type { FallbackSkeletonProps } from './fallback-skeleton.types';

export const FallbackSkeleton: FC<FallbackSkeletonProps> = ({
  height,
  transparent,
  children,
}) => {
  return (
    <Wrapper height={height} transparent={transparent}>
      {children}
    </Wrapper>
  );
};
