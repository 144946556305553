import { i18nDefaultValues } from '../i18n';
import i18n from '@app/i18n/config';
import { Document } from '../constants';

export const getLabel = (document: string, label: string): string => {
  if (document === Document.STS || document === Document.PTS)
    return i18n.t('SMART:VehicleDocument.label.documentSerieAndNumber', {
      document: label,
      defaultValue:
        i18nDefaultValues.VehicleDocument.labels.documentSerieAndNumber,
    });
  if (document === Document.EPTS)
    return i18n.t('SMART:VehicleDocument.label.documentNumber', {
      document: label,
      defaultValue: i18nDefaultValues.VehicleDocument.labels.documentNumber,
    });
  return '';
};
