import { Info } from '@pulse-web-ui/icons';
import { BaseThemeType, useTheme } from '@pulse-web-ui/theme';
import { Button, Paragraph } from './franchise-info.styles';
import { Modal } from '@pulse-web-ui/modal';
import { useState } from 'react';
import { i18nDefaultValues } from '../../i18n';
import { Trans, useTranslation } from 'react-i18next';

export const FranchiseInfo = () => {
  const { t } = useTranslation();

  // TODO: Добавить дженерик для хука useTheme для передачи типа BaseThemeType
  const theme = useTheme() as BaseThemeType;

  const [openModal, setOpenModal] = useState(false);

  const open = () => {
    setOpenModal(true);
  };

  const close = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button onClick={open}>
        <Info width={24} color={theme.colors.icon.secondary} />
      </Button>
      <Modal
        showDialog={openModal}
        closeHandler={close}
        submitLabel={t('SMART:InsuranceParameters.buttons.accessibly', {
          defaultValue:
            i18nDefaultValues.InsuranceParameters.buttons.accessibly,
        })}
        submitHandler={close}
        title={t('SMART:InsuranceParameters.labels.franchise', {
          defaultValue: i18nDefaultValues.InsuranceParameters.labels.franchise,
        })}
      >
        <>
          <Paragraph>
            <Trans>
              {t('SMART:InsuranceParameters.texts.franchise', {
                defaultValue:
                  i18nDefaultValues.InsuranceParameters.texts.franchise,
              })}
            </Trans>
          </Paragraph>
        </>
      </Modal>
    </>
  );
};
