import { GetSubmitOrderResponse } from '@shared/queries/documents-list/submit-order';
import { ContractDataType } from '@shared/types';

export const getInitOrderData = (
  resSubmitOrder: GetSubmitOrderResponse | undefined,
  profile: any //TODO: Type
): ContractDataType => ({
  amount: resSubmitOrder?.premiumAndDelta,
  contractId: resSubmitOrder?.contractId,
  contractNumber: resSubmitOrder?.contractNumber,
  email: profile?.profile?.email,
  firstName: profile?.profile?.firstName,
  lastName: profile?.profile?.surname,
  patronymic: profile?.profile?.secondName,
  operationType: 'ORDER',
  orderNumber: resSubmitOrder?.orderNumber,
  paymentDocumentId: resSubmitOrder?.billId,
  subscriptionId: resSubmitOrder?.subscriptionId,
  subscriptionType: resSubmitOrder?.subscriptionType,
});
