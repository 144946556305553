import type { ExtraParam } from '@shared/types';
import { DEFAULT_FRANCHISE_OPTION } from '../constants';

export const getDefaultFranchiseValue = (
  defaultProductValues: ExtraParam[] = []
) => {
  const franchiseMode =
    defaultProductValues.find((item) => item.code === 'defaultFranchiseMode')
      ?.value === 'true';

  if (!franchiseMode) {
    return DEFAULT_FRANCHISE_OPTION.value;
  }

  const franchiseValue =
    defaultProductValues.find((item) => item.code === 'defaultFranchiseValue')
      ?.value ?? DEFAULT_FRANCHISE_OPTION.value;

  return franchiseValue;
};
