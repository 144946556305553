import { media, mixins, styled, typography } from '@pulse-web-ui/theme';
import { Button } from '@pulse-web-ui/button';

export const PromoWrapper = styled.div`
  ${mixins.flex({ gap: 8 })};
  width: 100%;
`;

export const PromoTextInputWrapper = styled.div`
  flex: auto;
`;

export const PromoSubmitButton = styled(Button)`
  ${typography.bold18};
  ${media.desktop} {
    width: auto;
  }
  width: 100%;
`;
