/* eslint-disable indent */
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';
import { phoneRegEx } from '@shared/constants';
import { memo, useCallback, type FocusEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { PhoneAndEmailValues, PhoneAndEmailProps } from './types';
import type { ControllerRenderProps } from 'react-hook-form';

import { i18nDefaultValues } from './i18n';
import { Row, RowSection } from './phone-and-email.styles';

const PhoneAndEmailWithoutMemo = ({
  getDisableState,
  onBlurEmail,
  onBlurPhone,
}: PhoneAndEmailProps) => {
  const { t } = useTranslation();

  const { setValue, control, clearErrors } =
    useFormContext<PhoneAndEmailValues>();

  const handleChange = useCallback(
    (
      onChange: ControllerRenderProps<PhoneAndEmailValues>['onChange'],
      name: keyof PhoneAndEmailValues
    ) =>
      (value: string) => {
        onChange(value);
        clearErrors(name);
      },
    [clearErrors]
  );

  const handleBlurEmail = useCallback((event: FocusEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim();
    setValue('email', newValue);
    if (newValue.length) {
      onBlurEmail?.();
    }
  }, []);

  const handleBlurPhone = useCallback((event: FocusEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (phoneRegEx.test(newValue)) {
      onBlurPhone?.();
    }
  }, []);

  return (
    <Row>
      <RowSection>
        <Controller
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <HelperText
              noMargin
              status={error ? 'error' : 'default'}
              message={
                error?.message ??
                t('SMART:PhoneAndEmail.hints.sendByPhone', {
                  defaultValue:
                    i18nDefaultValues.PhoneAndEmail.hints.sendByPhone,
                })
              }
            >
              <Input
                type="tel"
                label={t('SMART:PhoneAndEmail.labels.phone', {
                  defaultValue: i18nDefaultValues.PhoneAndEmail.labels.phone,
                })}
                value={value}
                onChange={handleChange(onChange, name)}
                error={!!error}
                onBlur={handleBlurPhone}
                disabled={getDisableState('phone')}
                data-testid="phone"
              />
            </HelperText>
          )}
          control={control}
          name="phone"
        />
      </RowSection>
      <RowSection>
        <Controller
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <HelperText
              status={error ? 'error' : 'default'}
              message={
                error?.message ??
                t('SMART:PhoneAndEmail.hints.sendByMail', {
                  defaultValue:
                    i18nDefaultValues.PhoneAndEmail.hints.sendByMail,
                })
              }
            >
              <Input
                onChange={handleChange(onChange, name)}
                value={value}
                error={!!error}
                disabled={getDisableState('email')}
                label={t('SMART:PhoneAndEmail.labels.email', {
                  defaultValue: i18nDefaultValues.PhoneAndEmail.labels.email,
                })}
                onBlur={handleBlurEmail}
                data-testid="email"
              />
            </HelperText>
          )}
          control={control}
          name="email"
        />
      </RowSection>
    </Row>
  );
};

export const PhoneAndEmail = memo(
  PhoneAndEmailWithoutMemo
) as typeof PhoneAndEmailWithoutMemo;
