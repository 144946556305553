export enum Currency {
  RUB = 'RUB',
  EUR = 'EUR',
  EURO = 'EURO',
  USD = 'USD',
}

export enum CurrencyLabel {
  RUB = '₽',
  EUR = '€',
  USD = '$',
}
