import { styled } from '@pulse-web-ui/theme';

export const LeftSlotContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${({ theme }) => theme.colors.neutral[10]};
  border-radius: calc(${({ theme }) => theme.common.radius} * 8);
  color: ${({ theme }) => theme.colors.text.interactive};
`;
