/* eslint-disable indent */
import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';
import {
  DEFAULT_OPTION,
  MAX_CAR_SEARCH_ATTEMPTS_COUNT,
} from '@shared/constants';
import { useHandleManualInput, useStores } from '@shared/hooks';
import { useFetchCarData } from '@shared/queries';
import { useCallback, useEffect, useState } from 'react';
import { useQueryErrorResetBoundary } from 'react-query';

export const useHandleCarData = (carNumber?: string) => {
  const {
    MainStore: {
      applicationStore: {
        updateFormValue,
        setHasUnknownCarNumberError,
        setIsManualCarInput,
        incrementCarSearchAttemptsCount,
        carSearchAttemptsCount,
      },
      initProductStore: {
        initState: { code, minProductLimit },
      },
      productStore: {
        car,
        formState: { Car: CarFormState },
        setCar,
      },
    },
  } = useStores();

  const [wantFindCar, setWantFindCar] = useState(false);

  const {
    refetch: refetchCar,
    data: carData,
    error: carError,
  } = useFetchCarData(code, carNumber);

  const isFoundAllCarData = Boolean(
    carData
      ? carData?.makeId && carData?.modelId && carData?.power && carData?.year
      : car?.makeId && car?.modelId && car?.power && car?.year
  );

  const handleManualInput = useHandleManualInput(carNumber);
  const { reset } = useQueryErrorResetBoundary();

  const handleFindCar = useCallback(() => {
    if (code && carNumber) {
      incrementCarSearchAttemptsCount();

      if (carSearchAttemptsCount < MAX_CAR_SEARCH_ATTEMPTS_COUNT) {
        refetchCar();
        setWantFindCar(true);
      } else {
        handleManualInput();
        setCar({ requestId: '' });
      }
    }
  }, [code, carNumber, carSearchAttemptsCount]);

  useEffect(() => {
    if (!wantFindCar) return;
    if (!carData) return;

    setCar(carData);

    setIsManualCarInput(!isFoundAllCarData);

    const event = isFoundAllCarData
      ? analyticEvents.regNumberFound
      : analyticEvents.regNumberNotAllDataFound;

    sendAnalyticEvent(event);

    updateFormValue('Car', {
      ...CarFormState,
      car: {
        model: carData.makeId
          ? { label: carData?.model, value: carData?.modelId }
          : DEFAULT_OPTION,
        power:
          carData.makeId && carData.modelId && carData.power
            ? {
                label: carData?.power?.toString(),
                value: carData?.power?.toString(),
              }
            : DEFAULT_OPTION,
        brand: { label: carData?.make, value: carData?.makeId },
        manufactureYear:
          carData.makeId && carData.modelId
            ? {
                label: carData?.year?.toString(),
                value: carData?.year?.toString(),
              }
            : DEFAULT_OPTION,
      },
      isManualVINInput: false,
      carNumber: CarFormState?.carNumber,
      vin: carData.vin,
      marketPrice:
        isFoundAllCarData && carData?.price?.price
          ? carData?.price?.price
          : minProductLimit,
      mileage: null,
    });

    updateFormValue('Drivers', {
      minDriverAge: null,
      minDriverExperience: null,
    });

    setWantFindCar(false);
  }, [carData]);

  useEffect(() => {
    if (wantFindCar && carError) {
      handleManualInput();
      setCar({ requestId: '' });
      reset();
      setWantFindCar(false);
      setHasUnknownCarNumberError(true);
    }
  }, [carError]);

  return {
    isFoundAllCarData,
    handleFindCar,
  };
};
