import { AddressType, PASSPORT_CARD_TYPE } from '@shared/constants';
import { getFormattedDate, getPassportValues } from '@shared/utils';

import type { RegistrationRequestDataType } from '@features/auth-box';
import type { WhoIsPolicyholderValues } from '@smart-components/index';

interface GetRegistrationDataFn {
  whoIsPolicyholder?: WhoIsPolicyholderValues;
  regUtm?: Record<string, any>;
  promoAgreement: boolean;
}
export const getRegistrationData = ({
  whoIsPolicyholder,
  regUtm,
  promoAgreement,
}: GetRegistrationDataFn): RegistrationRequestDataType => {
  const extendedIntermediaryChannelCode = `${regUtm?.media_source ?? '0000'},${
    regUtm?.campaign ?? 'WEB'
  }`;

  const userPolicies = [
    { approvedAt: new Date(), code: '003' },
    { approvedAt: new Date(), code: '005' },
    ...(promoAgreement ? [{ approvedAt: new Date(), code: '002' }] : []),
  ];

  const registrationData: RegistrationRequestDataType = {
    user: {
      userPolicies,
      extendedIntermediaryChannelCode,
      firstName: whoIsPolicyholder?.name?.trim() ?? '',
      lastName: whoIsPolicyholder?.surname?.trim() ?? '',
      middleName: whoIsPolicyholder?.middlename?.trim() ?? '',
      birthDay: whoIsPolicyholder?.birthday
        ? getFormattedDate(whoIsPolicyholder?.birthday)
        : '',
      passport: {
        cardType: PASSPORT_CARD_TYPE,
        cardSeries: whoIsPolicyholder?.passport
          ? getPassportValues(whoIsPolicyholder?.passport)[0]
          : '',
        cardNumber: whoIsPolicyholder?.passport
          ? getPassportValues(whoIsPolicyholder?.passport)[1]
          : '',
        cardIssueDate: whoIsPolicyholder?.passportDate
          ? getFormattedDate(whoIsPolicyholder?.passportDate)
          : '',
        cardIssuerCode: whoIsPolicyholder?.passportCode
          ? whoIsPolicyholder?.passportCode.trim().split('-').join('')
          : '',
        cardIssuerName: whoIsPolicyholder?.passportByWho?.trim() ?? '',
        addresses: [
          {
            addressType: AddressType.REGISTRATION,
            address: whoIsPolicyholder?.address?.value?.trim() ?? '',
            addressCode: whoIsPolicyholder?.address?.fias_id ?? '',
          },
        ],
      },
      email: whoIsPolicyholder?.email ?? '',
      clientCategory: 'STANDART',
      utmSource: regUtm?.utm_source ?? '',
      utmMedium: regUtm?.utm_medium ?? '',
      utmCampaign: regUtm?.utm_campaign ?? '',
      utmContent: regUtm?.utm_content ?? '',
      wmId: regUtm?.wm_id ?? '',
    },
  };

  return registrationData;
};
