import type { AgeRange } from '@shared/types';

type Args = {
  birthday: Date;
  policyStartDate: Date;
  ageRange: AgeRange;
};

export const valideBirthdayByPolicyStartDate = ({
  birthday,
  policyStartDate,
  ageRange,
}: Args): boolean => {
  const birthDate = new Date(birthday);
  const end = new Date(policyStartDate);
  end.setFullYear(end.getFullYear() - ageRange.min);
  end.setDate(end.getDate());

  const start = new Date(policyStartDate);
  start.setFullYear(start.getFullYear() - ageRange.max - 1);
  start.setDate(start.getDate() + 1);

  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);
  birthDate.setHours(0, 0, 0, 0);

  return (
    start.getTime() <= birthDate.getTime() &&
    birthDate.getTime() <= end.getTime()
  );
};
