import i18n from '@app/i18n/config';
import { declineWord } from '@shared/utils';

enum TimeUnit {
  YEAR = 'Y',
  MONTH = 'M',
  DAY = 'D',
}

const ACCESS_TIME_UNIT = [TimeUnit.DAY, TimeUnit.MONTH, TimeUnit.YEAR];

const declination = {
  [TimeUnit.YEAR]: {
    one: i18n.t('COMMON:declensions.year-1'),
    few: i18n.t('COMMON:declensions.year-2'),
    many: i18n.t('COMMON:declensions.year-3'),
  },
  [TimeUnit.MONTH]: {
    one: i18n.t('COMMON:declensions.month-1'),
    few: i18n.t('COMMON:declensions.month-2'),
    many: i18n.t('COMMON:declensions.month-3'),
  },
  [TimeUnit.DAY]: {
    one: i18n.t('COMMON:declensions.day-1'),
    few: i18n.t('COMMON:declensions.day-2'),
    many: i18n.t('COMMON:declensions.day-3'),
  },
};

// period формата P15D, P1Y, P2M
export const formattingPeriod = (period: string) => {
  const timeUnit = period.slice(-1) as TimeUnit;

  if (!ACCESS_TIME_UNIT.includes(timeUnit)) {
    return '';
  }

  const duration = parseInt(period.slice(1, -1));
  const words = declination[timeUnit];

  return declineWord(duration, words);
};
