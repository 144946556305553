const DEFAULT_REGEXP = /^(\d{2} \w{2}) (\d{6})$/;

export const parsingDocument = (
  document: string,
  regex = DEFAULT_REGEXP
): [string, string] => {
  const match = document.match(regex);

  if (match) {
    return [match[1], match[2]];
  }

  return ['', ''];
};
