export const getFormattedPassport = (input: string): string => {
  if (input.length !== 10) {
    return '';
  }

  // Разделяем строку на массив пар символов
  const result = input.match(/(\d{2})/g) || [];

  // Соединяем первые две пары пробелом, а остальное оставляем как есть
  return result.slice(0, 2).join(' ') + ' ' + result.slice(2).join('');
};
