import { PaymentLayout } from '@shared/layouts/payment-layout';
import { Trans, useTranslation } from 'react-i18next';

import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { scrollToIframe } from '@shared/utils';
import { lazy, Suspense, useEffect } from 'react';

const DocumentsIcon = lazy(() => import('@shared/icons/documents'));
const ChangeIcon = lazy(() => import('@shared/icons/change'));
const CrashIcon = lazy(() => import('@shared/icons/crash'));

export const InProgressPay = observer(() => {
  const {
    MainStore: {
      applicationStore: { deviceType, phoneFromMobileApp },
    },
  } = useStores();
  const { t } = useTranslation();

  const policyInProgress = [
    {
      icon: (
        <Suspense>
          <CrashIcon />
        </Suspense>
      ),
      text: t('COMMON:pay.reportOccurrence'),
    },
    {
      icon: (
        <Suspense>
          <ChangeIcon />
        </Suspense>
      ),
      text: t('COMMON:pay.changeSubscription'),
    },
    {
      icon: (
        <Suspense>
          <DocumentsIcon />
        </Suspense>
      ),
      text: t('COMMON:pay.viewUsefulMaterials'),
    },
  ];

  useEffect(() => {
    scrollToIframe();
  }, []);

  return (
    <PaymentLayout
      policyLabel={t('COMMON:pay.payInProgressLabel')}
      isMobileApp={Boolean(phoneFromMobileApp && deviceType)}
      policyText={<Trans>{t('COMMON:pay.payInProgressText')}</Trans>}
      items={policyInProgress}
      labelTest="Pay-in-progress-block"
    />
  );
});
