import type { ExtraParam } from '@shared/types';

interface GetMaxCountDays {
  hasKasko: boolean;
  defaultProductValues?: ExtraParam[];
}
export const getMaxCountDays = ({
  hasKasko = false,
  defaultProductValues = [],
}: GetMaxCountDays) => {
  if (hasKasko) {
    const countDays =
      defaultProductValues.find(
        (item) => item.code === 'maxPoliceStartDateHasKasko'
      )?.value ?? 0;

    return Number(countDays);
  }

  const countDays =
    defaultProductValues.find(
      (item) => item.code === 'maxPoliceStartDateHasNotKasko'
    )?.value ?? 0;

  return Number(countDays);
};
