import {
  AutocompleteSelect,
  type Option,
} from '@pulse-web-ui/autocomplete-select';
import { HelperText } from '@pulse-web-ui/helper-text';
import { useTranslation } from 'react-i18next';

import type { FieldError } from 'react-hook-form';

import { Wrapper } from './region.styles';
import { InsuranceParametersAnalyticEvent } from '../../constants';
import { i18nDefaultValues } from '../../i18n';
import { Span } from '../common';

interface RegionProps {
  value: string;
  onChange: (value: string) => void;
  options: Option[];
  error?: FieldError;
  onAnalyticEventSend?: VoidFn<InsuranceParametersAnalyticEvent>;
}

export const Region = ({
  value,
  onChange,
  options,
  error,
  onAnalyticEventSend,
}: RegionProps) => {
  const { t } = useTranslation();

  const handleSelect = (value: string | undefined) => {
    onChange(value ?? '');
    onAnalyticEventSend?.(InsuranceParametersAnalyticEvent.ON_REGION_SELECTED);
  };

  const errorMessage = error?.message;

  return (
    <Wrapper>
      <HelperText
        noMargin
        status={errorMessage ? 'error' : 'default'}
        message={errorMessage}
      >
        <AutocompleteSelect
          value={value}
          onChange={handleSelect}
          placeholder={t('SMART:InsuranceParameters.placeholders.region', {
            defaultValue:
              i18nDefaultValues.InsuranceParameters.placeholders.region,
          })}
          options={options}
          status={errorMessage ? 'error' : undefined}
        />
      </HelperText>
      <Span>
        {t('SMART:InsuranceParameters.hints.specifyRegion', {
          defaultValue:
            i18nDefaultValues.InsuranceParameters.hints.specifyRegion,
        })}
      </Span>
    </Wrapper>
  );
};
