export const i18nDefaultValues = {
  Policyholder: {
    labels: {
      surname: 'Фамилия',
      name: 'Имя',
      middlename: 'Отчество',
      birthday: 'Дата рождения',
      passport: 'Серия и номер паспорта',
      passportDate: 'Дата выдачи',
      passportByWho: 'Кем выдан',
      passportCode: 'Код подразделения',
      address: 'Адрес регистрации',
      client: 'Страхователь является владельцем',
      phone: 'Телефон',
      email: 'Email',
    },
  },
};
