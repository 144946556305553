import { useStores } from '@shared/hooks';
import debounce from 'lodash.debounce';
import { useCallback, useEffect } from 'react';
import type { FieldValues, UseFormWatch, WatchObserver } from 'react-hook-form';

export const useSynchronizeStore = (
  watch: UseFormWatch<FieldValues>,
  setFormState: VoidFn<Record<string, any>>,
  clearErrors: VoidFn
) => {
  const {
    MainStore: {
      authStore: { setAuthError },
    },
  } = useStores();

  const synchronizeStoreForm = useCallback(
    debounce<WatchObserver<FieldValues>>((values) => {
      setAuthError(false);
      setFormState(values);
      clearErrors();
    }, 1),
    []
  );

  useEffect(() => {
    const subscription = watch(synchronizeStoreForm);

    return () => subscription.unsubscribe();
  }, [watch]);
};
