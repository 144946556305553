/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { Input } from '@pulse-web-ui/input';
import { useTheme } from '@pulse-web-ui/theme';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Props } from './types';

import { Resend } from '..';
import {
  InputCodeRow,
  InputCodeWrapper,
  StyledButton,
  StyledChangePhoneButton,
} from './input-code.styles';
import { i18nDefaultValues } from '../../i18n';

const ArrowRight = lazy(
  () => import('@pulse-web-ui/icons/lib/lazy/arrow-right')
);

const EXPIRE_PERIOD = 30;
const OTP_MAX_LENGTH = 5;
const PLACEHOLDER = '00000';

export const InputCode = ({
  value,
  onChange,
  changePhone,
  submitCode,
  disabled,
  isError,
  refetchAuthentication,
}: Props) => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const [placeholder, setPlaceholder] = useState('');
  const [expiredTime, setExpiredTime] = useState<number>();

  const handleFocus = () => {
    setPlaceholder(PLACEHOLDER);
  };

  const handleBlur = () => {
    setPlaceholder('');
  };

  const handleSubmitCode = () => {
    submitCode();
  };

  useEffect(() => {
    setExpiredTime(Date.now() / 1000 + EXPIRE_PERIOD);
  }, []);

  useEffect(() => {
    if (String(value).length === 5) {
      handleSubmitCode();
    }
  }, [value]);

  const handleResend = () => {
    setExpiredTime(Date.now() / 1000 + EXPIRE_PERIOD);
    onChange('');
    refetchAuthentication(true);
  };

  const colorIcon = disabled
    ? theme.colors.text.tertiary
    : theme.colors.icon.inverse;

  return (
    <InputCodeWrapper>
      <InputCodeRow>
        <Input
          noMargin
          label={t('SMART:Submit.labels.codeFromSMS', {
            defaultValue: i18nDefaultValues.Submit.labels.codeFromSMS,
          })}
          value={value}
          onChange={onChange}
          maxLength={OTP_MAX_LENGTH}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          width={304}
          type="numeric"
          inputMode="numeric"
          error={isError}
          disabled={disabled}
          hintObject={{
            message: isError
              ? t('SMART:Submit.errors.incorrectCodeFromSms', {
                  defaultValue:
                    i18nDefaultValues.Submit.errors.incorrectCodeFromSms,
                })
              : '',
          }}
        />
        <StyledButton
          variant="square"
          onClick={handleSubmitCode}
          isLoading={disabled}
          error={isError}
        >
          <Suspense>
            <ArrowRight width={30} color={colorIcon} />
          </Suspense>
        </StyledButton>
      </InputCodeRow>
      {expiredTime && (
        <Resend
          isExpiryTimestampText
          expiryTimestamp={expiredTime}
          onResend={handleResend}
        />
      )}
      <StyledChangePhoneButton variant="text" onClick={changePhone}>
        {t('SMART:Submit.labels.changePhone', {
          defaultValue: i18nDefaultValues.Submit.labels.changePhone,
        })}
      </StyledChangePhoneButton>
    </InputCodeWrapper>
  );
};
