import { array, object, string } from 'yup';

const address = object({
  addressType: string().required(),
  address: string().required(),
  addressCode: string(),
});

const passport = object({
  cardType: string().required(),
  cardSeries: string().required(),
  cardNumber: string().required(),
  cardIssueDate: string().required(),
  cardIssuerCode: string().required(),
  cardIssuerName: string().required(),
  addresses: array(address),
});

const clientChange = object({
  birthDate: string(),
  email: string(),
  lastName: string(),
  firstName: string(),
  middleName: string(),
  passport,
});

export const profile = object({
  clientChange,
});
