import { StepsList } from '@pulse-web-ui/stepper';
import { type FC, memo } from 'react';

import { StyledContainer } from './smart-header.styles';
import type { HeaderProps } from './smart-header.types';
import { SmartHeaderItem } from './smart-header.item';
import { BackButton } from './components/back-button';

const SmartHeader: FC<HeaderProps> = memo(
  ({
    activeStep,
    handleStepChange,
    handleOnClickBack,
    isMobileApp,
    isShowBackButton,
    config,
  }) => (
    <>
      <StyledContainer isMobileApp={isMobileApp}>
        <StepsList value={activeStep - 1} onChange={handleStepChange}>
          {config.map((item, value) => (
            <SmartHeaderItem item={item} value={value} key={item.name} />
          ))}
        </StepsList>
      </StyledContainer>
      {isShowBackButton && <BackButton handleOnClickBack={handleOnClickBack} />}
    </>
  )
);

SmartHeader.displayName = 'SmartHeader';
export default SmartHeader;
