import { type FC } from 'react';
import type { FormBuilderStructure } from '@shared/types';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';

type Props = {
  components: FormBuilderStructure;
};

export const FormBuilderHeader: FC<Props> = observer(({ components }) => {
  const {
    MainStore: {
      applicationStore: { isHeaderShowing },
    },
  } = useStores();

  if (!isHeaderShowing) return;

  return components.header.map(
    (
      { component: Component, props: { name, ...additionalVariables } },
      index
    ) => (
      <Component
        key={`${name}-${index}`}
        additionalVariables={additionalVariables}
      />
    )
  );
});

FormBuilderHeader.displayName = 'FormBuilderHeader';
