import { styled, css } from '@pulse-web-ui/theme';

export const StyledContainer = styled.div<{ isMobileApp: boolean }>`
  margin-bottom: 8px;
  ${({ isMobileApp }) =>
    isMobileApp &&
    css`
      margin-top: 16px;
    `}
`;
