export const i18nDefaultValues = {
  InsuranceParameters: {
    title: 'Параметры страховки',
    labels: {
      repair: 'Ремонт от страховщика',
      franchise: 'Франшиза виновника',
    },
    hints: {
      specifyRegion:
        'Укажите регион преимущественного использования ТС. Страховой полис будет действителен на территории всей страны',
      reducesCostPolicy:
        'Снижает стоимость полиса до 65%, так как при ДТП по вашей вине вы оплачиваете часть ремонта в пределах суммы франшизы',
    },
    placeholders: {
      region: 'Регион использования ТС',
      insurancePeriod: 'Срок страхования',
      contractStartDate: 'Дата начала действия договора',
    },
    texts: {
      franchise:
        'Франшиза - часть ущерба, которую страховщик не возмещает.<br><br> Это уменьшает компенсацию, но значительно сокращает стоимость полиса. Если в ДТП есть установленный виновник и это не вы, то франшиза не применяется и выплата будет в полном размере.',
    },
    buttons: {
      accessibly: 'Понятно',
    },
    validations: {
      required: 'Обязательно для заполнения',
      regionRequired: 'Укажите регион использования ТС',
      startDateRequired: 'Укажите дату начала действия договора',
    },
  },
};
