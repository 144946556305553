import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';
import type { VehicleRepairTypeResponse } from './types';

export const useHandleVehicleRepairType = (code?: string) => {
  const enabled = Boolean(code);

  return useQuery(
    ['get-vehicle-repair-type'],
    fetchData<VehicleRepairTypeResponse>({
      url: Endpoints.GET_VEHICLE_REPAIR_TYPE,
      method: 'POST',
      data: {
        baseParameters: {
          productVersion: code,
        },
      },
    }),
    { enabled }
  );
};
