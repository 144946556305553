export const PASSPORT_MASK = [
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const PASSPORT_CODE_MASK = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
