import { TIME_ZONE_MOSCOW } from '@shared/constants';
import { utcToZonedTime } from 'date-fns-tz';

export const convertDateByTimeZone = (
  date: Date | null,
  timeZone?: string
): Date | null => {
  return date instanceof Date
    ? convertDateByTimeZoneStrict(date, timeZone)
    : date;
};

export const convertDateByTimeZoneStrict = (
  date: Date,
  timeZone?: string
): Date => {
  return utcToZonedTime(date, timeZone || TIME_ZONE_MOSCOW);
};
