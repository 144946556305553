import type { AgeRange, AgeRatio } from '@shared/types';

export const getAgeRange = (ageRatios: AgeRatio[]): AgeRange => {
  const agesMin = ageRatios.map((age) => age.ageMin);
  const agesMax = ageRatios.map((age) => age.ageMax);

  const min = Math.min(...agesMin);
  const max = Math.max(...agesMax);

  return {
    min,
    max,
  };
};
