import { DATE_FORMAT, PASSPORT_REG_EXP } from '@shared/constants';
import { format } from 'date-fns';

import type { VehicleOwner } from '../types';
import type { CarOwnerValues } from '@smart-components/car-owner';

import { parsingDocument } from './parsing-document';

export const getVehicleOwner = (carOwner?: CarOwnerValues): VehicleOwner => {
  const passport = carOwner?.passport || '';
  const [series, number] = parsingDocument(passport, PASSPORT_REG_EXP);

  return {
    lastName: carOwner?.surname || '',
    firstName: carOwner?.name || '',
    middleName: carOwner?.middlename || '',
    birthDate: format(carOwner?.birthday || new Date(), DATE_FORMAT),
    passport: {
      cardSeries: series.replace(' ', ''),
      cardNumber: number.trim(),
      cardIssueDate: format(carOwner?.passportDate || new Date(), DATE_FORMAT),
      cardIssuerCode: carOwner?.passportCode || '',
      cardIssuerName: carOwner?.passportByWho || '',
    },
    address: {
      address: carOwner?.address.value ?? '',
      addressCode: carOwner?.address.fias_id ?? '',
    },
  };
};
