import { useEffect } from 'react';
import { useStores } from '@shared/hooks';
import { useSearchParams } from 'react-router-dom';
import { QueryParams } from '../types';

export const useHandleQueries = () => {
  const utmPayload: Record<string, any> = {};
  const {
    MainStore: {
      initProductStore: { setRegUtm },
      productStore: { setFormState },
    },
  } = useStores();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Чтение параметров для формы из QUERY
    const queryComponents = searchParams.get(QueryParams.COMPONENTS);

    //TODO: необходимо при реальных прогонах доработать функционал. Результат чтение queryComponents сохранять отдельно в инит-стор
    // а гидратацию формы выполнять после инициализации в form-builder. Это же место будет использовано для гидратации данных из черновиков

    if (queryComponents) {
      const parsedComponents: any = JSON.parse(queryComponents);

      // после парсинга, передаем предзаполненные значения в стор
      // этот же механизм использовать для черновиков
      setFormState({
        WhoAndHow: parsedComponents.whoAndHow,
      });
    }

    if (searchParams.get('utm_source')) {
      utmPayload.utm_source = searchParams.get('utm_source');
    }

    if (searchParams.get('utm_campaign')) {
      utmPayload.utm_campaign = searchParams.get('utm_campaign');
    }

    if (searchParams.get('utm_content')) {
      utmPayload.utm_content = searchParams.get('utm_content');
    }

    if (searchParams.get('utm_medium')) {
      utmPayload.utm_medium = searchParams.get('utm_medium');
    }

    if (searchParams.get('utm_media_source')) {
      utmPayload.utm_media_source = searchParams.get('utm_media_source');
    }
    if (searchParams.get('campaign')) {
      utmPayload.campaign = searchParams.get('campaign');
    }
    if (searchParams.get('media_source')) {
      utmPayload.media_source = searchParams.get('media_source');
    }
    if (searchParams.get('wm_id')) {
      utmPayload.wm_id = searchParams.get('wm_id');
    }

    setRegUtm(utmPayload);
  }, []);
};
