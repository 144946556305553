const numberFormat = new Intl.NumberFormat('ru-RU');

export const formattingNumber = (number: number) => numberFormat.format(number);

export const currencyRuLocaleWithoutFraction = (
  number: number,
  options = { maxFraction: 0, minFraction: 0 }
) =>
  new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: options.maxFraction,
    minimumFractionDigits: options.minFraction,
  }).format(number);

export const currencyFormat = (number: number) => {
  if (number > 1_000_000) {
    const truncNumber = Math.floor(number / 1_000_000);
    return `${formattingNumber(truncNumber)} млн`;
  }
  return formattingNumber(number);
};
