/* eslint-disable indent */
export const adriverEvent = (e, n) => {
  function o(e, n, o) {
    (n = n || '&'), (o = o || '=');
    var d = [];
    for (var r in e)
      e.hasOwnProperty(r) && d.push(r + o + encodeURIComponent(e[r]));
    return d.join(n);
  }
  function d(e, n) {
    var o = e.cookie.match('(^|;) ?' + n + '=([^;]*)(;|$)');
    return o ? decodeURIComponent(o[2]) : null;
  }
  var r, t, i, c, u;
  (r = e),
    (t = n),
    (i = document.domain),
    (c = { tail256: document.referrer || 'unknown' }),
    void 0 !==
      (u = (function (e) {
        var n = {};
        if (e) {
          var o = e.split('&');
          for (var d in o)
            if (o.hasOwnProperty(d)) {
              var r = o[d].split('=');
              void 0 !== r[0] &&
                void 0 !== r[1] &&
                (n[r[0]] = decodeURIComponent(r[1]));
            }
        }
        return n;
      })(window.location.search.substring(1))).adrclid && (r.fsid = u.adrclid),
    null !== d(document, 'adrcid') && (r.cid = d(document, 'adrcid')),
    t &&
      t.id &&
      null !== d(document, t.id) &&
      (r.suid = i + '_' + encodeURIComponent(d(document, t.id))),
    t && t.gid1
      ? (r.gid1 = t.gid1)
      : null !== d(document, '_ga') &&
        (r.gid1 = encodeURIComponent(d(document, '_ga'))),
    t && t.yid1
      ? (r.yid1 = t.yid1)
      : null !== d(document, '_ym_uid') &&
        (r.yid1 = encodeURIComponent(d(document, '_ym_uid'))),
    (r.loc = encodeURIComponent(window.location.href)),
    r.custom && (r.custom = o(r.custom, ';')),
    (function (e, n) {
      !(function (e) {
        if (
          ((e = e.split('![rnd]').join(~~(1e6 * Math.random()))),
          document.createElement && document.body)
        ) {
          var n = document.createElement('img');
          (n.style.position = 'absolute'),
            (n.style.display = 'none'),
            (n.style.width = n.style.height = '0px'),
            n.setAttribute('referrerpolicy', 'no-referrer-when-downgrade'),
            (n.src = e),
            document.body.appendChild(n);
        } else {
          var o = new Image();
          o.setAttribute('referrerpolicy', 'no-referrer-when-downgrade'),
            (o.src = e);
        }
      })(
        'https://ad.adriver.ru/cgi-bin/rle.cgi?' +
          e +
          '&rnd=![rnd]' +
          (n ? '&' + n : '')
      );
    })(o(r), o(c));
};
