import { addDays, differenceInDays, format } from 'date-fns';
import { array, boolean, number, object, string } from 'yup';
import {
  valideBirthdayByPolicyStartDate,
  getNumberOfDays,
  validatePeriod,
} from '@shared/utils';
import i18n from '@app/i18n/config';

import type { GetPricesRequest } from '../types';

const regFormattedDate = new RegExp(/\d{4}-\d{2}-\d{2}/);
const regCurrecny = new RegExp(/(USD|EUR)/);

const risk = object({
  code: string(),
  amount: number(),
}).required();

const insurance = object({
  birthDate: string().matches(regFormattedDate).required(),
});

const validEndDate = (dateEnd: string, context: any) => {
  const {
    parent: { dateBegin },
    options: { traveling, insurancePeriodShortMax },
    path,
    createError,
  } = context;
  const start = new Date(dateBegin);
  const end = new Date(dateEnd);

  if (start.getTime() > end.getTime()) {
    return createError({
      path,
      message: i18n.t('COMMON:errors.startDateLater'),
    });
  }

  if (traveling) {
    const maxDate = getNumberOfDays(insurancePeriodShortMax) || 0;
    const dayDifference = differenceInDays(end, start);

    if (dayDifference >= maxDate) {
      const targetEndDate = addDays(start, maxDate);

      return createError({
        path,
        message: i18n.t('COMMON:errors.travelEndDate', {
          replace: {
            date: format(targetEndDate, 'dd.MM.yyyy'),
          },
        }),
      });
    }
  }

  return true;
};

const validatStartDateForTraveling = (startDate: string, context: any) => {
  const {
    options: { traveling, effectiveSinceFranchise },
    createError,
    path,
  } = context;
  if (traveling) {
    const countDays = getNumberOfDays(effectiveSinceFranchise) || 0;
    const start = new Date(startDate);

    const minAvailableDate = new Date();
    minAvailableDate.setDate(minAvailableDate.getDate() + countDays);
    minAvailableDate.setHours(0, 0, 0);

    if (start.getTime() <= minAvailableDate.getTime()) {
      return createError(path, i18n.t('COMMON:errors.invalidDate'));
    }

    return true;
  }

  return true;
};

const validEndDateMorePeriodDays = (endDate: string, context: any) => {
  const {
    options: { insurancePeriodOneTimeMax, insurancePeriodOneTimeMin },
    parent: { dateBegin },
    createError,
    path,
  } = context;

  const isValidPeriod = validatePeriod({
    min: insurancePeriodOneTimeMin,
    max: insurancePeriodOneTimeMax,
    start: dateBegin,
    end: endDate,
  });

  if (!isValidPeriod) {
    return createError({ path, message: i18n.t('COMMON:errors.invalidDate') });
  }

  return true;
};

const validateForAcceptableAge = (
  insuranceObjects: { birthDate: string }[],
  context: any
) => {
  const {
    parent: { dateBegin },
    options: { ageRange },
    createError,
    path,
  } = context;
  const isValid = insuranceObjects.every((insurance) => {
    const birthday = new Date(insurance.birthDate);
    const policyStartDate = new Date(dateBegin);

    const isValid = valideBirthdayByPolicyStartDate({
      birthday,
      policyStartDate,
      ageRange,
    });

    return isValid;
  });

  if (isValid) {
    return true;
  }

  return createError({
    path,
    message: i18n.t('COMMON:errors.ageNotMatch'),
  });
};

export const getPricesRequestSchema = object<GetPricesRequest>({
  productVersion: string().required(),
  dateBegin: string()
    .matches(regFormattedDate, i18n.t('COMMON:errors.invalidDateFormat'))
    .required()
    .test('start-date-for-traveling', validatStartDateForTraveling),
  dateEnd: string()
    .matches(regFormattedDate, i18n.t('COMMON:errors.invalidDateFormat'))
    .required()
    .test('start-date-more-end-date', validEndDate)
    .when('annualPolicy', {
      is: false,
      then: (schema) =>
        schema.test('end-date-more-period-days', validEndDateMorePeriodDays),
    }),
  annualPolicy: boolean().required(),
  numberOfDays: number().when('annualPolicy', {
    is: true,
    then: (schema) => schema.required(),
  }),
  currency: string()
    .matches(regCurrecny, i18n.t('COMMON:errors.invalidCurrencyType'))
    .required(),
  risks: array(risk).required(),
  territories: array(string()).required(),
  options: array(string()).notRequired(),
  activities: array(string()).notRequired(),
  insuranceObjects: array(insurance)
    .required()
    .test('acceptable-age', validateForAcceptableAge),
  promoCode: string().notRequired(),
}).required();
