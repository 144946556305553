/* eslint-disable indent */
import commonValues from '@app/i18n/ru/common.dic.json';

interface ObjectWithPrefix {
  [key: string]: ObjectWithPrefix | string;
}

interface TransformedObjects {
  ru: {
    COMMON: ObjectWithPrefix;
    SMART: ObjectWithPrefix;
  };
}

interface I18nDefaultValues {
  [key: string]: any;
}

// Получить данные i18n для всех смарт компонентов
const importI18nConstants = (): I18nDefaultValues => {
  // Указать абсолютный путь к папке smart-components
  const smartComponentsAbsolutePath = '';
  const i18nContext = (require as any).context(
    smartComponentsAbsolutePath,
    true,
    /\/i18n\/.*\.ts$/
  );
  const i18nFiles = i18nContext.keys();

  const i18nConstants: I18nDefaultValues = i18nFiles.reduce(
    (acc: I18nDefaultValues, file: string) => {
      const module = i18nContext(file);
      const { i18nDefaultValues }: { i18nDefaultValues: I18nDefaultValues } =
        module;
      return Object.assign(acc, i18nDefaultValues);
    },
    {}
  );

  return i18nConstants;
};

// Получить объект с полной структурой переводов для QA с префиксом "X-"
// На вход принимает массив с объектами переводов
export const transformObjects = (arr: object[]): TransformedObjects => {
  const result: TransformedObjects = {
    ru: {
      COMMON: {},
      SMART: {},
    },
  };

  arr.forEach((obj, index) => {
    const prefix = index === 0 ? 'COMMON' : 'SMART';
    const transformedObj = addPrefixToValues(obj as ObjectWithPrefix, 'X-');
    result.ru[prefix] = { ...result.ru[prefix], ...transformedObj };
  });

  return result;
};

const addPrefixToValues = (
  obj: ObjectWithPrefix,
  prefix: string
): ObjectWithPrefix => {
  const transformedObj: ObjectWithPrefix = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        transformedObj[key] = addPrefixToValues(
          obj[key] as ObjectWithPrefix,
          prefix
        );
      } else {
        transformedObj[key] = prefix + obj[key];
      }
    }
  }

  return transformedObj;
};

// Скачать json файл с переводами i18n для тестирования
const downloadJsonFile = (data: any, filename: string): void => {
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], { type: 'application/json' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();

  URL.revokeObjectURL(url);
};

// При вызове загружает на ПК файл с переводами i18n, с префиксом 'X-' для всех текстов
export const getI18nTestValues = () => {
  const i18nConstants: I18nDefaultValues = importI18nConstants();
  const smartComponentsI18nValues = Object.entries(i18nConstants).map(
    ([key, value]) => ({ [key]: value })
  );
  const result: TransformedObjects = transformObjects([
    commonValues,
    ...smartComponentsI18nValues,
  ]);
  const fileName = 'i18n.json';

  downloadJsonFile(result, fileName);
};
