import type { InsuranceParametersValues } from '@smart-components/index';
import type { ExtraParam } from '@shared/types';

const defaultValue: InsuranceParametersValues = {
  region: '',
  repair: '',
  period: {
    startDate: new Date(),
    period: '',
  },
  franchise: '',
  isValid: false,
};

/* eslint-disable indent */
export const getDefaultValues = (
  defaultProductValues: ExtraParam[] = [],
  minDate: Date,
  region?: string
) => {
  const defaultValues = defaultProductValues.reduce<InsuranceParametersValues>(
    (acc, item) => {
      switch (item.code) {
        case 'defaultVehicleRepairType':
          acc.repair = item.value;
          break;

        case 'defaultRegion':
          acc.region = region ?? item.value;
          break;

        case 'defaultInsuranceProductPeriod':
          acc.period.startDate = minDate;
          acc.period.period = item.value;
          break;
      }

      return acc;
    },
    defaultValue
  );

  return defaultValues;
};
