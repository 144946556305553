/* eslint-disable indent */

export const salesNinja = (id, reachGoal = 'reachGoal') => {
  !(function (n, e, t, a, r, s) {
    function i(n, a) {
      var r = e.createElement(t),
        s = e.getElementsByTagName(t)[0];
      (r.async = 1),
        (r.src = n),
        (r.onerror = a),
        s.parentNode.insertBefore(r, s);
    }
    (n.SalesNinja = ['init', 'start', 'onPersonalization', 'reachGoal'].reduce(
      function (e, t) {
        return (
          (e[t] = function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t), n[a].apply(0, e);
          }),
          e
        );
      },
      { k: a, ready: !1 }
    )),
      (n[a] = function () {
        (n[a].c = n[a].c || []).push(arguments);
      }),
      i(r, function () {
        i(s);
      });
  })(
    window,
    document,
    'script',
    'ninja',
    'https://cdn.sales-ninja.me/userBundle.js',
    'https://bundle.sales-ninja.me/userBundle.js'
  );
  ninja('init', '8b971ee5-ac4a-43b5-b1f7-2b25f437b834');
  ninja('start');

  ninja(reachGoal, id);
};
