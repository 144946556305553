import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { WhoIsDriver } from '@entities/import-smart-components/who-is-driver';
import { useStores } from '@shared/hooks';
import {
  DEFAULT_DRIVER,
  WhoIsDriverAnalyticEvent,
} from '@smart-components/who-is-driver';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import type { AnalyticEventsMap } from '@shared/types';
import type {
  SmartComponentAdapterProps,
  WhoIsDriverOptions,
  WhoIsDriverValues,
} from '@smart-components/index';
import type { Driver } from '@smart-components/who-is-driver/types';
import type { Ref } from 'react';

import { MIN_AGE_OF_DRIVER, MIN_NUMBER_OF_DRIVERS } from './constants';

const analyticEventsMap: AnalyticEventsMap<WhoIsDriverAnalyticEvent> = {
  [WhoIsDriverAnalyticEvent.ON_DRIVER_SELECTED]: {
    name: analyticEvents.driverSelected,
  },
  [WhoIsDriverAnalyticEvent.ON_DRIVER_ADDED]: {
    name: analyticEvents.driverAdded,
  },
};

export const WhoIsDriverAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<WhoIsDriverValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          initProductStore: {
            initState: { extraParams },
          },
          productStore: {
            formState: { PolicyholderForm, WhoIsPolicyholder },
          },
        },
      } = useStores();

      const hasAuthorized = WhoIsPolicyholder?.hasAuthorized;

      const { name, surname, middlename, birthday } = PolicyholderForm ?? {};

      const options: WhoIsDriverOptions = useMemo(() => {
        const minAgeOfDriver = Number(
          extraParams?.find(
            (extraParam) => extraParam.code === MIN_AGE_OF_DRIVER
          )?.value
        );
        const maxDriversNumber = Number(
          extraParams?.find(
            (extraParam) => extraParam.code === MIN_NUMBER_OF_DRIVERS
          )?.value
        );

        const me: Driver = {
          ...DEFAULT_DRIVER,
          name: name || '',
          surname: surname || '',
          middlename: middlename || '',
          birthday: birthday || null,
          id: uuid(),
        };

        return {
          minAgeOfDriver,
          maxDriversNumber,
          me,
        };
      }, [extraParams, name, surname, middlename, birthday]);

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<WhoIsDriverAnalyticEvent>(
          analyticEventsMap
        );

      if (!hasAuthorized || !value) {
        return null;
      }

      return (
        <Suspense>
          <WhoIsDriver
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            fieldState={fieldState}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

WhoIsDriverAdapter.displayName = 'WhoIsDriverAdapter';
