/* eslint-disable indent */
export const getNumberOfDays = (duration: string) => {
  const matches = duration.match(/^P(\d+)([YMD])$/);

  if (matches) {
    const value = parseInt(matches[1]);
    const unit = matches[2];

    switch (unit) {
      case 'D':
        return value;

      case 'M':
        return value * 30;

      case 'Y':
        return value * 365;

      default:
        return null;
    }
  }

  return null;
};
