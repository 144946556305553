import { useCallback, useEffect, useRef, useState } from 'react';

interface Settings {
  expiryTimestamp: number;
  isPaused?: boolean;
}

const getSecondsFromExpiry = (expiry: number) => {
  const now = new Date().getTime() / 1000;
  const secondsDistance = expiry - now;
  return Math.max(0, Math.floor(secondsDistance));
};

const getTimeFromSeconds = (totalSeconds: number) => {
  const days = Math.floor(totalSeconds / (60 * 60 * 24));
  const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return {
    seconds,
    minutes,
    hours,
    days,
  };
};

export const useTimer = ({
  expiryTimestamp: expire,
  isPaused = false,
}: Settings) => {
  const [expiryTimestamp, setExpiryTimestamp] = useState(expire);
  const [seconds, setSeconds] = useState(getSecondsFromExpiry(expiryTimestamp));
  const [isRunning, setIsRunning] = useState(true);
  const intervalRef = useRef<NodeJS.Timeout>();

  const clearIntervalRef = useCallback(() => {
    if (intervalRef.current) {
      setIsRunning(false);
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  }, []);

  const start = useCallback(() => {
    if (!intervalRef.current) {
      setIsRunning(true);
      intervalRef.current = setInterval(() => {
        const secondsValue = getSecondsFromExpiry(expiryTimestamp);
        if (secondsValue <= 0) {
          clearIntervalRef();
        }
        setSeconds(secondsValue);
      }, 1000);
    }
  }, [expiryTimestamp, clearIntervalRef]);

  const pause = useCallback(() => {
    clearIntervalRef();
  }, [clearIntervalRef]);

  const resume = useCallback(() => {
    if (!intervalRef.current) {
      setIsRunning(true);
      intervalRef.current = setInterval(
        () =>
          setSeconds((prevSeconds) => {
            const secondsValue = prevSeconds - 1;
            if (secondsValue <= 0) {
              clearIntervalRef();
            }
            return secondsValue;
          }),
        1000
      );
    }
  }, [clearIntervalRef]);

  const restart = useCallback(
    (newExpiryTimestamp: number) => {
      clearIntervalRef();
      setExpiryTimestamp(newExpiryTimestamp);
      setSeconds(getSecondsFromExpiry(newExpiryTimestamp));
      start();
    },
    [clearIntervalRef, start]
  );

  useEffect(() => {
    setSeconds(getSecondsFromExpiry(expiryTimestamp));
    start();

    return clearIntervalRef;
  }, [expiryTimestamp, clearIntervalRef, start]);

  useEffect(() => {
    if (isPaused) {
      pause();
    }
  }, [isPaused, pause]);

  return {
    timer: { ...getTimeFromSeconds(seconds) },
    seconds,
    isRunning,
    start,
    pause,
    resume,
    restart,
  };
};
