import { HelperText } from '@pulse-web-ui/helper-text';
import { Select, type Option } from '@pulse-web-ui/select';
import { useTranslation } from 'react-i18next';

import type { FieldError } from 'react-hook-form';

import { HeaderWrapper, Row } from './franchise.styles';
import { InsuranceParametersAnalyticEvent } from '../../constants';
import { i18nDefaultValues } from '../../i18n';
import { Header, Span, Wrapper } from '../common';
import { FranchiseInfo } from '../franchise-info';

interface FranchiseProps {
  value: string;
  onChange: VoidFn<string>;
  franchises: Option[];
  error?: FieldError;
  onAnalyticEventSend?: VoidFn<InsuranceParametersAnalyticEvent>;
}

export const Franchise = ({
  value,
  onChange,
  franchises,
  error,
  onAnalyticEventSend,
}: FranchiseProps) => {
  const { t } = useTranslation();

  const handleSelect = (franchise: string) => {
    onChange(franchise);
    onAnalyticEventSend?.(
      InsuranceParametersAnalyticEvent.ON_FRANCHISE_SELECTED
    );
  };

  const errorMessage = error?.message;

  return (
    <Wrapper>
      <Row>
        <HeaderWrapper>
          <Header>
            {t('SMART:InsuranceParameters.labels.franchise', {
              defaultValue:
                i18nDefaultValues.InsuranceParameters.labels.franchise,
            })}
          </Header>
          <FranchiseInfo />
        </HeaderWrapper>
      </Row>
      <Span>
        {t('SMART:InsuranceParameters.hints.reducesCostPolicy', {
          defaultValue:
            i18nDefaultValues.InsuranceParameters.hints.reducesCostPolicy,
        })}
      </Span>
      <HelperText
        noMargin
        status={errorMessage ? 'error' : 'default'}
        message={errorMessage}
      >
        <Select value={value} options={franchises} onChange={handleSelect} />
      </HelperText>
    </Wrapper>
  );
};
