export const phoneDisplayValueCasting = (value: string) => {
  // Убираем все символы, кроме цифр
  let cleanValue = value.replace(/\D/g, '');

  // Если номер начинается с 8, заменяем на 7
  if (cleanValue.length === 11 && cleanValue[0] === '8') {
    cleanValue = '7' + cleanValue.slice(1);
  }

  // Если длина 10 цифр (без кода страны), добавляем код страны
  if (cleanValue.length === 10) {
    cleanValue = '7' + cleanValue;
  }

  // Форматируем строку в +7 (XXX) XXX-XX-XX
  return cleanValue.replace(
    /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
    '+$1 ($2) $3-$4-$5'
  );
};
