import { useEffect } from 'react';
import { useStores } from '@shared/hooks';
import { useSearchParams } from 'react-router-dom';

export const useHandleMobileParams = () => {
  const {
    MainStore: {
      applicationStore: { setDeviceType, setPhoneFromMobileApp },
    },
  } = useStores();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const deviceType = searchParams.get('platform');
    const phone = searchParams.get('phone');

    setPhoneFromMobileApp(phone as string);
    setDeviceType(deviceType as string);
  }, []);
};
