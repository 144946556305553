import { media, styled } from '@pulse-web-ui/theme';
import { Wrapper } from '../common';

export const WrapperStyled = styled(Wrapper)`
  ${media.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
`;
