import { boolean, date, object, string } from 'yup';
import { i18nDefaultValues } from './i18n';
import i18n from '@app/i18n/config';

const required = i18n.t(
  'SMART:InsuranceParameters.validations.required',
  i18nDefaultValues.InsuranceParameters.validations.required
);

const regionRequired = i18n.t(
  'SMART:InsuranceParameters.validations.regionRequired',
  i18nDefaultValues.InsuranceParameters.validations.regionRequired
);

const startDateRequired = i18n.t(
  'SMART:InsuranceParameters.validations.startDateRequired',
  i18nDefaultValues.InsuranceParameters.validations.startDateRequired
);

export const insuranceParameters = object().shape({
  region: string().required(regionRequired),
  repair: string().required(required),
  period: object().shape({
    startDate: date().required(startDateRequired),
    period: string().required(required),
  }),
  franchise: string().required(required),
  isValid: boolean(),
});
