import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';
import { VehicleRegionsResponse } from './types';

export const useHandleVehicleRegion = (code?: string) => {
  const enabled = Boolean(code);

  return useQuery(
    ['get-vehicle-region'],
    fetchData<VehicleRegionsResponse>({
      url: Endpoints.GET_VEHICLE_REGION,
      method: 'POST',
      data: {
        baseParameters: {
          productVersion: code,
        },
      },
    }),
    { enabled }
  );
};
