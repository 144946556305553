import { media, styled } from '@pulse-web-ui/theme';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  ${media.desktop} {
    row-gap: 24px;
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  ${media.desktop} {
    column-gap: 12px;
    flex-direction: row;
  }
`;

export const InputWrapper = styled.div`
  ${media.desktop} {
    flex: 1;
  }
`;
