import type { Country, CurrencyType, Territory } from '@shared/types';

export const getCurrency = (store: any): CurrencyType => {
  const { mainStore, formState } = store;

  if (
    mainStore.initProductStore.initState?.territories &&
    formState?.WhereAndHowLong?.countries
  ) {
    const allCountries: Territory[] =
      mainStore.initProductStore.initState.territories;
    const selectedCountries: Country[] = formState?.WhereAndHowLong?.countries;

    const currenties = selectedCountries.reduce(
      (currenties: string[], selectedCountry: Country) => {
        const foundedCountry = allCountries.find(
          (country: Territory) => country.code === selectedCountry.value
        );

        if (foundedCountry) {
          currenties.push(foundedCountry.currency);
        }

        return currenties;
      },
      []
    );

    const hasEUR = currenties.some((currency: string) => currency === 'EUR');

    return currenties.length > 0 && hasEUR ? 'EUR' : 'USD';
  }

  return 'USD';
};
