import { type FC } from 'react';
import type { FormBuilderStructure } from '@shared/types';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';

type Props = {
  components: FormBuilderStructure;
};

export const FormBuilderFooter: FC<Props> = observer(({ components }) => {
  const {
    MainStore: {
      applicationStore: { isFooterShowing },
    },
  } = useStores();

  if (!isFooterShowing) return;

  return components.footer.map(
    (
      { component: Component, props: { name, ...additionalVariables } },
      index
    ) => (
      <Component
        key={`${name}-${index}`}
        additionalVariables={additionalVariables}
      />
    )
  );
});

FormBuilderFooter.displayName = 'FormBuilderFooter';
