import { getFormattedDate } from '@shared/utils';
import { getDateOfBirth } from './get-date-of-birth';
import { Traveler } from '@shared/types';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

const birthDate = new Date(today);
birthDate.setFullYear(tomorrow.getFullYear() - 30);

const BIRTH_DATE = getFormattedDate(birthDate);

const BIRTH_DATE_DEFAULT = {
  birthDate: BIRTH_DATE,
};
const INSURANCE_OBJECTS_DEFAULT = [BIRTH_DATE_DEFAULT];

const getTravelerBirthDay =
  (ageSelect: any[]) => (traveler: Traveler, index: number) => {
    // дата рождения есть у путешественника
    if (traveler.birthday)
      return { birthDate: getFormattedDate(traveler.birthday || birthDate) };

    // защита от кривых данных (нет др, нет возрастов)
    if (ageSelect.length === 0) return { birthDate };
    const age = ageSelect[index]?.value;

    // берем данные из возраста
    return {
      birthDate: age
        ? getFormattedDate(getDateOfBirth(Number(age)))
        : birthDate,
    };
  };

export const getInsuranceObject = (formState: any) => {
  const travelers = formState?.WhoAndHowPaperwork?.travelers || [];
  const ageSelect = formState?.WhoAndHow?.ageSelect || [];

  // всегда после первого заполнения
  if (travelers.length > 0)
    return travelers.map(getTravelerBirthDay(ageSelect));

  // первое заполнение возрастов
  if (ageSelect.length > 0)
    return ageSelect.map((age: { value: string }) => ({
      birthDate: getFormattedDate(getDateOfBirth(Number(age.value))),
    }));

  return INSURANCE_OBJECTS_DEFAULT;
};
