import { Datepicker, type ReactDatePicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Select } from '@pulse-web-ui/select';
import { getFormattedDate } from '@shared/utils';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  InsuranceParametersValues,
  Period as PeriodType,
} from '../../types';
import type { FieldErrors } from 'react-hook-form';

import { WrapperStyled } from './period.styles';
import { InsuranceParametersAnalyticEvent } from '../../constants';
import { i18nDefaultValues } from '../../i18n';

interface Value {
  startDate: Date;
  period: string;
}

interface PeriodProps {
  value: Value;
  onChange: VoidFn<Value>;
  period: PeriodType;
  errors: FieldErrors<InsuranceParametersValues>;
  onAnalyticEventSend?: VoidFn<InsuranceParametersAnalyticEvent>;
}

export const Period = ({
  value,
  onChange,
  period: { maxDate, minDate, periods },
  errors,
  onAnalyticEventSend,
}: PeriodProps) => {
  const { t } = useTranslation();

  const datePickerRef = useRef<ReactDatePicker>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleSelect = useCallback(
    (period: string) => {
      onChange({ ...value, period });
    },
    [value]
  );

  const handleChange = useCallback(
    (date: Date) => {
      const prevValue = value?.startDate && getFormattedDate(value?.startDate);
      if (prevValue !== getFormattedDate(date)) {
        onChange({ ...value, startDate: date });
        onAnalyticEventSend?.(
          InsuranceParametersAnalyticEvent.ON_DATE_SELECTED
        );
      }
    },
    [value]
  );

  const disabled = periods.length <= 1;

  const errorMessageStartDate = errors?.period?.startDate?.message;
  const errorMessagePeriod = errors?.period?.period?.message;

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (!wrapperRef.current?.contains(event.target as Node)) {
      datePickerRef.current?.setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <WrapperStyled>
      <HelperText
        noMargin
        status={errorMessageStartDate ? 'error' : 'default'}
        message={errorMessageStartDate}
      >
        <div ref={wrapperRef}>
          <Datepicker
            ref={datePickerRef}
            selected={value?.startDate}
            onChange={handleChange}
            label={t(
              'SMART:InsuranceParameters.placeholders.contractStartDate',
              {
                defaultValue:
                  i18nDefaultValues.InsuranceParameters.placeholders
                    .contractStartDate,
              }
            )}
            enableTabLoop={false}
            maxDate={maxDate}
            minDate={minDate}
            error={Boolean(errorMessageStartDate)}
          />
        </div>
      </HelperText>
      <HelperText
        noMargin
        status={errorMessagePeriod ? 'error' : 'default'}
        message={errorMessagePeriod}
      >
        <Select
          value={value?.period}
          onChange={handleSelect}
          placeholder={t(
            'SMART:InsuranceParameters.placeholders.insurancePeriod',
            {
              defaultValue:
                i18nDefaultValues.InsuranceParameters.placeholders
                  .insurancePeriod,
            }
          )}
          options={periods}
          disabled={disabled}
          status={errorMessagePeriod ? 'error' : undefined}
        />
      </HelperText>
    </WrapperStyled>
  );
};
