import { ErrorCode } from '@shared/constants';
import { ResponseError } from '@shared/types';

const matchCode = (
  input: string | undefined,
  code: ErrorCode | ErrorCode[]
) => {
  if (!input) return false;

  return Array.isArray(code)
    ? code.includes(input as ErrorCode)
    : input === code;
};
export const hasError = (
  error: ResponseError | null | undefined,
  code: ErrorCode | ErrorCode[],
  status = 400
) =>
  error?.response?.status === status &&
  matchCode(error.response?.data?.code, code);
