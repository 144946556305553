import { yupResolver } from '@hookform/resolvers/yup';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';
import { forwardRef, memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { InsuranceParametersAnalyticEvent } from './constants';
import type {
  InsuranceParametersOptions,
  InsuranceParametersValues,
} from './types';
import type { SmartComponentProps } from '@smart-components/shared/types';
import type { Ref } from 'react';

import { Franchise, Period, Region, Repair } from './components';
import { i18nDefaultValues } from './i18n';
import { Content, WidgetContainerStyled } from './insurance-parameters.styles';
import { insuranceParameters } from './schemas';

export const InsuranceParameters = memo(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        options,
        onAnalyticEventSend,
      }: SmartComponentProps<
        InsuranceParametersValues,
        InsuranceParametersOptions,
        InsuranceParametersAnalyticEvent
      >,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();
      const {
        trigger,
        control,
        watch,
        formState: { isValid },
        setValue,
      } = useForm<InsuranceParametersValues>({
        defaultValues: value,
        resolver: yupResolver(insuranceParameters),
        mode: 'onChange',
      });

      useEffect(() => {
        const subscription = watch((values) =>
          onChange(values as InsuranceParametersValues)
        );

        return () => subscription.unsubscribe();
      }, [watch]);

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        setValue('isValid', isValid);
      }, [isValid]);

      return (
        <WidgetContainerStyled ref={forwardRef}>
          <HeaderWithSubText
            title={t('SMART:InsuranceParameters.title', {
              defaultValue: i18nDefaultValues.InsuranceParameters.title,
            })}
          />
          <Content>
            <Controller
              name="region"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Region
                  value={value}
                  onChange={onChange}
                  options={options.regions}
                  error={error}
                  onAnalyticEventSend={onAnalyticEventSend}
                />
              )}
            />
            <Controller
              name="repair"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Repair
                  value={value}
                  onChange={onChange}
                  items={options.repairs}
                  error={error}
                />
              )}
            />
            <Controller
              name="period"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <Period
                  value={value}
                  onChange={onChange}
                  period={options.period}
                  errors={errors}
                  onAnalyticEventSend={onAnalyticEventSend}
                />
              )}
            />
            <Controller
              name="franchise"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Franchise
                  value={value}
                  onChange={onChange}
                  franchises={options.franchises}
                  error={error}
                  onAnalyticEventSend={onAnalyticEventSend}
                />
              )}
            />
          </Content>
        </WidgetContainerStyled>
      );
    }
  )
);
