import { ErrorCode, Endpoints } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { getInitOrderData } from '@shared/queries';
import { useEffect } from 'react';

import type { SubmitResponse } from '@entities/adapters/submit-adapter/types';
import type { FormBuilderUpdateHandler } from '@features/form-builder';
import type { ContractDataType, InitOrderResponse } from '@shared/types';

export const useInitOrder = (
  updateFormValue: FormBuilderUpdateHandler,
  resSubmitOrder: SubmitResponse | undefined
) => {
  const {
    MainStore: {
      applicationStore: { isPaymentStep, retryPayment, isExternalPayment },
      initProductStore: { setInitOrder, setInitOrderError },
      errorStore: { setErrorRetry },
      authStore: { profile, accessToken, setDisableForm },
    },
  } = useStores();

  const contractData: ContractDataType = getInitOrderData(
    resSubmitOrder,
    profile
  );

  const {
    isLoading,
    res: resInit,
    refetch: refetchInit,
    error: errorInit,
  } = useRequest<InitOrderResponse>(
    'initOrder',
    'post',
    Endpoints.INIT_ORDER,
    contractData,
    [contractData, accessToken],
    true,
    accessToken
  );

  useEffect(() => {
    if (!isLoading && resInit) {
      updateFormValue('B2P', {
        isExternalPayment,
        initOrder: resInit,
        showInitOrder: false, // активность оплаты
        isValid: true,
      });
      setErrorRetry(false);
      setInitOrder(resInit);
      if (
        !isLoading &&
        errorInit?.response?.data?.code === ErrorCode.BUSINESS_ERROR
      ) {
        setInitOrderError({ code: ErrorCode.BUSINESS_ERROR });
      }
    }
  }, [isLoading, resInit, errorInit]);

  useEffect(() => {
    if (isPaymentStep && resSubmitOrder && !retryPayment) {
      setErrorRetry(false);
      setDisableForm(true);
      refetchInit();
    }
  }, [isPaymentStep, resSubmitOrder]);

  return { refetchInit, resInit };
};
