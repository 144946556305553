/* eslint-disable indent */
import { ptsRegEx, stsRegEx, eptsRegEx } from '@shared/constants/reg-ex';
import { Document } from '@shared/constants/document-type';
import { parsingDocument } from './parsing-document';

export const getDataDocument = (document: string, documentType: Document) => {
  switch (documentType) {
    case Document.STS:
      return parsingDocument(document, stsRegEx);

    case Document.PTS:
      return parsingDocument(document, ptsRegEx);

    case Document.EPTS:
      return parsingDocument(document, eptsRegEx);

    default:
      return ['', ''];
  }
};
