import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStores } from '@shared/hooks';

export const AgentController = observer(() => {
  const {
    MainStore: {
      productStore: { setAgentLogin },
    },
  } = useStores();
  const [searchParams] = useSearchParams();
  const agentLogin = searchParams.get('agent_login');

  useEffect(() => {
    if (agentLogin) {
      setAgentLogin(agentLogin);
    }
  }, [agentLogin]);

  return null;
});
