/* eslint-disable indent */
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { InsuranceParameters } from '@entities/import-smart-components/insurance-parameters';
import { Spinner } from '@pulse-web-ui/spinner';
import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import { formattingPrice } from '@shared/utils';
import { InsuranceParametersAnalyticEvent } from '@smart-components/insurance-parameters';
import { addDays } from 'date-fns';
import { observer } from 'mobx-react-lite';
import {
  forwardRef,
  type Ref,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type {
  Franchise,
  InsuranceProductPeriod,
  VehicleRepairType,
  VehicleRegion,
} from './queries';
import type { AnalyticEventsMap } from '@shared/types';
import type {
  InsuranceParametersOptions,
  InsuranceParametersValues,
  SmartComponentAdapterProps,
} from '@smart-components/index';

import { DEFAULT_FRANCHISE_OPTION } from './constants';
import {
  useHandleVehicleRegion,
  useHandleInsuranceProductPeriod,
  useHandleVehicleRepairType,
  useHandleFranchise,
  useHandleRegion,
} from './queries';
import {
  getDefaultFranchiseValue,
  getDefaultValues,
  getMaxCountDays,
  getMinCountDays,
} from './utils';

const TODAY_DATE = new Date();

const analyticEventsMap: AnalyticEventsMap<InsuranceParametersAnalyticEvent> = {
  [InsuranceParametersAnalyticEvent.ON_REGION_SELECTED]: {
    name: analyticEvents.regionSelected,
  },
  [InsuranceParametersAnalyticEvent.ON_DATE_SELECTED]: {
    name: analyticEvents.dateSelected,
  },
  [InsuranceParametersAnalyticEvent.ON_FRANCHISE_SELECTED]: {
    name: analyticEvents.franchiseSelected,
  },
};

export const InsuranceParametersAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
      }: SmartComponentAdapterProps<InsuranceParametersValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          productStore: {
            formState: {
              Car,
              CarNumber,
              InsuranceParameters: InsuranceParametersStore,
            },
          },
          applicationStore: { updateFormValue },
          initProductStore: {
            initState: { extraParams, code },
          },
        },
      } = useStores();

      const [isLoading, setIsLoading] = useState(true);

      const carNumber = Car?.carNumber ?? CarNumber?.carNumber;

      const { data: dataVehicleRegion, isLoading: isLoadingVehicleRegion } =
        useHandleVehicleRegion(code);
      const { data: dataRepair, isLoading: isLoadingRepair } =
        useHandleVehicleRepairType(code);
      const { data: dataPeriod, isLoading: isLoadingPeriod } =
        useHandleInsuranceProductPeriod(code);
      const { data: dataFranchise, isLoading: isLoadingFranchise } =
        useHandleFranchise(code);
      const { data: dataRegion, isLoading: isLoadingRegion } = useHandleRegion({
        carNumber: carNumber,
        code,
      });

      const isLoadings =
        isLoadingVehicleRegion ||
        isLoadingRepair ||
        isLoadingPeriod ||
        isLoadingFranchise ||
        isLoadingRegion;

      useEffect(() => {
        setIsLoading(isLoadings);
      }, [isLoadings]);

      const minDate = useMemo(() => {
        const minCountDays = getMinCountDays(extraParams);
        return addDays(TODAY_DATE, minCountDays);
      }, [extraParams]);

      const franchiseDefaultValue = useMemo(() => {
        return getDefaultFranchiseValue(extraParams);
      }, [extraParams]);

      useEffect(() => {
        const defaultValues = getDefaultValues(
          extraParams,
          minDate,
          dataRegion?.region
        );

        updateFormValue('InsuranceParameters', {
          ...InsuranceParametersStore,
          ...defaultValues,
          franchise: franchiseDefaultValue,
        });
      }, [franchiseDefaultValue, extraParams, minDate, dataRegion?.region]);

      const regions = useMemo(() => {
        if (!dataVehicleRegion?.vehicleRegions) {
          return [];
        }

        return dataVehicleRegion?.vehicleRegions.map((item: VehicleRegion) => ({
          label: item.name,
          value: item.code,
        }));
      }, [dataVehicleRegion?.vehicleRegions]);

      const repairs = useMemo(() => {
        if (!dataRepair?.vehicleRepairTypes) {
          return [];
        }

        return dataRepair?.vehicleRepairTypes?.map(
          (item: VehicleRepairType) => ({
            label: item.name,
            value: item.code,
            id: item.code,
            describe: item.description,
          })
        );
      }, [dataRepair?.vehicleRepairTypes]);

      const periods = useMemo(() => {
        if (!dataPeriod?.insuranceProductPeriods) {
          return [];
        }

        return dataPeriod.insuranceProductPeriods.map(
          (item: InsuranceProductPeriod) => ({
            label: item.name,
            value: item.code,
          })
        );
      }, [dataPeriod?.insuranceProductPeriods]);

      const period = useMemo(() => {
        const maxCountDays = getMaxCountDays({
          hasKasko: Boolean(Car?.haveValidPolicy),
          defaultProductValues: extraParams,
        });

        return {
          maxDate: addDays(TODAY_DATE, maxCountDays),
          minDate: minDate,
          periods: periods,
        };
      }, [Car?.haveValidPolicy, extraParams, minDate, periods]);

      const franchises = useMemo(() => {
        if (!dataFranchise?.franchises) {
          return [DEFAULT_FRANCHISE_OPTION];
        }

        const franchises = dataFranchise.franchises.map((item: Franchise) => ({
          label: formattingPrice(Number(item.name)),
          value: item.code,
        }));

        return [DEFAULT_FRANCHISE_OPTION, ...franchises];
      }, [dataFranchise?.franchises]);

      const options: InsuranceParametersOptions = {
        regions,
        repairs,
        period,
        franchises,
      };

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<InsuranceParametersAnalyticEvent>(
          analyticEventsMap
        );

      return (
        <Suspense
          fallback={
            <FallbackSkeleton height={274}>
              <Spinner />
            </FallbackSkeleton>
          }
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <InsuranceParameters
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              ref={forwardRef}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          )}
        </Suspense>
      );
    }
  )
);

InsuranceParametersAdapter.displayName = 'InsuranceParametersAdapter';
