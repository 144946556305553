import { analyticEvents, useSendSingleAnalyticEvent } from '@app/web-analytic';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import type { formValue } from '../utils/get-default-values';
import type { TBusCallback } from '@shared/event-bus/event-bus';
import type { SmartComponent } from '@shared/types';

import { useDisabledSubmit, useScrollToElementWithError } from '../hooks';
import { FormBuilderEventBus, FormBuilderAction } from '../utils';
import { FormBuilderStepItem } from './form-builder-step-item';
import { useSynchronizeStore } from '../hooks/use-synchronize-store';

type Props = {
  config: SmartComponent<any>[];
  defaultValues: formValue;
};

type FormHandlerErrorParams = { fieldName: string; err: string };
type FormHandlerParams = { fieldName: string; value: any };
type FormHandlerObject = (params: FormHandlerParams) => void;
type FormHandler = (fieldName: string, value: any) => void;
type FormErrorHandler = (params: FormHandlerErrorParams) => void;

export const FormBuilderStep = observer(({ config, defaultValues }: Props) => {
  const {
    MainStore: {
      applicationStore: {
        currentStep,
        activeStep,
        wantNextStep,
        setWantNextStep,
        setCurrentStep,
        isResetFormState,
        setDisabledSubmit,
        setIsResetFormState,
      },
      productStore: { setFormState, formState: storeFormState },
      authStore: { setDisableForm, handleAuth },
      initProductStore: { setFormBuilderIsMounted },
    },
  } = useStores();

  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    formState: {
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
      errors,
      submitCount,
    },
  } = useForm({ values: defaultValues });

  const elementRefs = useScrollToElementWithError(submitCount, errors);

  useSynchronizeStore(watch, setFormState, clearErrors);

  useSendSingleAnalyticEvent(isDirty, analyticEvents.start);

  useEffect(() => {
    if (isResetFormState) {
      reset(storeFormState);
      setIsResetFormState(false);
    }
  }, [isResetFormState]);

  useEffect(() => {
    setCurrentStep(activeStep);
  }, [activeStep]);

  useEffect(() => {
    setFormBuilderIsMounted();
  }, []);

  const updateFormHandlerObject: FormHandlerObject = useCallback(
    ({ fieldName, value }) => {
      updateFormHandler(fieldName, value);
    },
    []
  );

  const updateFormHandler: FormHandler = useCallback((fieldName, value) => {
    getValues();
    setValue(fieldName, value);
  }, []);

  const updateFormErrorHandler: FormErrorHandler = useCallback(
    ({ fieldName, err }) => {
      setError(fieldName, { message: err });
      setDisableForm(false);
    },
    []
  );

  const handleAuthUser: TBusCallback<{ phone: string }> = useCallback(
    ({ phone }) => {
      handleAuth(phone);
    },
    [handleAuth, currentStep]
  );

  useEffect(() => {
    const unsubscribe = FormBuilderEventBus.subscribe(
      FormBuilderAction.SUBMIT_FORM,
      handleSubmit(() => {})
    );

    return unsubscribe;
  }, [handleSubmit]);

  useEffect(() => {
    const unsubscribe = FormBuilderEventBus.subscribe(
      FormBuilderAction.UPDATE_FORM,
      updateFormHandlerObject
    );

    return unsubscribe;
  }, [updateFormHandlerObject]);

  useEffect(() => {
    const unsubscribe = FormBuilderEventBus.subscribe<FormHandlerErrorParams>(
      FormBuilderAction.UPDATE_FORM_ERRORS,
      updateFormErrorHandler
    );

    return unsubscribe;
  }, [updateFormErrorHandler]);

  useEffect(() => {
    const unsubscribe = FormBuilderEventBus.subscribe<{ phone: string }>(
      FormBuilderAction.AUTH_USER,
      handleAuthUser
    );

    return unsubscribe;
  }, [handleAuthUser]);

  const disabledSubmit = useDisabledSubmit({
    errors,
    submitCount,
    wantNextStep,
  });

  useEffect(() => {
    setDisabledSubmit(disabledSubmit);
  }, [disabledSubmit]);

  useEffect(() => {
    if (isSubmitSuccessful && !isSubmitting) {
      setWantNextStep(true);
    }
  }, [isSubmitSuccessful, isSubmitting]);

  const setRefHandler = useCallback(
    (e: HTMLDivElement, name: string) => (elementRefs.current[name] = e),
    []
  );

  return config
    .filter((item) => item?.step === activeStep)
    .map((component) => (
      <FormBuilderStepItem
        setRef={setRefHandler}
        activeStep={activeStep}
        key={`${activeStep}${component.props.id}`}
        component={component}
        isSubmitting={isSubmitting}
        control={control}
      />
    ));
});

FormBuilderStep.displayName = 'FormBuilderStep';
