import i18n from '@app/i18n/config';
import { Document } from '@shared/constants';

import { i18nDefaultValues } from './i18n';

const STS = i18n.t(
  'SMART:VehicleDocumentAdapter.labels.STS',
  i18nDefaultValues.VehicleDocumentAdapter.labels.STS
);
const PTS = i18n.t(
  'SMART:VehicleDocumentAdapter.labels.PTS',
  i18nDefaultValues.VehicleDocumentAdapter.labels.PTS
);
const EPTS = i18n.t(
  'SMART:VehicleDocumentAdapter.labels.EPTS',
  i18nDefaultValues.VehicleDocumentAdapter.labels.EPTS
);

export const DOCUMENTS = [
  { value: Document.STS, label: STS },
  { value: Document.PTS, label: PTS },
  { value: Document.EPTS, label: EPTS },
];
