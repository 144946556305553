import i18n from '@app/i18n/config';
import { boolean, object, array, string } from 'yup';

export const schema = object({
  documentsList: array(),
  token: string(),
  acceptRequirements: boolean()
    .nonNullable()
    .test({
      name: 'acceptRequirements',
      message: i18n.t('COMMON:errors.acceptRequirements'),
      test: (value) => value === true,
    }),
  isValid: boolean(),
});
