import {
  PolicyIncludedAccordion,
  PolicyIncludedItem,
} from '@pulse-web-ui/policy-included-accordion';
import { i18nDefaultValues } from '../../i18n';
import { useTranslation } from 'react-i18next';
import i18n from '@app/i18n/config';
import { declineWord } from '@shared/utils';

const DECLINATION = {
  one: i18n.t('COMMON:declensions.risk-1'),
  few: i18n.t('COMMON:declensions.risk-2'),
  many: i18n.t('COMMON:declensions.risk-3'),
};

interface InsuranceRisksAccordionProps {
  risks: PolicyIncludedItem[];
}

export const InsuranceRisksAccordion = ({
  risks,
}: InsuranceRisksAccordionProps) => {
  const { t } = useTranslation();

  return (
    <PolicyIncludedAccordion
      header={t('SMART:CheckPolicyAdapter.insuranceRisks.header', {
        defaultValue:
          i18nDefaultValues.CheckPolicyAdapter.insuranceRisks.header,
      })}
      subHeader={declineWord(risks.length, DECLINATION)}
      productItems={risks}
    />
  );
};
