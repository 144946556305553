import { yupResolver } from '@hookform/resolvers/yup';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';
import { SelectorCard } from '@pulse-web-ui/selector-card';
import { StyledThemeProvider, baseTheme } from '@pulse-web-ui/theme';
import React, { memo, forwardRef, useEffect, useCallback } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { InsuranceRisksValues } from './types';
import type { RisksType } from '@shared/types';
import type { SmartComponentBaseProps } from '@smart-components/shared/types';
import type { Ref } from 'react';

import { InsuranceRisksAnalyticEvent } from './constants';
import { i18nDefaultValues } from './i18n';
import { WidgetContainerStyled } from './insurance-risks.styles';
import { schema } from './schema';

export const InsuranceRisks = memo(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        onAnalyticEventSend,
      }: SmartComponentBaseProps<
        InsuranceRisksValues,
        InsuranceRisksAnalyticEvent
      >,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();

      const {
        trigger,
        watch,
        control,
        setValue,
        formState: { isValid },
      } = useForm<InsuranceRisksValues>({
        defaultValues: value,
        resolver: yupResolver(schema),
        mode: 'all',
      });

      const { fields } = useFieldArray({
        control,
        name: 'insuranceRisks',
      });

      useEffect(() => {
        const subscription = watch((values) => {
          onChange(values);
        });

        return () => subscription.unsubscribe();
      }, [watch]);

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        setValue('isValid', isValid);
      }, [isValid]);

      const handleClick = useCallback(
        (value: RisksType, onChange: (item: RisksType) => void) => () => {
          if (!value.checked)
            onAnalyticEventSend?.(
              InsuranceRisksAnalyticEvent.ON_RISK_GAP_SELECTED
            );

          onChange({
            ...value,
            checked: !value.checked,
          });
        },
        [value, onChange]
      );

      return (
        <WidgetContainerStyled ref={forwardRef}>
          <HeaderWithSubText
            title={t('SMART:InsuranceRisks.title', {
              defaultValue: i18nDefaultValues.InsuranceRisks.title,
            })}
          />
          <StyledThemeProvider theme={baseTheme}>
            {fields.map((field, index) => (
              <Controller
                key={field.id}
                name={`insuranceRisks.${index}`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SelectorCard
                    name={value.name}
                    key={`${value.code}`}
                    id={value.code}
                    label={value.name}
                    checked={value.checked}
                    description={value.description}
                    stopContentPropagation={value.checked}
                    readOnly={value.binding}
                    onClick={handleClick(value, onChange)}
                    mobileFullWidth
                  />
                )}
              />
            ))}
          </StyledThemeProvider>
        </WidgetContainerStyled>
      );
    }
  )
);
