import i18n from '@app/i18n/config';

export enum REGISTRATION {
  firstName = 'name',
  lastName = 'surname',
  middleName = 'secondName',
  birthDay = 'birthDate',
  email = 'email',
  phone = 'phone',
}

export const REGISTRATION_ERROR_TEXT = {
  firstName: i18n.t('COMMON:errors.firstName'),
  lastName: i18n.t('COMMON:errors.lastName'),
  middleName: i18n.t('COMMON:errors.middleName'),
  birthDay: i18n.t('COMMON:errors.birthDay'),
  email: i18n.t('COMMON:errors.email'),
  phone: i18n.t('COMMON:errors.wrongPhone'),
};
