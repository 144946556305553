import { getNumberOfDays } from '@shared/utils';

type ParamsFormattingDate = {
  min: string;
  max: string;
  start: string;
  end: string;
};

const ONE_DAY = 24 * 60 * 60 * 1000;

export const validatePeriod = ({
  min,
  max,
  start,
  end,
}: ParamsFormattingDate) => {
  const minDate = getNumberOfDays(min) || 0;
  const maxDate = getNumberOfDays(max) || 0;

  const timeDifference = Math.abs(
    new Date(start).getTime() - new Date(end).getTime()
  );

  const differenceInDays = Math.floor(timeDifference / ONE_DAY) + 1;
  const res = minDate <= differenceInDays && differenceInDays <= maxDate;

  return res;
};
