import { AutocompleteSelect } from '@pulse-web-ui/autocomplete-select';
import { HelperText } from '@pulse-web-ui/helper-text';
import { DEFAULT_OPTION } from '@shared/constants';
import { useUpdateEffect } from '@shared/hooks';
import { memo, useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type {
  CarData,
  CarValues,
  CarOptions as ICarOptions,
} from '../../types';
import type { FieldError } from 'react-hook-form';

import { Flex, FlexItem } from './styles';
import { CarAnalyticEvent } from '../../constants';
import { CarDataKey } from '../../types';

interface CarOptionsProps
  extends Pick<
    ICarOptions,
    | 'brands'
    | 'models'
    | 'years'
    | 'powers'
    | 'onFindBrands'
    | 'onFindModels'
    | 'onFindYears'
    | 'onFindCarPrice'
    | 'onFindPowers'
    | 'minProductLimit'
  > {
  brandLabel: string;
  modelLabel: string;
  manufactureYearLabel: string;
  powerLabel: string;
  onAnalyticEventSend?: VoidFn<CarAnalyticEvent>;
}

export const CarOptions = memo(
  ({
    brandLabel,
    manufactureYearLabel,
    modelLabel,
    powerLabel,
    brands,
    models,
    powers,
    years,
    onFindBrands,
    onFindModels,
    onFindYears,
    onFindPowers,
    onFindCarPrice,
    minProductLimit,
    onAnalyticEventSend,
  }: CarOptionsProps) => {
    //Проставить data-testid для AutocompleteSelect

    const { setValue, control, watch, clearErrors } =
      useFormContext<CarValues>();

    const carData = watch('car');

    const findData = useCallback(
      (key: CarDataKey, value: string) => {
        if (key === CarDataKey.BRAND) {
          return brands?.find((brand) => brand.value === value);
        }
        if (key === CarDataKey.MODEL) {
          return models?.find((model) => model.value === value);
        }
        if (key === CarDataKey.MANUFACTURE_YEAR) {
          return years?.find((year) => year.value === value);
        }
        if (key === CarDataKey.POWER) {
          return powers?.find((power) => power.value === value);
        }
      },
      [brands, models, years, powers]
    );

    const handleSelectChange = useCallback(
      (
        key: CarDataKey,
        onChange: (value?: { label: string; value: string }) => void,
        fieldsToReset: CarDataKey[]
      ) =>
        (value?: string) => {
          if (value === carData?.[key]?.value) return;

          clearErrors();

          onChange(!value ? DEFAULT_OPTION : findData(key, value));

          fieldsToReset.forEach((fieldToReset) => {
            setValue(`car.${fieldToReset}`, DEFAULT_OPTION);
          });
          setValue('marketPrice', minProductLimit);
          setValue('vin', '');
        },
      [findData, carData]
    );

    useEffect(() => {
      if (!brands?.length || (brands.length === 1 && !brands[0].value))
        onFindBrands();
    }, [brands]);

    useUpdateEffect(() => {
      if (carData?.brand?.value) {
        onFindModels(carData.brand?.value);
        onAnalyticEventSend?.(CarAnalyticEvent.ON_BRAND_SELECTED);
      }
    }, [carData?.brand?.value]);

    useUpdateEffect(() => {
      if (carData?.model?.value) {
        onFindYears(carData?.model?.value);
        onAnalyticEventSend?.(CarAnalyticEvent.ON_MODEL_SELECTED);
      }
    }, [carData?.model?.value]);

    useUpdateEffect(() => {
      if (carData?.manufactureYear?.value) {
        onFindPowers(carData?.manufactureYear?.value);
        onAnalyticEventSend?.(CarAnalyticEvent.ON_YEAR_SELECTED);
      }
    }, [carData?.manufactureYear?.value]);

    useUpdateEffect(() => {
      if (carData?.power?.value) {
        onAnalyticEventSend?.(CarAnalyticEvent.ON_POWER_SELECTED);
        //В данном случае as уместен, т.к. при вызове onFindCarPrice мы гарантируем, что все свойства !== null
        onFindCarPrice(carData as CarData);
        onAnalyticEventSend?.(CarAnalyticEvent.ON_SUM_SELECTED);
      }
    }, [carData?.power?.value]);

    return (
      <Flex data-testid="car-options-block">
        <FlexItem>
          <Controller
            name="car.brand"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <HelperText
                  noMargin
                  status={!!error ? 'error' : 'default'}
                  //TODO: когда в react-hook-form будем хранить только value, без label, то оставить error?.value?.message
                  message={(error as { value?: FieldError })?.value?.message}
                >
                  <AutocompleteSelect
                    testId="brand-autocomplete-select"
                    options={brands}
                    label={brandLabel}
                    status={!!error ? 'error' : undefined}
                    placeholder={brandLabel}
                    onChange={handleSelectChange(CarDataKey.BRAND, onChange, [
                      CarDataKey.MODEL,
                      CarDataKey.MANUFACTURE_YEAR,
                      CarDataKey.POWER,
                    ])}
                    value={value?.value ?? ''}
                  />
                </HelperText>
              );
            }}
          ></Controller>
        </FlexItem>
        <FlexItem>
          <Controller
            name="car.model"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <HelperText
                noMargin
                status={!!error ? 'error' : 'default'}
                //TODO: когда в react-hook-form будем хранить только value, без label, то оставить error?.value?.message
                message={(error as { value?: FieldError })?.value?.message}
              >
                <AutocompleteSelect
                  testId="model-autocomplete-select"
                  options={models}
                  label={modelLabel}
                  placeholder={modelLabel}
                  onChange={handleSelectChange(CarDataKey.MODEL, onChange, [
                    CarDataKey.MANUFACTURE_YEAR,
                    CarDataKey.POWER,
                  ])}
                  status={!!error ? 'error' : undefined}
                  value={value?.value ?? ''}
                  disabled={!carData?.[CarDataKey.BRAND]?.value}
                />
              </HelperText>
            )}
          ></Controller>
        </FlexItem>
        <FlexItem>
          <Controller
            name="car.manufactureYear"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <HelperText
                noMargin
                status={!!error ? 'error' : 'default'}
                //TODO: когда в react-hook-form будем хранить только value, без label, то оставить error?.value?.message
                message={(error as { value?: FieldError })?.value?.message}
              >
                <AutocompleteSelect
                  options={years}
                  testId="year-autocomplete-select"
                  label={manufactureYearLabel}
                  placeholder={manufactureYearLabel}
                  onChange={handleSelectChange(
                    CarDataKey.MANUFACTURE_YEAR,
                    onChange,
                    [CarDataKey.POWER]
                  )}
                  value={value?.value ?? ''}
                  status={!!error ? 'error' : undefined}
                  disabled={!carData?.[CarDataKey.MODEL]?.value}
                />
              </HelperText>
            )}
          ></Controller>
        </FlexItem>
        <FlexItem>
          <Controller
            name="car.power"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <HelperText
                noMargin
                status={!!error ? 'error' : 'default'}
                //TODO: когда в react-hook-form будем хранить только value, без label, то оставить error?.value?.message
                message={(error as { value?: FieldError })?.value?.message}
              >
                <AutocompleteSelect
                  testId="power-autocomplete-select"
                  options={powers}
                  label={powerLabel}
                  status={!!error ? 'error' : undefined}
                  placeholder={powerLabel}
                  onChange={handleSelectChange(CarDataKey.POWER, onChange, [])}
                  value={value?.value ?? ''}
                  disabled={!carData?.[CarDataKey.MANUFACTURE_YEAR]?.value}
                />
              </HelperText>
            )}
          ></Controller>
        </FlexItem>
      </Flex>
    );
  }
);
