/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable indent */
import { useStores } from '@shared/hooks';
import { detectIOS } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { GlobalError } from '@features/global-error';

import { Spinner } from '@pulse-web-ui/spinner';
import { AuthBoxAdapter } from '@entities/adapters';

import { BFFBox } from '../bff-box';
import { InitBox } from '../init-box';
import {
  FormBuilderWrapper,
  FormBuilderScrollWrapper,
  FormBuilderComponentsWrapper,
} from './form-builder.style';
import {
  FormBuilderFooter,
  FormBuilderHeader,
  FormBuilderStep,
  FormBuilderSubmit,
} from './components';
import { FormBuilderEventBus, getDefaultValues } from './utils';

import type { FC, UIEvent } from 'react';
import type { FormBuilderStructure } from '@shared/types';
import { FormBuilderAction } from './utils/form-builder-event-bus';

export type FormBuilderUpdateHandler = (fieldName: string, value: any) => void;

type Props = {
  components: FormBuilderStructure;
};

export const FormBuilder: FC<Props> = observer(({ components }) => {
  const {
    MainStore: {
      applicationStore: {
        activeStep,
        wantNextStep,
        loading,
        setCurrentStep,
        flowConfig: { isShowBackButton },
      },
      productStore: { isPolicyBanned, formState },
    },
  } = useStores();

  const isIOS = detectIOS();
  const scrollWrapperRef = useRef<HTMLDivElement>(null);

  // добавить в shared функцию isLastStep()
  const isBackButtonVisible =
    !!(activeStep > 1 && isShowBackButton) && !isPolicyBanned;

  useEffect(() => {
    setCurrentStep(activeStep);
  }, [activeStep]);

  if (loading) {
    return <Spinner />;
  }

  useEffect(() => {
    if (wantNextStep) {
      if (scrollWrapperRef?.current) {
        scrollWrapperRef.current.scrollTop = 0;
      }
    }
  }, [wantNextStep]);

  const defaultValues = useMemo(
    () => getDefaultValues(components, formState),
    [components, formState]
  );

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    if (
      isIOS &&
      (e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
        e.currentTarget.clientHeight ||
        e.currentTarget.scrollTop === 0)
    ) {
      if (scrollWrapperRef.current) {
        scrollWrapperRef.current.style.overflow = 'hidden';
      }
    }
  };

  const handleTouchStart = () => {
    if (
      isIOS &&
      scrollWrapperRef?.current &&
      scrollWrapperRef.current.style.overflow === 'hidden'
    ) {
      scrollWrapperRef.current.style.overflow = 'auto';
    }
  };

  const updateFormHandler: FormBuilderUpdateHandler = useCallback(
    (fieldName, value) => {
      FormBuilderEventBus.publish(FormBuilderAction.UPDATE_FORM, {
        fieldName,
        value,
      });
    },
    []
  );

  const updateFormErrorHandler = useCallback(
    (fieldName: string, err: string) => {
      FormBuilderEventBus.publish(FormBuilderAction.UPDATE_FORM_ERRORS, {
        fieldName,
        err,
      });
    },
    []
  );

  const formBuilderIsReady = useMemo(
    () => Object.keys(defaultValues).length > 0,
    [defaultValues]
  );

  return (
    <FormBuilderWrapper>
      <InitBox />
      <GlobalError />
      <BFFBox updateFormValue={updateFormHandler} />
      <AuthBoxAdapter
        updateFormValue={updateFormHandler}
        setFormValueError={updateFormErrorHandler}
      />
      <FormBuilderScrollWrapper
        ref={scrollWrapperRef}
        onScroll={handleScroll}
        onTouchStart={handleTouchStart}
      >
        <FormBuilderHeader components={components} />
        <FormBuilderComponentsWrapper isBackButtonVisible={isBackButtonVisible}>
          {formBuilderIsReady && (
            <FormBuilderStep
              config={components.body}
              defaultValues={defaultValues}
            />
          )}
        </FormBuilderComponentsWrapper>
        <FormBuilderSubmit components={components} />
      </FormBuilderScrollWrapper>
      <FormBuilderFooter components={components} />
    </FormBuilderWrapper>
  );
});

FormBuilder.displayName = 'FormBuilder';
