import { useStores } from '@shared/hooks';
import { getRisks } from '@shared/utils';

import type { SubmitRequest } from '../types';

import { OBJECT_TYPE } from '../constants';
import {
  getInsuranceObject,
  getVehicleDrivers,
  getVehicleOwner,
} from '../utils';

export const useGetRequestData = (): SubmitRequest => {
  const {
    MainStore: {
      initProductStore: {
        initState: { code },
      },
      productStore: {
        formState: {
          Car,
          CarOwner,
          InsuranceParameters,
          InsuranceRisks,
          VehicleDocument,
          WhoIsDriver,
        },
      },
    },
  } = useStores();

  const effectiveSince = (
    InsuranceParameters?.period?.startDate ?? new Date()
  ).toJSON();
  const price = Number(Car?.marketPrice) || 0;

  const baseParameters = {
    effectiveSince,
    productCode: code ?? '',
    contractDuration: InsuranceParameters?.period?.period || '',
    riskObjects: getRisks({
      insuranceRisks: InsuranceRisks,
      amount: price,
    }),
  };

  const productProperty = {
    kind: OBJECT_TYPE,
    hasActiveKasko: Boolean(Car?.haveValidPolicy),
    insuranceSum: price,
    vehicleOwner: getVehicleOwner(CarOwner),
    vehicleDrivers: getVehicleDrivers(WhoIsDriver?.drivers || []),
    insuranceObject: getInsuranceObject({
      car: Car,
      insuranceParameters: InsuranceParameters,
      vehicleDocument: VehicleDocument,
    }),
  };

  return { baseParameters, productProperty };
};
