import { useEffect } from 'react';

import { Endpoints } from '@shared/constants';
import { useStores } from '@shared/hooks/use-stores';

export const useHandleErrorRetry = (
  selectRefetch: (index: string) => void
): void => {
  const {
    MainStore: {
      errorStore: { displayGlobalErrorUrl, errorRetry, setErrorRetry },
    },
  } = useStores();

  useEffect(() => {
    if (errorRetry && displayGlobalErrorUrl) {
      const endpoints = Object.entries(Endpoints);

      endpoints.forEach(([key, endpoint]) => {
        if (displayGlobalErrorUrl.includes(endpoint)) {
          selectRefetch(key);
        }
      });

      setErrorRetry(false);
    }
  }, [errorRetry, displayGlobalErrorUrl]);
};
