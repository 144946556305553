import { useStores } from '@shared/hooks';
import { useQuery } from 'react-query';
import { fetchData, validateData } from '@shared/utils';
import { Endpoints, QUERY_KEYS } from '@shared/constants';

import { getPricesRequestSchema } from './schemas';

import type {
  GetPricesResponse,
  GetPricesRequest,
  ValidateOptions,
} from './types';
import type { ResponseError } from '@shared/types';

export const useGetPrices = (
  data: GetPricesRequest,
  options: ValidateOptions
) => {
  const {
    MainStore: {
      authStore: { accessToken },
    },
  } = useStores();

  const fetch = fetchData<GetPricesResponse>({
    url: Endpoints.GET_PRICES,
    method: 'POST',
    data,
    headers: {
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    },
  });

  const queryResult = useQuery<GetPricesResponse, ResponseError>(
    [QUERY_KEYS.getPrices, data],
    fetch,
    {
      enabled: options?.hasError
        ? false
        : validateData(data, getPricesRequestSchema, options),
    }
  );

  return queryResult;
};
