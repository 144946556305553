import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@pulse-web-ui/button';
import { Checkbox } from '@pulse-web-ui/checkbox';
import { WidgetContainer } from '@pulse-web-ui/containers';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';
import {
  Suspense,
  forwardRef,
  lazy,
  memo,
  useEffect,
  useState,
  useCallback,
  type Ref,
} from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import type {
  ErrorDriver,
  Driver as DriverType,
  WhoIsDriverOptions,
  WhoIsDriverValues,
  ValidationContext,
} from './types';
import type { SmartComponentProps } from '@smart-components/index';

import { Driver } from './components';
import { WhoIsDriverAnalyticEvent, DEFAULT_DRIVER } from './constants';
import { i18nDefaultValues } from './i18n';
import { schema } from './schema';
import { getDefaultValues } from './utils';
import { Form } from './who-is-driver.styles';

const Plus = lazy(() => import('@pulse-web-ui/icons/lib/lazy/plus'));

export const WhoIsDriver = memo(
  forwardRef(
    (
      {
        isSubmitting,
        onChange,
        value,
        disabled,
        options: { minAgeOfDriver, maxDriversNumber, me },
        onAnalyticEventSend,
      }: SmartComponentProps<
        WhoIsDriverValues,
        WhoIsDriverOptions,
        WhoIsDriverAnalyticEvent
      >,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();
      const [driversCount, setDriversCount] = useState<number>(1);

      const {
        control,
        trigger,
        setValue,
        getValues,
        formState: { isValid },
        clearErrors,
        watch,
      } = useForm<WhoIsDriverValues, ValidationContext>({
        defaultValues: getDefaultValues(driversCount, value),
        resolver: yupResolver(schema),
        context: { minAgeOfDriver },
      });

      const { fields, append, remove } = useFieldArray({
        control,
        name: 'drivers',
        keyName: 'key',
      });
      const isMe = Boolean(value?.forMe);
      const { name, surname, middlename, birthday } = me;

      useEffect(() => {
        if (isMe) {
          const firstDriver = getValues('drivers.0');

          setValue('drivers.0', {
            ...firstDriver,
            name: me.name,
            surname: me.surname,
            middlename: me.middlename,
            birthday: me.birthday,
          });

          clearErrors('drivers.0.name');
          clearErrors('drivers.0.surname');
          clearErrors('drivers.0.middlename');
          clearErrors('drivers.0.birthday');
        }
      }, [isMe, name, surname, middlename, birthday?.toDateString()]);

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        setValue('isValid', isValid);
        onChange({
          ...getValues(),
          isValid,
        });
      }, [isValid, driversCount]);

      useEffect(() => {
        const subscription = watch((values) => {
          onChange(values as WhoIsDriverValues);
        });

        return () => subscription.unsubscribe();
      }, [watch, onChange]);

      const handleChangeInsurance =
        (onChange: (data: DriverType) => void) => (data: DriverType) => {
          onChange(data);
        };

      const addDriver = useCallback(() => {
        setDriversCount((prev) => prev + 1);
        append({ ...DEFAULT_DRIVER, id: uuid() });
      }, [driversCount, onChange]);

      const removeDriver = useCallback(
        (index: number) => {
          setDriversCount((prev) => prev - 1);
          remove(index);

          if (index === 0) {
            setValue('forMe', false);
          }
        },
        [driversCount, onChange]
      );

      const handleAnalyticEventSend = useCallback(
        (index: number) => {
          if (
            getValues(`drivers.${index}.surname`) &&
            getValues(`drivers.${index}.name`) &&
            getValues(`drivers.${index}.middlename`) &&
            getValues(`drivers.${index}.birthday`) &&
            getValues(`drivers.${index}.license`) &&
            getValues(`drivers.${index}.licenseDate`) &&
            getValues(`drivers.${index}.yearOfStart`)
          ) {
            if (index === 0) {
              onAnalyticEventSend?.(
                WhoIsDriverAnalyticEvent.ON_DRIVER_SELECTED
              );
            } else {
              onAnalyticEventSend?.(WhoIsDriverAnalyticEvent.ON_DRIVER_ADDED);
            }
          }
        },
        [onAnalyticEventSend]
      );

      const currentDate = new Date();
      const maxBirthday = new Date(
        currentDate.getFullYear() - minAgeOfDriver,
        currentDate.getMonth(),
        currentDate.getDate() - 1
      );

      const maxLicenseDate = currentDate;

      const fieldsLength = fields.length;
      const isMaxDriversCount = fieldsLength < (maxDriversNumber ?? 0);

      return (
        <WidgetContainer ref={forwardRef} data-testid="who-is-driver">
          <Form>
            <HeaderWithSubText
              title={t('SMART:WhoIsDriver.title', {
                defaultValue: i18nDefaultValues.WhoIsDriver.title,
              })}
              subTitle={t('SMART:WhoIsDriver.subTitle', {
                defaultValue: i18nDefaultValues.WhoIsDriver.subTitle,
              })}
            />
            <Controller
              render={({ field: { onChange } }) => (
                <div data-testid="for-me">
                  <Checkbox
                    onChange={onChange}
                    disabled={disabled}
                    checked={isMe}
                    label={t('SMART:WhoIsDriver.labels.client', {
                      defaultValue: i18nDefaultValues.WhoIsDriver.labels.client,
                    })}
                    data-testid="check-is-me"
                  />
                </div>
              )}
              control={control}
              name="forMe"
            />
            {fields.map((field, index) => (
              <Controller
                key={field.id}
                name={`drivers.${index}`}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Driver
                    serialNumber={index + 1}
                    remove={removeDriver}
                    value={value}
                    onChange={handleChangeInsurance(onChange)}
                    error={error as ErrorDriver | undefined}
                    fieldsLength={fieldsLength}
                    disabled={disabled}
                    maxBirthday={maxBirthday}
                    maxLicenseDate={maxLicenseDate}
                    isMe={isMe}
                    trigger={trigger}
                    clearErrors={clearErrors}
                    handleAnalyticEventSend={handleAnalyticEventSend}
                  />
                )}
              />
            ))}
            {isMaxDriversCount && (
              <Button
                icon={
                  <Suspense>
                    <Plus color="active" width={24} />
                  </Suspense>
                }
                variant="text"
                onClick={addDriver}
                disabled={disabled}
              >
                {t('SMART:WhoIsDriver.labels.addMore', {
                  defaultValue: i18nDefaultValues.WhoIsDriver.labels.addMore,
                })}
              </Button>
            )}
          </Form>
        </WidgetContainer>
      );
    }
  )
);
