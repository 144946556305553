export const i18nDefaultValues = {
  PhoneAndEmail: {
    hints: {
      sendByPhone: 'На него мы отправим проверочный код',
      sendByMail: 'На эту почту мы отправим страховой полис и другие документы',
    },
    labels: {
      phone: 'Телефон',
      email: 'Email',
    },
  },
};
