import { isAppleSafari } from '@shared/utils/is-apple-safari';
import { useState, useCallback, useEffect } from 'react';

import type { DocumentType, OnDownloadPDF } from '@pulse-web-ui/documents-list';

import {
  initDocumentsStatusList,
  onDocumentLoadHandler,
} from './use-handle-download-pdf.utils';
import { useMultipleDownloadPdf } from './use-multiple-download-pdf';

export const useHandleDownloadPDF = (
  accessToken: string,
  documentsList?: DocumentType[]
) => {
  const isIOS = isAppleSafari();

  // Массив статусов скачивания для обновления view.
  const [documentsStatusList, setDocumentsStatusList] = useState(
    initDocumentsStatusList(documentsList?.length)
  );

  // Запускаем скачивание файла у документов с активным признаком включения запроса.
  const results = useMultipleDownloadPdf({
    documentsList,
    accessToken,
  });

  const resultsDeps = JSON.stringify(results);
  const documentsListDeps = JSON.stringify(documentsList);

  const handleDownloadClick: OnDownloadPDF = useCallback(
    (index) => {
      results[index].refetch().then(({ data }) => {
        if (data && documentsList?.[index].clientDocument) {
          onDocumentLoadHandler({
            data,
            name: documentsList[index].clientDocument,
            isIOS,
          });
        }
      });
    },
    [resultsDeps, documentsListDeps, isIOS]
  );

  useEffect(() => {
    setDocumentsStatusList(
      results.map(({ isFetching, isError }) => ({
        isLoading: isFetching,
        isError,
      }))
    );
  }, [resultsDeps]);

  useEffect(() => {
    // Сбрасываем статусы и признаки включения запросов при получении новых документов.
    setDocumentsStatusList(initDocumentsStatusList(documentsList?.length));
  }, [documentsListDeps]);

  return {
    handleDownloadClick,
    documentsStatusList,
  };
};
