export type Option = Pick<
  RisksOptionsType,
  'binding' | 'code' | 'description' | 'name' | 'coverageSum'
>;

export type Risk = {
  code: string;
  amount: number;
};

export interface RisksOptionsType {
  code: string;
  name: string;
  coverageSumByClient?: boolean;
  coverageSum: number[];
  discreteCoverage?: boolean;
  description: string;
  binding: boolean;
  //TODO: Доработать структуру options
  options?: any[];
  checked: boolean;
  items?: RadioItem[];
  isRadio?: boolean;
  isSlider?: boolean;
  sum?: number;
  type: string;
  slider?: Slider;
}

export interface RadioItem {
  label: string;
  id: string;
  value: string;
}

export interface Slider {
  min: number;
  max: number;
}

export interface RisksType {
  code: string;
  name: string;
  coverageSumByClient: boolean;
  coverageSum: Array<number>;
  discreteCoverage: boolean;
  description: string;
  binding: boolean;
  options: any[];
  sum?: number;
  checked?: boolean;
  type?: string;
}

export enum RisksOptionEnum {
  option = 'option',
  risk = 'risk',
}
