import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Props } from './types';

import { InputCode } from '../input-code';
import { InputPhone } from '../input-phone';
import { OtpWrapper, SubmitPhone } from './otp.styles';
import { i18nDefaultValues } from '../../i18n';

export const Otp = ({
  handleOtp,
  handleAuth,
  displayPhone,
  otpIsLoading,
  authIsLoading,
  externalPhone,
  setDisableForm,
  otpErrorCode,
  setOtpErrorCode,
  authError,
  refetchAuthentication,
  hideOtp,
}: Props) => {
  const { t } = useTranslation();

  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [disabledInputPhone, setDisabledInputPhone] = useState(false);
  const [showInputCode, setShowInputCode] = useState(false);

  const handleChangePhone = (value: string) => {
    setPhone(value);
  };

  useEffect(() => {
    if (externalPhone) {
      setPhone(externalPhone);
      setOtpErrorCode(false);
    }
  }, [externalPhone]);

  useEffect(() => {
    if (phone) {
      sendPhone();
    }
  }, [phone]);

  useEffect(() => {
    if (authError) {
      setDisableForm(false);
      setDisabledInputPhone(false);
      setShowInputCode(false);
    }
  }, [authError]);

  const handleChangeCode = (value: string) => {
    setCode(value);
  };

  const sendPhone = () => {
    setDisableForm(true);
    setShowInputCode(true);
    setDisabledInputPhone(true);
    handleAuth(phone);
  };

  const changePhone = () => {
    setDisableForm(false);
    setOtpErrorCode(false);
    setDisabledInputPhone(false);
    setShowInputCode(false);
    setCode('');
    hideOtp();
  };

  const handleSubmitCode = () => {
    handleOtp(code);
  };

  const handleRefetchAuthentication = (e: boolean) => {
    setOtpErrorCode(false);
    handleOtp('');
    refetchAuthentication(e);
  };

  return (
    <OtpWrapper>
      {displayPhone ? null : (
        <InputPhone
          value={phone}
          onChange={handleChangePhone}
          disabled={disabledInputPhone}
        />
      )}
      {!showInputCode && (
        <SubmitPhone onClick={sendPhone} disabled={authIsLoading}>
          {t('SMART:Submit.labels.continue', {
            defaultValue: i18nDefaultValues.Submit.labels.continue,
          })}
        </SubmitPhone>
      )}
      {showInputCode && (
        <InputCode
          value={code}
          onChange={handleChangeCode}
          changePhone={changePhone}
          submitCode={handleSubmitCode}
          disabled={otpIsLoading}
          isError={otpErrorCode}
          refetchAuthentication={handleRefetchAuthentication}
        />
      )}
    </OtpWrapper>
  );
};
