/* eslint-disable indent */
import {
  useStores,
  useGlobalFetching,
  useHandleErrorRetry,
} from '@shared/hooks';
import { useUpdateProfile, useHandleGetPrice } from '@shared/queries';
import { updateFormByMobileApp } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useQueryErrorResetBoundary } from 'react-query';

import type { FormBuilderUpdateHandler } from '@features/form-builder';
import type { FC } from 'react';

import { useHandleNextStep } from './hooks';
import {
  useHandleSubmitOnPaymentPage,
  useHandleFooter,
} from './smart-components-hooks';

type BFFBoxProps = {
  updateFormValue: FormBuilderUpdateHandler;
};

export const BFFBox: FC<BFFBoxProps> = observer(({ updateFormValue }) => {
  const {
    MainStore: {
      applicationStore: { deviceType, phoneFromMobileApp },
      initProductStore: { formBuilderIsMounted },
      authStore: { setDisableForm },
      errorStore: { setErrorRetry },
    },
  } = useStores();

  const isGlobalFetching = useGlobalFetching();

  // Smart components hooks
  useHandleSubmitOnPaymentPage(updateFormValue);
  useHandleFooter();

  // Queries hooks

  const refetchPrice = useHandleGetPrice();
  const updateProfileRefetch = useUpdateProfile();

  // Other hooks
  useHandleNextStep();

  useEffect(() => {
    setDisableForm(isGlobalFetching);
  }, [isGlobalFetching]);

  const { reset } = useQueryErrorResetBoundary();

  const selectRefetch = (index: string) => {
    reset();
    setErrorRetry(false);
    switch (index) {
      // TODO: Вынести case в константу, лучше использовать enum Endpoints
      case 'UPDATE_PROFILE':
        return updateProfileRefetch();
      case 'GET_PRICES':
        return refetchPrice();
    }
  };

  useHandleErrorRetry(selectRefetch);

  // Добавление данных с мп в смарт компонент
  useEffect(() => {
    if (!formBuilderIsMounted) return;
    updateFormByMobileApp(updateFormValue, deviceType, phoneFromMobileApp);
  }, [phoneFromMobileApp, deviceType, formBuilderIsMounted]);

  return null;
});
