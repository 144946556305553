/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';
import { PRICES_MAX_VALUE } from '@shared/constants';
import { useHandlePressKey } from '@shared/hooks';
import { formInsurancePeriodSchema } from '@shared/scheme/auth/reg-form-scheme-VZR';
import { KeyCode, type ProductPrice } from '@shared/types';
import { getPercentageDiff } from '@shared/utils';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { ControllerFieldState } from 'react-hook-form';

import {
  PromoWrapper,
  PromoTextInputWrapper,
  PromoSubmitButton,
} from './promo-code.styles';
import { i18nDefaultValues } from '../../i18n';

interface Props {
  promoCode?: string;
  price: ProductPrice;
  disabled?: boolean;
  isPricesLoading?: boolean;
  onChange?: (promoCode: string) => void;
  onSubmit: (promoCode: string) => void;
}

type FormPromoProps = {
  promoCode?: string;
};

export const PromoCode = memo(
  ({
    promoCode,
    price,
    price: { isSuccessfulPromo, premiumAndDelta, premiumAndDeltaPromo },
    disabled,
    isPricesLoading,
    onChange,
    onSubmit,
  }: Props) => {
    const [promoCodeFailedMessage, setPromoCodeFailedMessage] = useState<
      string | undefined
    >(undefined);
    const { t } = useTranslation(['COMMON']);
    const [submitPromoDisabled, setSubmitPromoDisabled] = useState(false);
    const [isInputActive, setIsInputActive] = useState(false);

    const {
      control,
      formState: { errors },
      handleSubmit,
      setError,
      clearErrors,
      watch,
      getValues,
    } = useForm<FormPromoProps>({
      resolver: yupResolver(formInsurancePeriodSchema),
      shouldFocusError: true,
      mode: 'all',
      defaultValues: { promoCode },
    });

    useEffect(() => {
      const { promoCode: promoCodeValue } = getValues();
      if (promoCodeValue?.length === 0 && !isSuccessfulPromo) {
        onSubmit('');
        clearErrors('promoCode');
        setPromoCodeFailedMessage(undefined);
      }
    }, [watch('promoCode')]);

    useEffect(() => {
      const subscription = watch((value) => {
        if (onChange) {
          onChange(value.promoCode ? value.promoCode : '');
        }
      });

      return () => subscription.unsubscribe();
    }, [watch]);

    const submitPromoCode = handleSubmit((data) => {
      setPromoCodeFailedMessage(undefined);
      onSubmit(!!data.promoCode?.length ? data.promoCode : '');
    });

    const handleKeyPressEnter = useCallback(() => {
      isInputActive && submitPromoCode();
    }, [isInputActive]);

    useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [isInputActive]);

    const getLabel = () => {
      const { promoCode: statePromoCode } = getValues();

      return isSuccessfulPromo && promoCode === statePromoCode
        ? t('SMART:Submit.labels.applied', {
            defaultValue: i18nDefaultValues.Submit.labels.applied,
          })
        : t('SMART:Submit.labels.apply', {
            defaultValue: i18nDefaultValues.Submit.labels.apply,
          });
    };

    useEffect(() => {
      setSubmitPromoDisabled(
        !!isSuccessfulPromo && promoCode !== '' && price.promoCode === promoCode
      );
    }, [isSuccessfulPromo, promoCode]);

    useEffect(() => {
      if (
        !isPricesLoading &&
        isSuccessfulPromo === false &&
        !!price.promoCode
      ) {
        const message = t('SMART:Submit.errors.promoCodeNotValid', {
          defaultValue: i18nDefaultValues.Submit.errors.promoCodeNotValid,
        });
        setError('promoCode', {
          type: 'string',
          message,
        });
        setPromoCodeFailedMessage(message);
        setSubmitPromoDisabled(true);
      }
      if (!isPricesLoading && isSuccessfulPromo && !!price.promoCode) {
        clearErrors('promoCode');
      }
    }, [isPricesLoading, isSuccessfulPromo, price.promoCode]);

    useEffect(() => {
      if (!promoCode?.length) {
        clearErrors('promoCode');
      }
    }, [promoCode]);

    const checkSubmitState = useCallback(
      (val: string) => {
        setSubmitPromoDisabled(val === promoCode);
      },
      [promoCode]
    );

    const handleInputChange = useCallback(
      (onChange: (val: string) => void) => (val: string) => {
        checkSubmitState(val);
        onChange(val);
      },
      []
    );

    const handleInputFocus = useCallback(() => {
      setIsInputActive(true);
    }, []);

    const handleInputBlur = useCallback(() => {
      setIsInputActive(false);
    }, []);

    const inputError =
      !!errors.promoCode || (!!promoCodeFailedMessage && !isSuccessfulPromo);

    const getPromoCodeStatus = useCallback(
      (fieldState: ControllerFieldState) =>
        fieldState.error || (promoCodeFailedMessage && !isSuccessfulPromo)
          ? 'error'
          : 'success',
      [isSuccessfulPromo, promoCodeFailedMessage]
    );

    const discountMessage = useMemo(() => {
      if (premiumAndDelta === String(PRICES_MAX_VALUE)) return '';

      return errors.promoCode?.message
        ? t(errors.promoCode?.message)
        : (!isSuccessfulPromo && promoCodeFailedMessage) ||
            (isSuccessfulPromo && price.promoCode !== ''
              ? t('SMART:Submit.hints.discount', {
                  discount:
                    premiumAndDelta &&
                    premiumAndDeltaPromo &&
                    getPercentageDiff(premiumAndDelta, premiumAndDeltaPromo),
                  defaultValue: i18nDefaultValues.Submit.hints.discount,
                })
              : '');
    }, [
      isSuccessfulPromo,
      promoCode,
      promoCodeFailedMessage,
      errors.promoCode?.message,
      premiumAndDelta,
      premiumAndDeltaPromo,
    ]);

    return (
      <PromoWrapper>
        <PromoTextInputWrapper data-test="Promo-code-input">
          <Controller
            control={control}
            name="promoCode"
            render={({ field: { onChange, value }, fieldState }) => (
              <HelperText
                status={getPromoCodeStatus(fieldState)}
                message={discountMessage}
              >
                <Input
                  label={t('SMART:Submit.labels.promoCode', {
                    defaultValue: i18nDefaultValues.Submit.labels.promoCode,
                  })}
                  value={value || ''}
                  onChange={handleInputChange(onChange)}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  error={inputError}
                  disabled={disabled}
                />
              </HelperText>
            )}
          />
        </PromoTextInputWrapper>
        <PromoSubmitButton
          label={getLabel()}
          onClick={submitPromoCode}
          disabled={submitPromoDisabled}
          variant="secondary-2"
          adaptiveWidth
          data-test="Promo-code-submit"
        />
      </PromoWrapper>
    );
  }
);

PromoCode.displayName = 'PromoCode';
