/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormBuilderStructure } from '@shared/types';

export type formValue = {
  [key: string]: any;
};

export const getDefaultValues = (
  components: FormBuilderStructure,
  formState: formValue
): formValue => {
  const bodyValues = components.body.reduce((values, item) => {
    const storeData = formState[item.props.name];
    return {
      ...values,
      [item.props.id]: {
        ...item.props.value,
        ...(storeData ? storeData : {}),
      },
    };
  }, {});
  return {
    ...bodyValues,
  };
};
