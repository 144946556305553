/* eslint-disable @typescript-eslint/no-explicit-any */
import type { HeaderStep, SmartHeaderIconKey } from '@smart-components/index';

// TODO: вернуть после подключения из smart-components-kit
//import type {
//   HeaderStep,
//   HeaderBaseIconKey,
// } from '@pulse-smart-components-kit/header-base';

export enum CascoStep {
  CALCULATION = 'calculation',
  OPTIONS = 'options',
  FORMALIZATION = 'formalization',
  PAYMENT = 'payment',
  CLIENTS = 'clients',
}

export type VZRProductConfig = ProductConfig<TravelGlobalVariables>;

export type TravelGlobalVariables = {
  otpStep: number;
  isShowBackButton: boolean; // Флаг отображения кнопки "Назад"
};

export type ProductConfig<T> = {
  name: string;
  header: ConfigSmartComponent[];
  footer: ConfigSmartComponent[];
  submit: ConfigSmartComponent[];
  flow: ProductStep[];
  globalVariables: T;
};

export type ProductStep = {
  components: ConfigSmartComponent[];
  icon: SmartHeaderIconKey; //TODO: вернуть после подключения из smart-components-kit HeaderBaseIconKey;
  name: string;
  additionalVariables?: {
    [key: string]: any;
  };
};

export type ConfigSmartComponent = {
  name: string;
  id: string;
  additionalVariables?: {
    value?: any;
    [key: string]: any;
  };
};

export type FlowConfig<T> = T & {
  headerConfig: HeaderStep[];
  stepConfig: StepConfig[];
  maxSteps: number;
};

export type StepConfig = {
  step: number;
  value: {
    [key: string]: any;
  };
};
