import { styled } from '@pulse-web-ui/theme';

export const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 16px;
  margin-bottom: 24px;
`;
