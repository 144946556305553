/* eslint-disable indent */
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { Car } from '@entities/import-smart-components/car';
import { FallbackSkeleton } from '@shared/components';
import { DEFAULT_OPTION } from '@shared/constants';
import {
  useHandleManualInput,
  useStores,
  useUpdateEffect,
} from '@shared/hooks';
import { CarAnalyticEvent } from '@smart-components/car';
import { observer } from 'mobx-react-lite';
import {
  type Ref,
  Suspense,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type { AnalyticEventsMap, VehicleData } from '@shared/types';
import type { CarData } from '@smart-components/car';
import type { CarValues } from '@smart-components/car/types';
import type { SmartComponentAdapterProps } from '@smart-components/index';

import { useHandleCarData } from './hooks';
import {
  useGetBrands,
  useGetModels,
  useGetYears,
  useGetPowers,
  useGetCarPrice,
} from './queries';

const analyticEventsMap: AnalyticEventsMap<CarAnalyticEvent> = {
  [CarAnalyticEvent.ON_BRAND_SELECTED]: {
    name: analyticEvents.carBrandSelected,
  },
  [CarAnalyticEvent.ON_MODEL_SELECTED]: {
    name: analyticEvents.carModelSelected,
  },
  [CarAnalyticEvent.ON_YEAR_SELECTED]: {
    name: analyticEvents.carYearSelected,
  },
  [CarAnalyticEvent.ON_POWER_SELECTED]: {
    name: analyticEvents.carPowerSelected,
  },
  [CarAnalyticEvent.ON_SUM_SELECTED]: {
    name: analyticEvents.carSumSelected,
  },
  [CarAnalyticEvent.ON_VALID_KASKO]: {
    name: analyticEvents.validKasko,
  },
  [CarAnalyticEvent.ON_KREDIT_AVTO]: {
    name: analyticEvents.kreditAvto,
  },
  [CarAnalyticEvent.ON_MILEAGE_SELECTED]: {
    name: analyticEvents.carMileageSelected,
  },
  [CarAnalyticEvent.ON_VIN_SELECTED]: {
    name: analyticEvents.carVinSelected,
  },
  [CarAnalyticEvent.ON_CHANGE_CAR_DATA]: {
    name: analyticEvents.changeCarData,
  },
  [CarAnalyticEvent.ON_CHANGE_CAR_DATA_ACCEPTED]: {
    name: analyticEvents.changeCarDataAccepted,
  },
  [CarAnalyticEvent.ON_REG_NUMBER_SELECTED]: {
    name: analyticEvents.regNumberSelected,
  },
  [CarAnalyticEvent.ON_REG_NUMBER_SEARCH]: {
    name: analyticEvents.regNumberSearch,
  },
  [CarAnalyticEvent.ON_REG_NUMBER_NOT_FOUND]: {
    name: analyticEvents.regNumberNotFound,
  },
};

const getOptionsFromVehicleData = (vehicleData: VehicleData[]) =>
  vehicleData.map(({ code, name }) => ({
    label: name,
    value: code,
  }));

export const CarAdapter = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting }: SmartComponentAdapterProps<CarValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: {
            isManualCarInput,
            isCarNumberSmartComponentShowing,
            updateFormValue,
            hasUnknownCarNumberError,
            setHasUnknownCarNumberError,
            isCarNumberNotReceived,
            setIsCarNumberNotReceived,
          },
          initProductStore: {
            initState: { code, minProductLimit, maxProductLimit, extraParams },
          },
          productStore: {
            car,
            setCar,
            formState: { InsuranceParameters },
          },
        },
      } = useStores();

      //TODO: вынести в regex после переноса useHandleCarNumber из BFFBox
      const carNumberWithoutMask = value?.carNumber?.replaceAll(/[_\s]/g, '');

      const { isFoundAllCarData, handleFindCar } =
        useHandleCarData(carNumberWithoutMask);

      const [brandId, setBrandId] = useState<string | undefined>(
        value?.car?.brand?.value
      );
      const [modelId, setModelId] = useState<string | undefined>(
        value?.car?.model?.value
      );
      const [yearId, setYearId] = useState<string | undefined>(
        value?.car?.manufactureYear?.value
      );
      const [powerId, setPowerId] = useState<string | undefined>(
        value?.car?.power?.value
      );

      const region = useMemo(
        () => extraParams?.find(({ code }) => code === 'defaultRegion')?.value,
        [extraParams]
      );

      const { refetch: refetchBrands, data: brandsData } = useGetBrands(code);
      const { data: modelsData } = useGetModels({ code, brandId });
      const { data: yearsData } = useGetYears({ code, brandId, modelId });
      const { data: powersData } = useGetPowers({
        code,
        brandId,
        modelId,
        yearId,
      });

      const { refetch: refetchCarPrice, data: carPriceResponse } =
        useGetCarPrice({
          code,
          brandId,
          modelId,
          yearId,
          powerId,
          region,
        });

      const handleFindBrands = useCallback(() => {
        if (!code) return;
        refetchBrands();
      }, [code, refetchBrands]);

      const handleFindModels = useCallback((brand: string) => {
        setBrandId(brand);
        // Отчистка идентификаторов которые зависят от id бренда
        setModelId(undefined);
        setYearId(undefined);
        setPowerId(undefined);
      }, []);

      const handleFindYears = useCallback((model: string) => {
        setModelId(model);
        // Отчистка идентификаторов которые зависят от id модели
        setYearId(undefined);
        setPowerId(undefined);
      }, []);

      const handleFindPowers = useCallback((year: string) => {
        setYearId(year);
        // Отчистка идентификаторов которые зависят от id года
        setPowerId(undefined);
      }, []);

      const handleFindCarPrice = useCallback((data: CarData) => {
        setBrandId(data.brand?.value);
        setModelId(data.model?.value);
        setYearId(data.manufactureYear?.value);
        setPowerId(data.power?.value);
      }, []);

      const brands = useMemo(() => {
        if (!isFoundAllCarData && !brandsData?.data?.length && brandId)
          return [DEFAULT_OPTION];
        return brandsData?.data
          ? getOptionsFromVehicleData(brandsData.data)
          : [];
      }, [brandsData?.data, isFoundAllCarData, brandId]);

      const models = useMemo(() => {
        if (!isFoundAllCarData && !modelsData?.data?.length && modelId)
          return [DEFAULT_OPTION];

        return modelsData?.data
          ? getOptionsFromVehicleData(modelsData.data)
          : [];
      }, [modelsData?.data, isFoundAllCarData, modelId]);

      const years = useMemo(() => {
        if (!isFoundAllCarData && !yearsData?.data?.length && yearId)
          return [DEFAULT_OPTION];

        return yearsData?.data ? getOptionsFromVehicleData(yearsData.data) : [];
      }, [yearsData?.data, isFoundAllCarData, yearId]);

      const powers = useMemo(() => {
        if (!isFoundAllCarData && !powersData?.data?.length && powerId)
          return [DEFAULT_OPTION];

        return powersData?.data
          ? getOptionsFromVehicleData(powersData.data)
          : [];
      }, [powersData?.data, isFoundAllCarData, powerId]);

      const handleManualInput = useHandleManualInput(carNumberWithoutMask);

      const handleCarNumberIsNotReceived = useCallback(() => {
        setIsCarNumberNotReceived(true);
        handleManualInput();
      }, [handleManualInput, setIsCarNumberNotReceived]);

      const handleCarNumberIsReceived = useCallback(() => {
        setIsCarNumberNotReceived(false);
      }, [setIsCarNumberNotReceived]);

      const options = useMemo(
        () => ({
          onFindCar: handleFindCar,
          onFindBrands: handleFindBrands,
          onFindModels: handleFindModels,
          onFindYears: handleFindYears,
          onFindPowers: handleFindPowers,
          onFindCarPrice: handleFindCarPrice,
          brands,
          models,
          years,
          powers,
          minProductLimit: Number(minProductLimit),
          maxProductLimit: Number(maxProductLimit),
          car,
          isManualCarInput,
          hasUnknownCarNumberError,
          isFoundAllCarData,
          handleCarNumberIsNotReceived,
          isCarNumberNotReceived,
          handleCarNumberIsReceived,
        }),
        [
          isCarNumberSmartComponentShowing,
          handleFindBrands,
          handleFindModels,
          handleFindYears,
          handleFindCar,
          handleFindPowers,
          handleFindCarPrice,
          brands,
          models,
          years,
          powers,
          minProductLimit,
          maxProductLimit,
          car,
          isManualCarInput,
          hasUnknownCarNumberError,
          isFoundAllCarData,
          handleCarNumberIsNotReceived,
          isCarNumberNotReceived,
          handleCarNumberIsReceived,
        ]
      );

      useEffect(() => {
        if (value?.car?.brand?.value) setBrandId(value?.car?.brand?.value);
      }, [value?.car?.brand?.value]);

      useEffect(() => {
        if (value?.car?.model?.value) setModelId(value?.car?.model?.value);
      }, [value?.car?.model?.value]);

      useEffect(() => {
        if (value?.car?.manufactureYear?.value)
          setYearId(value?.car?.manufactureYear?.value);
      }, [value?.car?.manufactureYear?.value]);

      useEffect(() => {
        if (value?.car?.power?.value) setPowerId(value?.car?.power?.value);
      }, [value?.car?.power?.value]);

      useUpdateEffect(() => {
        if (code && brandId && modelId && yearId && powerId && region)
          refetchCarPrice();
      }, [powerId]);

      useUpdateEffect(() => {
        if (!carPriceResponse) return;

        setCar({
          ...car,
          makeId: brandId,
          modelId: modelId,
          power: Number(powerId),
          year: Number(yearId),
          price: carPriceResponse,
        });

        updateFormValue('Car', {
          ...value,
          marketPrice: carPriceResponse?.price ?? minProductLimit,
        });
      }, [carPriceResponse]);

      useUpdateEffect(() => {
        if (InsuranceParameters) {
          updateFormValue('InsuranceParameters', {
            ...InsuranceParameters,
            period: {
              period: InsuranceParameters?.period?.period,
              startDate: undefined,
            },
          });
        }
      }, [value?.haveValidPolicy]);

      const handleChange = useCallback(
        (values: Partial<CarValues>) => {
          onChange(values);
          setHasUnknownCarNumberError(false);
        },
        [onChange]
      );

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<CarAnalyticEvent>(
          analyticEventsMap
        );

      if (isCarNumberSmartComponentShowing) return;

      return (
        <Suspense fallback={<FallbackSkeleton height={274} />}>
          <Car
            ref={forwardRef}
            value={value}
            onChange={handleChange}
            isSubmitting={isSubmitting}
            options={options}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);
