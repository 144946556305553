import { Suspense, forwardRef, useEffect, useMemo, type Ref } from 'react';
import { observer } from 'mobx-react-lite';

import { useIsDesktop, useStores } from '@shared/hooks';
import type {
  SmartComponentAdapterProps,
  PolicyholderDataOptions,
  PolicyholderDataValues,
} from '@smart-components/index';
import { PolicyholderData } from '@entities/import-smart-components/policyholder-data';
import { FallbackSkeleton } from '@shared/components';
import {
  getFormattedPassportCode,
  getRuFormattedDate,
  phoneDisplayValueCasting,
} from '@shared/utils';

export const PolicyholderDataAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<PolicyholderDataValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const isDesktop = useIsDesktop();
      const {
        MainStore: {
          authStore: { disableForm },
          productStore: {
            formState: { WhoIsPolicyholder },
          },
          applicationStore: { isRegistration, updateFormValue },
        },
      } = useStores();

      const options: PolicyholderDataOptions = useMemo(
        () => ({
          middlename: WhoIsPolicyholder?.middlename ?? '',
          name: WhoIsPolicyholder?.name,
          surname: WhoIsPolicyholder?.surname,
          birthday: !!WhoIsPolicyholder?.birthday
            ? getRuFormattedDate(WhoIsPolicyholder?.birthday)
            : undefined,
          phone: !!WhoIsPolicyholder?.phone
            ? phoneDisplayValueCasting(WhoIsPolicyholder?.phone)
            : undefined,
          email: WhoIsPolicyholder?.email,
          address: WhoIsPolicyholder?.address?.value,
          passport: WhoIsPolicyholder?.passport,
          passportCode: !!WhoIsPolicyholder?.passportCode
            ? getFormattedPassportCode(WhoIsPolicyholder?.passportCode)
            : undefined,
          passportByWho: WhoIsPolicyholder?.passportByWho,
          passportDate: !!WhoIsPolicyholder?.passportDate
            ? getRuFormattedDate(WhoIsPolicyholder?.passportDate)
            : undefined,
        }),
        [WhoIsPolicyholder]
      );

      const hasAuthorized = WhoIsPolicyholder?.hasAuthorized;

      useEffect(() => {
        if (!isRegistration) {
          updateFormValue('PolicyholderData', { isValid: true });
        }
      }, [isRegistration]);

      if (!hasAuthorized || !isRegistration) {
        return null;
      }

      return (
        <Suspense
          fallback={<FallbackSkeleton height={isDesktop ? 260 : 370} />}
        >
          <PolicyholderData
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={disableForm}
            fieldState={fieldState}
          />
        </Suspense>
      );
    }
  )
);

PolicyholderDataAdapter.displayName = 'PolicyholderDataAdapter';
