import { FRANCHISE_TYPE } from '../../entities/adapters/footer-adapter/constants';

export const getFranchise = (franchise?: number) => {
  if (!franchise) {
    return null;
  }

  return {
    franchiseType: FRANCHISE_TYPE,
    franchiseValue: franchise,
  };
};
