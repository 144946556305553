import {
  AddressType,
  Endpoints,
  FiasLevel,
  QUERY_KEYS,
} from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { useGetDadataSuggestions } from '@shared/queries';
import { getFormattedPassport } from '@shared/utils';
import { useEffect } from 'react';

import type { UpdateProfileResponse } from '@shared/types';
import type { WhoIsPolicyholderValues } from '@smart-components/who-is-policyholder';

export const useHandleGetProfile = (
  updateFormValue: (fieldName: string, value: any) => void,
  // TODO: Убрать any когда у refetchRegistration появится возвращаемый тип
  refetchRegistration: () => Promise<any>,
  removeConfirmAuthentication: () => void
) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      initProductStore: { setInitFormState, initFormState },
      authStore: {
        handleAuth,
        handleOtp,
        handleProfile,
        setAuthTokens,
        accessToken,
        setDisableForm,
        setHasAuthorized,
        hasAuthorized,
        setRefetchAuthentication,
        handleUpdateDisableProfileState,
        setUseOtp,
        profile: storeProfile,
        setIsUserNotDefine,
        isFastAuthOpen,
      },
      productStore: {
        isPolicyBanned,
        formState: { WhoIsPolicyholder, PolicyholderForm },
      },
      applicationStore: { setIsRegistration },
    },
  } = useStores();

  const { isLoading, error, res, refetch, remove } =
    useRequest<UpdateProfileResponse>(
      QUERY_KEYS.getProfile,
      'post',
      Endpoints.GET_PROFILE,
      {},
      [],
      true,
      accessToken
    );

  const address = res?.profile?.passport?.addresses.find(
    ({ addressType }) => addressType === AddressType.REGISTRATION
  )?.address;

  const { data: dadataSuggestions } = useGetDadataSuggestions({
    count: 10,
    query: address ?? '',
  });

  useEffect(() => {
    if (res) {
      handleProfile(res);
      setErrorRetry(false);
      setDisableForm(false);

      const { profile, lockedFields } = res;

      handleUpdateDisableProfileState({
        name: !!profile?.firstName,
        surname: !!profile?.lastName,
        secondName: lockedFields?.middleName || !!profile?.middleName,
        birthDate: !!profile?.birthDate,
      });

      const {
        cardSeries,
        cardNumber,
        cardIssuerName,
        cardIssuerCode,
        cardIssueDate,
        addresses,
      } = profile?.passport ?? {};

      const registrationAddress = addresses?.find(
        ({ addressType }) => addressType === AddressType.REGISTRATION
      );

      const hasAddressCode = Boolean(registrationAddress?.addressCode);
      const isMe = profileDataIsFulfilled(profile);

      const newValue: Partial<WhoIsPolicyholderValues> = {
        name: profile?.firstName,
        surname: profile?.lastName,
        middlename: profile?.middleName,
        birthday: profile?.birthDate ? new Date(profile?.birthDate) : undefined,
        phone: profile?.phone.slice(2),
        email: profile?.email || WhoIsPolicyholder?.email || '',
        hasAuthorized: hasAuthorized,
        isValid: !!profile?.email && !!profile.birthDate,
        passport:
          cardSeries && cardNumber
            ? getFormattedPassport(`${cardSeries}${cardNumber}`)
            : WhoIsPolicyholder?.passport,
        passportByWho: cardIssuerName ?? WhoIsPolicyholder?.passportByWho,
        passportCode: cardIssuerCode ?? WhoIsPolicyholder?.passportCode,
        passportDate: cardIssueDate
          ? new Date(cardIssueDate)
          : WhoIsPolicyholder?.passportDate,
        address: {
          value: hasAddressCode
            ? registrationAddress?.address
            : WhoIsPolicyholder?.address?.value,
          fias_id: hasAddressCode
            ? registrationAddress?.addressCode
            : WhoIsPolicyholder?.address?.fias_id,
          fias_level: hasAddressCode
            ? FiasLevel.HOUSE
            : WhoIsPolicyholder?.address?.fias_level,
        },
      };

      updateFormValue('WhoIsPolicyholder', newValue);
      updateFormValue('PolicyholderForm', {
        birthday: newValue?.birthday,
        middlename: newValue?.middlename,
        name: newValue?.name,
        surname: newValue?.surname,
        passport: newValue?.passport,
        passportDate: newValue?.passportDate,
        passportByWho: newValue?.passportByWho,
        passportCode: newValue?.passportCode,
        address: newValue?.address,
        phone: newValue?.phone,
        email: newValue?.email,
        isValid: newValue?.isValid,
      });
      updateFormValue('WhoIsDriver', {
        forMe: isMe,
      });
      setInitFormState({
        ...initFormState,
        WhoIsPolicyholder: newValue,
      });
      setDisableForm(false);
    }
  }, [res]);

  useEffect(() => {
    const hasAccessToken = Boolean(accessToken);
    setHasAuthorized(hasAccessToken);

    if (accessToken && !storeProfile) {
      setDisableForm(true);
      refetch();
    }
  }, [accessToken, storeProfile]);

  useEffect(() => {
    if (isPolicyBanned) remove();
  }, [isPolicyBanned]);

  useEffect(() => {
    if (
      hasAuthorized &&
      error &&
      error?.response?.status === 403 &&
      error?.response?.data?.code === 'INVALID_ROLE'
    ) {
      localStorage.removeItem('csrfToken');

      if (isFastAuthOpen) {
        setHasAuthorized(false);
        setAuthTokens(undefined);
        removeConfirmAuthentication();
        setUseOtp(true);
        handleOtp('', false);
        handleAuth('');
        setRefetchAuthentication(true);

        setIsUserNotDefine(true);
      } else {
        setDisableForm(true);
        refetchRegistration().then(() => {
          setIsRegistration(true);
        });
      }
    }
  }, [hasAuthorized, error]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (dadataSuggestions) {
        const dadataValue = Array.isArray(dadataSuggestions?.suggestions)
          ? dadataSuggestions?.suggestions?.[0]
          : dadataSuggestions?.suggestions;

        const isAvailableFiasLevel =
          dadataValue?.data.fias_level === FiasLevel.APARTMENT ||
          dadataValue?.data.fias_level === FiasLevel.HOUSE;

        if (isAvailableFiasLevel) {
          updateFormValue('WhoIsPolicyholder', {
            ...WhoIsPolicyholder,
            address: {
              value: dadataValue.value,
              fias_id: dadataValue?.data?.fias_id,
              fias_level: dadataValue?.data?.fias_level,
            },
          });
          updateFormValue('PolicyholderForm', {
            ...PolicyholderForm,
            address: {
              value: dadataValue.value,
              fias_id: dadataValue?.data?.fias_id,
              fias_level: dadataValue?.data?.fias_level,
            },
          });
        }
      }
    }, 200); // TODO: Завести задачу на ресечь оптимизации, чтобы избавиться от setTimeout

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dadataSuggestions]);

  return {
    isLoadingGetProfile: isLoading,
    errorGetProfile: error,
    resGetProfile: res,
    refetchGetProfile: refetch,
  };
};

// Оформляю полис на себя включен, если у профайла заполнены все поля для заполнения путешественника.
const profileDataIsFulfilled = (
  profile: UpdateProfileResponse['profile']
): boolean => !!profile.firstName && !!profile.lastName && !!profile.birthDate;
