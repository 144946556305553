import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { CarOwner } from '@entities/import-smart-components/car-owner';
import { useStores } from '@shared/hooks';
import { CarOwnerAnalyticEvent } from '@smart-components/car-owner';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, type Ref, useMemo, useCallback } from 'react';

import type { AnalyticEventsMap } from '@shared/types';
import type {
  CarOwnerOptions,
  CarOwnerValues,
  SmartComponentAdapterProps,
} from '@smart-components/index';

const analyticEventsMap: AnalyticEventsMap<CarOwnerAnalyticEvent> = {
  [CarOwnerAnalyticEvent.ON_INSURANT_IS_OWNER]: {
    name: analyticEvents.insurantisOwner,
  },
  [CarOwnerAnalyticEvent.ON_DATA_SELECTED]: {
    name: analyticEvents.ownerDataSelected,
  },
};

export const CarOwnerAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<CarOwnerValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          productStore: {
            formState: {
              PolicyholderForm,
              WhoIsPolicyholder,
              CarOwner: CarOwnerData,
            },
          },
          applicationStore: {
            updateFormValue,
            isPolicyholderTheOwner,
            setIsPolicyholderTheOwner,
          },
        },
      } = useStores();

      const onIsPolicyholderTheOwnerChange = useCallback(
        (isPolicyholderTheOwner: boolean) => {
          if (isPolicyholderTheOwner) {
            updateFormValue('CarOwner', {
              ...CarOwnerData,
              birthday: PolicyholderForm?.birthday,
              middlename: PolicyholderForm?.middlename,
              name: PolicyholderForm?.name,
              surname: PolicyholderForm?.surname,
              passport: PolicyholderForm?.passport,
              passportDate: PolicyholderForm?.passportDate,
              passportByWho: PolicyholderForm?.passportByWho,
              passportCode: PolicyholderForm?.passportCode,
              address: PolicyholderForm?.address,
            });
          } else {
            updateFormValue('CarOwner', {
              ...CarOwnerData,
              birthday: null,
              middlename: '',
              name: '',
              surname: '',
              passport: '',
              passportDate: null,
              passportByWho: '',
              passportCode: '',
              address: { value: '' },
            });
          }
          setIsPolicyholderTheOwner(isPolicyholderTheOwner);
        },
        [
          isPolicyholderTheOwner,
          setIsPolicyholderTheOwner,
          CarOwnerData,
          PolicyholderForm,
        ]
      );

      const options: CarOwnerOptions = useMemo(
        () => ({
          isPolicyholderTheOwner,
          onIsPolicyholderTheOwnerChange,
        }),
        [isPolicyholderTheOwner, onIsPolicyholderTheOwnerChange]
      );

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<CarOwnerAnalyticEvent>(
          analyticEventsMap
        );

      const hasAuthorized = WhoIsPolicyholder?.hasAuthorized;

      if (!hasAuthorized) {
        return null;
      }

      return (
        <Suspense>
          <CarOwner
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            fieldState={fieldState}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

CarOwnerAdapter.displayName = 'CarOwnerAdapter';
