import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import type { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

export const ThemeInterceptor: FC = observer(() => {
  const [searchParams] = useSearchParams();
  const {
    MainStore: {
      themesStore: { setThemeUrl },
    },
  } = useStores();
  const themeUrl = searchParams.get('personalization') || undefined;

  useEffect(() => {
    if (themeUrl) {
      setThemeUrl(themeUrl);
    }
  }, []);

  return null;
});
