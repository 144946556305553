import { DOCUMENT_QUERY_KEY, QUERY_KEYS } from '@shared/constants';
import { useIsFetching } from 'react-query';

import { useStores } from './use-stores';

export const useGlobalFetching = () => {
  const {
    MainStore: {
      authStore: { isFastAuthOpen },
    },
  } = useStores();

  const countFetchings = useIsFetching({
    predicate: (query) => {
      const [key] = query.queryKey;

      if (isFastAuthOpen) {
        return key !== QUERY_KEYS.auth;
      }

      return (
        key !== QUERY_KEYS.getPrices &&
        key !== QUERY_KEYS.auth &&
        key !== QUERY_KEYS.confirmAuth &&
        !(key as string).includes(DOCUMENT_QUERY_KEY)
      );
    },
  });

  return Boolean(countFetchings);
};
