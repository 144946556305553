import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import { Suspense, useCallback } from 'react';
import { FallbackSkeleton } from '@shared/components';
import SmartHeader from '@smart-components/smart-header';

export const HeaderAdapter: FC = observer(() => {
  const {
    MainStore: {
      applicationStore: {
        activeStep,
        setActiveStep,
        deviceType,
        phoneFromMobileApp,
        setIsCarNumberSmartComponentShowing,
        flowConfig: { headerConfig, isShowBackButton },
      },
      authStore: { setDisableForm },
    },
  } = useStores();

  // добавить в shared функцию isLastStep()
  const isBackButtonVisible = !!(activeStep > 1 && isShowBackButton);

  const handleStepChange = useCallback((step: number) => {
    setDisableForm(false);
    setActiveStep(step + 1);
  }, []);

  const handleOnClickBack = useCallback(() => {
    setDisableForm(false);
    if (activeStep === 1) setIsCarNumberSmartComponentShowing(true);
    else setActiveStep(activeStep - 1);
  }, [activeStep]);

  return (
    <Suspense fallback={<FallbackSkeleton height={0} transparent />}>
      <SmartHeader
        config={headerConfig || []}
        activeStep={activeStep}
        handleStepChange={handleStepChange}
        handleOnClickBack={handleOnClickBack}
        isShowBackButton={isBackButtonVisible}
        isMobileApp={Boolean(phoneFromMobileApp && deviceType)}
      />
    </Suspense>
  );
});

HeaderAdapter.displayName = 'HeaderAdapter';
