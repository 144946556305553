import { useEffect, useRef } from 'react';
import type { FieldErrors } from 'react-hook-form';

export const useScrollToElementWithError = (
  submitCount: number,
  errors: FieldErrors
) => {
  const elementRefs = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    const errorsInSmartComponent = Object.keys(errors);
    const [firstSmartComponentHasError] = errorsInSmartComponent;

    if (firstSmartComponentHasError) {
      elementRefs.current?.[firstSmartComponentHasError]?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [submitCount]);

  return elementRefs;
};
