import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { WhoIsPolicyholder } from '@entities/import-smart-components/who-is-policyholder';
import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import { WhoIsPolicyholderAnalyticEvent } from '@smart-components/who-is-policyholder/constants';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useEffect, useMemo } from 'react';

import type { AnalyticEventsMap } from '@shared/types';
import type {
  SmartComponentAdapterProps,
  WhoIsPolicyholderValues,
  WhoIsPolicyholderOptions,
} from '@smart-components/index';
import type { Ref } from 'react';

const analyticEventsMap: AnalyticEventsMap<WhoIsPolicyholderAnalyticEvent> = {
  [WhoIsPolicyholderAnalyticEvent.ON_DATA_SELECTED]: {
    name: analyticEvents.insurantDataSelected,
  },
  [WhoIsPolicyholderAnalyticEvent.ON_PHONE_ENTERED]: {
    name: analyticEvents.insurantPhoneEntered,
  },
  [WhoIsPolicyholderAnalyticEvent.ON_MAIL_ENTERED]: {
    name: analyticEvents.insurantMailEntered,
  },
};

export const WhoIsPolicyholderAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<WhoIsPolicyholderValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: { deviceType, phoneFromMobileApp },
          authStore: {
            disableProfileState,
            disableForm,
            otpErrorCode,
            setOtpErrorCode,
            handleAuth,
            handleOtp,
            setRefetchAuthentication,
            isUserNotDefine,
            setIsUserNotDefine,
            setIsFastAuthOpen,
            profile,
            setUseOtp,
          },
          productStore: { agentLogin },
        },
      } = useStores();

      useEffect(() => {
        if (!profile) setUseOtp(true);
      }, [profile]);

      const options: WhoIsPolicyholderOptions = useMemo(
        () => ({
          deviceType,
          phoneFromMobileApp,
          agentLogin,
          disableProfileState,
          isOtpError: otpErrorCode,
          handleAuth,
          handleOtp,
          setRefetchAuthentication,
          isUserNotDefine,
          setIsUserNotDefine,
          setIsFastAuthOpen,
          setOtpErrorCode,
        }),
        [
          deviceType,
          phoneFromMobileApp,
          agentLogin,
          otpErrorCode,
          handleAuth,
          handleOtp,
          setRefetchAuthentication,
          isUserNotDefine,
          setIsUserNotDefine,
          setIsFastAuthOpen,
          setOtpErrorCode,
        ]
      );

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<WhoIsPolicyholderAnalyticEvent>(
          analyticEventsMap
        );

      return (
        <Suspense fallback={<FallbackSkeleton height={340} />}>
          <WhoIsPolicyholder
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            fieldState={fieldState}
            disabled={disableForm}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

WhoIsPolicyholderAdapter.displayName = 'WhoIsPolicyholderAdapter';
