import { styled, css } from '@pulse-web-ui/theme';

export const FormBuilderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 768px;
  box-sizing: border-box;
`;

export const FormBuilderScrollWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  row-gap: 16px;
  flex-direction: column;
  padding-bottom: 16px;
`;

export const FormBuilderComponentsWrapper = styled.div<{
  isBackButtonVisible: boolean;
}>`
  display: flex;
  row-gap: 16px;
  flex-direction: column;

  ${({ isBackButtonVisible }) =>
    isBackButtonVisible &&
    css`
      margin-top: -40px;
    `}
`;

export const FormBuilderModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
