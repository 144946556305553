/* eslint-disable */
import { media, styled } from '@pulse-web-ui/theme';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: flex-start;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  ${media.desktop} {
    row-gap: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.desktop} {
    flex-direction: row;
  }
`;

export const RowSection = styled.div<{ isHalf?: boolean }>`
  display: flex;
  width: 100%;

  ${media.desktop} {
    flex: ${(props) => (props.isHalf ? '0 0 calc(50% - 8px)' : 1)};
  }
  & > * {
    width: 100%;
  }
`;
