export const i18nDefaultValues = {
  InsuranceRisks: {
    title: 'Страховые риски',
    labels: {
      damage: 'Хищение и ущерб',
      guarantee: 'Гарантия стоимости',
    },
    descriptions: {
      damage:
        'Хищение — незаконное завладение застрахованным транспортным средством третьими лицами.<br/><br/> Ущерб может быть причинён следующими рисками: ДТП, стихийные бедствия, падение предметов, пожар, провал под грунт, техногенная авария, противоправные действия третьих лиц, действия животных.',
      guarantee:
        'GAP (Guaranteed Asset Protection) страхование переводится как «гарантия сохранения стоимости» – полис страхования, позволяющий получить полную выплату по КАСКО без учета амортизационного износа до действительной или рыночной стоимости, в зависимости от комплектации и года выпуска автомобиля.',
    },
  },
};
