import type { FC, ReactNode } from 'react';
import type { SmartComponentAdapterProps } from '@smart-components/shared/types';

import {
  B2PAdapter,
  CarAdapter,
  CarNumberAdapter,
  DriversAdapter,
  FooterAdapter,
  InsuranceParametersAdapter,
  InsuranceRisksAdapter,
  PolicyholderDataAdapter,
  PolicyholderFormAdapter,
  HeaderAdapter,
  SubmitAdapter,
  VehicleDocumentAdapter,
  CheckPolicyAdapter,
  WhoIsDriverAdapter,
  WhoIsPolicyholderAdapter,
  CarOwnerAdapter,
  withCommonSmartComponentAdapter,
} from '@entities/adapters';
import { MockSmartComponent } from './mock-smart-component';

type TypeImportSmartComponents = {
  [key: string]: (props: SmartComponentAdapterProps<any>) => ReactNode;
};

export const importSmartComponents: TypeImportSmartComponents = {
  // Step 1 - calculation:
  CarNumber: CarNumberAdapter,
  Car: CarAdapter,
  Drivers: DriversAdapter,

  // Step 2 - options:
  InsuranceParameters: InsuranceParametersAdapter,

  // Step 3 - formalization:
  InsuranceRisks: InsuranceRisksAdapter,

  // Step 4 - clients:
  WhoIsPolicyholder: WhoIsPolicyholderAdapter,
  PolicyholderData: PolicyholderDataAdapter,
  PolicyholderForm: PolicyholderFormAdapter,
  CarOwner: CarOwnerAdapter,
  VehicleDocument: VehicleDocumentAdapter,
  WhoIsDriver: WhoIsDriverAdapter,

  // Step 5 - payment:
  CheckPolicy: CheckPolicyAdapter,

  //TODO: Удалить any, когда поправим типизацию в smart-components-kit
  MockSmartComponent: withCommonSmartComponentAdapter(
    MockSmartComponent as any
  ),
  B2P: B2PAdapter,
};

type TypeImportCommonComponents = {
  [key: string]: FC<any>;
};

export const importCommonComponents: TypeImportCommonComponents = {
  Header: HeaderAdapter,
  Footer: FooterAdapter,
  Submit: SubmitAdapter,
};
