import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { InsuranceRisks } from '@entities/import-smart-components/insurance-risks';
import { FallbackSkeleton } from '@shared/components';
import { useHandleErrorRequest } from '@shared/hooks';
import { useGetRisks } from '@shared/queries';
import { InsuranceRisksAnalyticEvent } from '@smart-components/insurance-risks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, type Ref, useEffect } from 'react';

import type { AnalyticEventsMap } from '@shared/types';
import type {
  InsuranceRisksValues,
  SmartComponentAdapterProps,
} from '@smart-components/index';

const analyticEventsMap: AnalyticEventsMap<InsuranceRisksAnalyticEvent> = {
  [InsuranceRisksAnalyticEvent.ON_RISK_GAP_SELECTED]: {
    name: analyticEvents.riskGapSelected,
  },
};

export const InsuranceRisksAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<InsuranceRisksValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { data, isLoading, status, refetch, error } = useGetRisks();

      useHandleErrorRequest({
        status,
        refetch,
        error,
        isLoading,
      });

      useEffect(() => {
        if (!isLoading && data) {
          onChange({
            ...value,
            insuranceRisks: data.risks,
          });
        }
      }, [isLoading, data]);

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<InsuranceRisksAnalyticEvent>(
          analyticEventsMap
        );

      if (!data?.risks?.length) {
        return <FallbackSkeleton height={274} />;
      }

      return (
        <Suspense fallback={<FallbackSkeleton height={274} />}>
          <InsuranceRisks
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            fieldState={fieldState}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

InsuranceRisksAdapter.displayName = 'InsuranceRisksAdapter';
