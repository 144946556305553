/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';
import { DEFAULT_OPTION } from '@shared/constants';
import { useHandleManualInput, useStores } from '@shared/hooks';
import { useFetchCarData } from '@shared/queries';
import { useCallback, useEffect } from 'react';
import { useQueryErrorResetBoundary } from 'react-query';

export const useHandleCarNumber = (carNumber?: string) => {
  const {
    MainStore: {
      initProductStore: {
        initState: { code, minProductLimit },
      },
      applicationStore: {
        wantFindCar,
        setWantFindCar,
        setIsManualCarInput,
        setIsCarNumberSmartComponentShowing,
        updateFormValue,
        setHasUnknownCarNumberError,
        incrementCarSearchAttemptsCount,
      },
      productStore: {
        formState: { Car },
        setCar,
      },
    },
  } = useStores();

  const enabled = Boolean(code && carNumber && wantFindCar);
  const carNumberWithoutMask = carNumber?.replaceAll(/[_\s]/g, '');

  const { refetch, data, error } = useFetchCarData(
    code,
    carNumberWithoutMask,
    enabled
  );

  const handleFindAuto = useCallback(() => {
    setWantFindCar(true);
    incrementCarSearchAttemptsCount();
  }, []);

  useEffect(() => {
    if (!wantFindCar) return;

    if (!data) return;

    const isFoundAllCarData = Boolean(
      data?.makeId && data?.modelId && data?.year && data?.power
    );

    if (!isFoundAllCarData) setIsManualCarInput(true);

    setCar(data);

    const event = isFoundAllCarData
      ? analyticEvents.regNumberFound
      : analyticEvents.regNumberNotAllDataFound;

    sendAnalyticEvent(event);

    updateFormValue('Car', {
      ...Car,
      carNumber,
      vin: data.vin,
      marketPrice:
        data?.price?.price && isFoundAllCarData
          ? data?.price?.price
          : minProductLimit,
      car: {
        model: data.makeId
          ? { label: data?.model, value: data?.modelId }
          : DEFAULT_OPTION,
        power:
          data.makeId && data.modelId && data.power
            ? {
                label: data?.power?.toString(),
                value: data?.power?.toString(),
              }
            : DEFAULT_OPTION,
        brand: { label: data?.make, value: data?.makeId },
        manufactureYear:
          data.makeId && data.modelId
            ? {
                label: data?.year?.toString(),
                value: data?.year?.toString(),
              }
            : DEFAULT_OPTION,
      },
    });

    setWantFindCar(false);
    setIsCarNumberSmartComponentShowing(false);
  }, [wantFindCar, data]);

  const handleManualInput = useHandleManualInput(carNumber);
  const { reset } = useQueryErrorResetBoundary();

  useEffect(() => {
    if (wantFindCar && error) {
      handleManualInput();
      setHasUnknownCarNumberError(true);
      reset();
      setWantFindCar(false);
    }
  }, [error]);

  return { handleFindAuto, refetch };
};
