import { Input } from '@pulse-web-ui/input';
import { useTranslation } from 'react-i18next';

import type { Props } from './types';

import { i18nDefaultValues } from '../../i18n';

export const InputPhone = ({ value, disabled, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <Input
      type="tel"
      label={t('SMART:Submit.labels.phone', {
        defaultValue: i18nDefaultValues.Submit.labels.phone,
      })}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
