import { PolicyholderForm } from '@entities/import-smart-components/policyholder-form';
import { FallbackSkeleton } from '@shared/components';
import { AddressType, FiasLevel } from '@shared/constants';
import { useIsDesktop, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import {
  Suspense,
  forwardRef,
  useEffect,
  useMemo,
  useState,
  type Ref,
} from 'react';

import type {
  SmartComponentAdapterProps,
  PolicyholderFormValues,
  PolicyholderFormDisabledFields,
  PolicyholderFormOptions,
} from '@smart-components/index';

export const PolicyholderFormAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<PolicyholderFormValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const isDesktop = useIsDesktop();
      const {
        MainStore: {
          authStore: { disableForm, profile: storeProfile },
          productStore: {
            formState: { WhoIsPolicyholder, CarOwner },
            agentLogin,
          },
          applicationStore: {
            updateFormValue,
            isPolicyholderTheOwner,
            isRegistration,
          },
        },
      } = useStores();

      const profile = storeProfile?.profile;
      const {
        cardSeries: profileCardSeries,
        cardNumber: profileCardNumber,
        cardIssuerName: profileCardIssuerName,
        cardIssuerCode: profileCardIssuerCode,
        cardIssueDate: profileCardIssueDate,
        addresses,
      } = profile?.passport ?? {};

      const registrationAddress = addresses?.find(
        ({ addressType }) => addressType === AddressType.REGISTRATION
      );

      const hasAddressCodeInProfile = Boolean(registrationAddress?.addressCode);

      const [disabledFields, setDisabledFields] =
        useState<PolicyholderFormDisabledFields>(() => ({
          birthday: false,
          middlename: false,
          name: false,
          surname: false,
          passport: false,
          passportDate: false,
          passportByWho: false,
          passportCode: false,
          address: false,
          phone: false,
          email: false,
        }));

      const hasAuthorized = WhoIsPolicyholder?.hasAuthorized;

      const fiasLevel = WhoIsPolicyholder?.address?.fias_level;

      const isAvailableFiasLevel =
        fiasLevel === FiasLevel.HOUSE || fiasLevel === FiasLevel.APARTMENT;

      useEffect(() => {
        if (hasAuthorized) {
          setDisabledFields({
            name: !!profile?.firstName,
            surname: !!profile?.lastName,
            phone: !!profile?.phone,
            birthday: !!profile?.birthDate,
            middlename: !!profile?.middleName,
            passport: !!profileCardSeries && !!profileCardNumber,
            passportDate: !!profileCardIssueDate,
            passportByWho: !!profileCardIssuerName,
            passportCode: !!profileCardIssuerCode,
            address: hasAddressCodeInProfile && isAvailableFiasLevel,
            email: false,
          });
        }
      }, [
        hasAuthorized,
        isAvailableFiasLevel,
        hasAddressCodeInProfile,
        profile?.firstName,
        profile?.lastName,
        profile?.phone,
        profile?.birthDate,
        profile?.middleName,
        profileCardSeries,
        profileCardNumber,
        profileCardIssueDate,
        profileCardIssuerName,
        profileCardIssuerCode,
      ]);

      useEffect(() => {
        const hasAddress = WhoIsPolicyholder?.address?.fias_id;
        if (hasAuthorized && hasAddress && hasAddressCodeInProfile) {
          setDisabledFields((prev) => ({
            ...prev,
            address: true,
          }));
        }
      }, [
        hasAuthorized,
        hasAddressCodeInProfile,
        WhoIsPolicyholder?.address?.value,
        WhoIsPolicyholder?.address?.fias_id,
        WhoIsPolicyholder?.address?.fias_level,
      ]);

      useEffect(() => {
        if (isPolicyholderTheOwner) {
          // Изменение формы CarOwner если выбрано "Страхователь является владельцем"
          updateFormValue('CarOwner', {
            ...CarOwner,
            birthday: value?.birthday,
            middlename: value?.middlename,
            name: value?.name,
            surname: value?.surname,
            passport: value?.passport,
            passportDate: value?.passportDate,
            passportByWho: value?.passportByWho,
            passportCode: value?.passportCode,
            address: value?.address,
          });
        }
      }, [
        value?.birthday?.toString(),
        value?.middlename,
        value?.name,
        value?.surname,
        value?.passport,
        value?.passportDate?.toString(),
        value?.passportByWho,
        value?.passportCode,
        value?.address?.value,
        value?.address?.fias_id,
        value?.address?.fias_level,
      ]);

      useEffect(() => {
        if (isRegistration) {
          updateFormValue('PolicyholderForm', { ...value, isValid: true });
        }
      }, [isRegistration]);

      const options: PolicyholderFormOptions = useMemo(
        () => ({ agentLogin, disabledFields }),
        [agentLogin, disabledFields]
      );

      if (!hasAuthorized || isRegistration) {
        return null;
      }
      return (
        <Suspense
          fallback={<FallbackSkeleton height={isDesktop ? 640 : 920} />}
        >
          <PolicyholderForm
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={disableForm}
            fieldState={fieldState}
          />
        </Suspense>
      );
    }
  )
);

PolicyholderFormAdapter.displayName = 'PolicyholderFormAdapter';
