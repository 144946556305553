import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

interface Params {
  code?: string;
  brandId?: string;
  modelId?: string;
  yearId?: string;
  powerId?: string;
  region?: string;
}

interface CarPriceResponse {
  requestId: string;
  maxPrice?: number;
  minPrice?: number;
  price?: number;
}

export const useGetCarPrice = ({
  code,
  brandId,
  modelId,
  yearId,
  powerId,
  region,
}: Params) => {
  return useQuery(
    ['get-car-price', code, brandId, modelId, yearId, powerId, region],
    fetchData<CarPriceResponse>({
      url: `${Endpoints.GET_BRANDS}/${brandId}/models/${modelId}/prices?region=${region}&year=${yearId}&power=${powerId}&productVersion=${code}`,
      method: 'GET',
    }),
    {
      enabled: false,
    }
  );
};
