export const MIN_MILEAGE = 1;
export const MAX_MILEAGE = 100_000_000;
export const MIN_LENGTH_VIN = 17;
export const ONLY_NUMBERS_REG_EXP = /^\d+$/;

export enum CarAnalyticEvent {
  ON_BRAND_SELECTED,
  ON_MODEL_SELECTED,
  ON_YEAR_SELECTED,
  ON_POWER_SELECTED,
  ON_SUM_SELECTED,
  ON_VALID_KASKO,
  ON_KREDIT_AVTO,
  ON_MILEAGE_SELECTED,
  ON_VIN_SELECTED,
  ON_CHANGE_CAR_DATA,
  ON_CHANGE_CAR_DATA_ACCEPTED,
  ON_REG_NUMBER_SELECTED,
  ON_REG_NUMBER_SEARCH,
  ON_REG_NUMBER_NOT_FOUND,
}
