import { media, styled, typography } from '@pulse-web-ui/theme';
import { Button } from '@pulse-web-ui/button';

export const StyledBackButton = styled(Button)`
  ${typography.regular16}
  line-height: 24px;
  position: relative;
  z-index: 1;
`;

export const StyledContainer = styled.div`
  display: flex;
  padding: 20px 16px;
  border-radius: 24px 24px 0 0;
  background: ${({ theme }) => theme.colors.surface.primary};

  ${media.desktop} {
    padding: 24px 24px 16px;
  }
`;
