import { lazy } from 'react';
import { i18nDefaultValues } from './i18n';

export const getDefaultValue = (name: string) =>
  i18nDefaultValues?.SmartHeader?.labels?.[
    name as keyof typeof i18nDefaultValues.SmartHeader.labels
  ] || '';

export const lazyIcons = {
  Fire: lazy(() => import('@pulse-web-ui/icons/lib/lazy/fire')),
  Water: lazy(() => import('@pulse-web-ui/icons/lib/lazy/water')),
  Intruder: lazy(() => import('@pulse-web-ui/icons/lib/lazy/intruder')),
  Neighbors: lazy(() => import('@pulse-web-ui/icons/lib/lazy/neighbors')),
  Tornado: lazy(() => import('@pulse-web-ui/icons/lib/lazy/tornado')),
  TvHighVoltage: lazy(
    () => import('@pulse-web-ui/icons/lib/lazy/tv-high-voltage')
  ),
  Football: lazy(() => import('@pulse-web-ui/icons/lib/lazy/football')),
  Wallet: lazy(() => import('@pulse-web-ui/icons/lib/lazy/wallet')),
  Bandage: lazy(() => import('@pulse-web-ui/icons/lib/lazy/bandage')),
  BrokenFoot: lazy(() => import('@pulse-web-ui/icons/lib/lazy/broken-foot')),
  AccessibleForward: lazy(
    () => import('@pulse-web-ui/icons/lib/lazy/accessible-forward')
  ),
  Xray: lazy(() => import('@pulse-web-ui/icons/lib/lazy/xray')),
  Doctor: lazy(() => import('@pulse-web-ui/icons/lib/lazy/doctor')),
  MedicalHelp: lazy(() => import('@pulse-web-ui/icons/lib/lazy/medical-help')),
  Pills: lazy(() => import('@pulse-web-ui/icons/lib/lazy/pills')),
  Bicycle: lazy(() => import('@pulse-web-ui/icons/lib/lazy/bicycle')),
  Telemedicine: lazy(() => import('@pulse-web-ui/icons/lib/lazy/telemedicine')),
  Door: lazy(() => import('@pulse-web-ui/icons/lib/lazy/door')),
  Bedroom: lazy(() => import('@pulse-web-ui/icons/lib/lazy/bedroom')),
  KeyFill: lazy(() => import('@pulse-web-ui/icons/lib/lazy/key-fill')),
  House: lazy(() => import('@pulse-web-ui/icons/lib/lazy/house')),
  Build: lazy(() => import('@pulse-web-ui/icons/lib/lazy/build')),
  Chair: lazy(() => import('@pulse-web-ui/icons/lib/lazy/chair')),
  Growth: lazy(() => import('@pulse-web-ui/icons/lib/lazy/growth')),
  Star: lazy(() => import('@pulse-web-ui/icons/lib/lazy/star')),
  Check: lazy(() => import('@pulse-web-ui/icons/lib/lazy/check')),
  Dacha: lazy(() => import('@pulse-web-ui/icons/lib/lazy/dacha')),
  Nature: lazy(() => import('@pulse-web-ui/icons/lib/lazy/nature')),
  Hike: lazy(() => import('@pulse-web-ui/icons/lib/lazy/hike')),
  Card: lazy(() => import('@pulse-web-ui/icons/lib/lazy/card')),
  Income: lazy(() => import('@pulse-web-ui/icons/lib/lazy/income')),
  Click: lazy(() => import('@pulse-web-ui/icons/lib/lazy/click')),
  Pin: lazy(() => import('@pulse-web-ui/icons/lib/lazy/pin')),
  CheckFull: lazy(() => import('@pulse-web-ui/icons/lib/lazy/check-full')),
  Help: lazy(() => import('@pulse-web-ui/icons/lib/lazy/help')),
  Attach: lazy(() => import('@pulse-web-ui/icons/lib/lazy/attach')),
  Download: lazy(() => import('@pulse-web-ui/icons/lib/lazy/download')),
  Edit: lazy(() => import('@pulse-web-ui/icons/lib/lazy/edit')),
  Link: lazy(() => import('@pulse-web-ui/icons/lib/lazy/link')),
  SystemCheck: lazy(() => import('@pulse-web-ui/icons/lib/lazy/system-check')),
  Loading: lazy(() => import('@pulse-web-ui/icons/lib/lazy/loading')),
  Document: lazy(() => import('@pulse-web-ui/icons/lib/lazy/document')),
  Attention: lazy(() => import('@pulse-web-ui/icons/lib/lazy/attention')),
  Play: lazy(() => import('@pulse-web-ui/icons/lib/lazy/play')),
  More: lazy(() => import('@pulse-web-ui/icons/lib/lazy/more')),
  Info: lazy(() => import('@pulse-web-ui/icons/lib/lazy/info')),
  SystemHelp: lazy(() => import('@pulse-web-ui/icons/lib/lazy/system-help')),
  Calendar: lazy(() => import('@pulse-web-ui/icons/lib/lazy/calendar')),
  Plus: lazy(() => import('@pulse-web-ui/icons/lib/lazy/plus')),
  Minus: lazy(() => import('@pulse-web-ui/icons/lib/lazy/minus')),
  ArrowUp: lazy(() => import('@pulse-web-ui/icons/lib/lazy/arrow-up')),
  ArrowRight: lazy(() => import('@pulse-web-ui/icons/lib/lazy/arrow-right')),
  ArrowDown: lazy(() => import('@pulse-web-ui/icons/lib/lazy/arrow-down')),
  ArrowLeft: lazy(() => import('@pulse-web-ui/icons/lib/lazy/arrow-left')),
  Close: lazy(() => import('@pulse-web-ui/icons/lib/lazy/close')),
  Clear: lazy(() => import('@pulse-web-ui/icons/lib/lazy/clear')),
  Add: lazy(() => import('@pulse-web-ui/icons/lib/lazy/add')),
  ArrowDownFull: lazy(
    () => import('@pulse-web-ui/icons/lib/lazy/arrow-down-full')
  ),
  Telegram: lazy(() => import('@pulse-web-ui/icons/lib/lazy/telegram')),
  Whatsapp: lazy(() => import('@pulse-web-ui/icons/lib/lazy/whatsapp')),
  Viber: lazy(() => import('@pulse-web-ui/icons/lib/lazy/viber')),
  Facebook: lazy(() => import('@pulse-web-ui/icons/lib/lazy/facebook')),
  Instagram: lazy(() => import('@pulse-web-ui/icons/lib/lazy/instagram')),
  Vk: lazy(() => import('@pulse-web-ui/icons/lib/lazy/vk')),
  GooglePlay: lazy(() => import('@pulse-web-ui/icons/lib/lazy/google-play')),
  AppStore: lazy(() => import('@pulse-web-ui/icons/lib/lazy/app-store')),
  Huawei: lazy(() => import('@pulse-web-ui/icons/lib/lazy/huawei')),
  Lightning: lazy(() => import('@pulse-web-ui/icons/lib/lazy/lightning')),
  Eye: lazy(() => import('@pulse-web-ui/icons/lib/lazy/eye')),
  ProductHome: lazy(() => import('@pulse-web-ui/icons/lib/lazy/product-home')),
  ProductHealth: lazy(
    () => import('@pulse-web-ui/icons/lib/lazy/product-health')
  ),
  Settings: lazy(() => import('@pulse-web-ui/icons/lib/lazy/settings')),
  SettingsVertically: lazy(
    () => import('@pulse-web-ui/icons/lib/lazy/settings-vertically')
  ),
  Phone: lazy(() => import('@pulse-web-ui/icons/lib/lazy/phone')),
  PhoneClassic: lazy(
    () => import('@pulse-web-ui/icons/lib/lazy/phone-classic')
  ),
  Location: lazy(() => import('@pulse-web-ui/icons/lib/lazy/location')),
  Time: lazy(() => import('@pulse-web-ui/icons/lib/lazy/time')),
  Timer: lazy(() => import('@pulse-web-ui/icons/lib/lazy/timer')),
  Matrix: lazy(() => import('@pulse-web-ui/icons/lib/lazy/matrix')),
  Group: lazy(() => import('@pulse-web-ui/icons/lib/lazy/group')),
  PillsFull: lazy(() => import('@pulse-web-ui/icons/lib/lazy/pills-full')),
  Peoples: lazy(() => import('@pulse-web-ui/icons/lib/lazy/peoples')),
  MoneyFull: lazy(() => import('@pulse-web-ui/icons/lib/lazy/money-full')),
  CalendarFull: lazy(
    () => import('@pulse-web-ui/icons/lib/lazy/calendar-full')
  ),
  Value: lazy(() => import('@pulse-web-ui/icons/lib/lazy/value')),
  Animal: lazy(() => import('@pulse-web-ui/icons/lib/lazy/animal')),
  CardFull: lazy(() => import('@pulse-web-ui/icons/lib/lazy/card-full')),
  Policy: lazy(() => import('@pulse-web-ui/icons/lib/lazy/policy')),
  DocumentFull: lazy(
    () => import('@pulse-web-ui/icons/lib/lazy/document-full')
  ),
  Lock: lazy(() => import('@pulse-web-ui/icons/lib/lazy/lock')),
  DocumentTransparent: lazy(
    () => import('@pulse-web-ui/icons/lib/lazy/document-transparent')
  ),
  Map: lazy(() => import('@pulse-web-ui/icons/lib/lazy/map')),
  Coins: lazy(() => import('@pulse-web-ui/icons/lib/lazy/coins')),
  CheckSmall: lazy(() => import('@pulse-web-ui/icons/lib/lazy/check-small')),
  Person: lazy(() => import('@pulse-web-ui/icons/lib/lazy/person')),
  Burger: lazy(() => import('@pulse-web-ui/icons/lib/lazy/burger')),
  Act: lazy(() => import('@pulse-web-ui/icons/lib/lazy/act')),
  Pulse: lazy(() => import('@pulse-web-ui/icons/lib/lazy/pulse')),
  PhoneTransparent: lazy(
    () => import('@pulse-web-ui/icons/lib/lazy/phone-transparent')
  ),
  Exit: lazy(() => import('@pulse-web-ui/icons/lib/lazy/exit')),
  World: lazy(() => import('@pulse-web-ui/icons/lib/lazy/world')),
  Flash: lazy(() => import('@pulse-web-ui/icons/lib/lazy/flash')),
  EyeTransparent: lazy(
    () => import('@pulse-web-ui/icons/lib/lazy/eye-transparent')
  ),
  CrossedEye: lazy(() => import('@pulse-web-ui/icons/lib/lazy/crossed-eye')),
  Magnifier: lazy(() => import('@pulse-web-ui/icons/lib/lazy/magnifier')),
  Filters: lazy(() => import('@pulse-web-ui/icons/lib/lazy/filters')),
  Sorting: lazy(() => import('@pulse-web-ui/icons/lib/lazy/sorting')),
  CloseLight: lazy(() => import('@pulse-web-ui/icons/lib/lazy/close-light')),
  Back: lazy(() => import('@pulse-web-ui/icons/lib/lazy/back')),
  Age: lazy(() => import('@pulse-web-ui/icons/lib/lazy/age')),
  Users: lazy(() => import('@pulse-web-ui/icons/lib/lazy/users')),
  Home: lazy(() => import('@pulse-web-ui/icons/lib/lazy/home')),
  Message: lazy(() => import('@pulse-web-ui/icons/lib/lazy/message')),
  Store: lazy(() => import('@pulse-web-ui/icons/lib/lazy/store')),
  Car: lazy(() => import('@pulse-web-ui/icons/lib/lazy/car')),
  Options: lazy(() => import('@pulse-web-ui/icons/lib/lazy/options')),
  Protect: lazy(() => import('@pulse-web-ui/icons/lib/lazy/protect')),
};
