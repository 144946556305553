import { useHandleErrorRequest } from '@shared/hooks';
import { observer } from 'mobx-react-lite';

import {
  useHandleQueries,
  useHandleMobileParams,
  useHandleSessionRecovery,
  useDeveloperMode,
} from './hooks';
import { useHandleProduct } from './queries';

export const InitBox = observer(() => {
  useDeveloperMode();

  useHandleQueries();
  useHandleMobileParams(); // TODO: Нужен ли нам это хук

  //Запрос на инициализацию продукта
  const { status, refetch, error, isLoading } = useHandleProduct();
  useHandleErrorRequest({ status, refetch, error, isLoading });

  //TODO: Добавить лоадер при восстановлении ссесси, в рамках задачи [WEB] Добавление лоадеров на шаги и компоненты (https://pulse-insure.atlassian.net/browse/PROD-11571)
  useHandleSessionRecovery();

  return null;
});
