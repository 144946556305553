import { styled, media } from '@pulse-web-ui/theme';

export const Form = styled.div``;

export const StyledIframe = styled.iframe`
  width: 100%;
  max-height: 820px;
  min-height: 820px;

  ${media.desktop} {
    height: 100%;
    top: 0;
  }
`;

export const B2pWrapper = styled.div`
  background: #fff;
  padding: 20px 16px;
  border-radius: 24px;

  ${media.desktop} {
    padding: 24px;
  }
`;
