export const i18nDefaultValues = {
  VehicleDocument: {
    title: 'Документ ТС',
    labels: {
      document: 'Документ ТС',
      documentNumber: 'Номер {{document}}',
      documentSerieAndNumber: 'Серия и номер {{document}}',
      documentDate: 'Дата выдачи {{document}}',
    },
    errors: {
      notFilled: 'Укажите данные документа ТC',
      invalidSerieOrNumber: 'Неверные номер или серия документа',
      invalidNumber: 'Неверный номер документа',
      documentMinDate:
        'Дата выдачи документа не может быть меньше года выпуска ТС',
      documentMaxDate: 'Дата выдачи документа не может быть в будущем',
      EPTSminDate: 'Дата выдачи ЭПТС не может быть ранее 01.01.2018',
    },
  },
};
