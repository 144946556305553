import { array, number, object, string } from 'yup';
import { REG_EXP_DATE } from './constants';

const risk = object({
  code: string().required(),
  amount: number().required(),
});

const franchise = object({
  franchiseType: string().required(),
  franchiseValue: number().required(),
}).nullable();

const insuranceObject = object({
  makeId: string().required(),
  make: string().required(),
  modelId: string(),
  model: string(),
  year: number().required(),
  enginePower: number(),
  region: string().required(),
  price: number().required(),
  stoType: string().required(),
  franchise: franchise,
});

export const requestBody = object({
  productVersion: string().required(),
  dateBegin: string().matches(REG_EXP_DATE).required(),
  contractDuration: string().required(),
  insuranceObjects: array(insuranceObject).min(1),
  minDriverAge: number().required(),
  minDriverExperience: number().required(),
  risks: array(risk).min(1),
  promoCode: string(),
});
