import { WidgetContainer } from '@pulse-web-ui/containers';
import { styled, typography } from '@pulse-web-ui/theme';

export const StyledWidgetContainer = styled(WidgetContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  ${typography.medium24}
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 8px;
`;

export const SubTitle = styled.span`
  ${typography.regular16}
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const SpinnerWrapper = styled.div`
  margin-bottom: 34px;
`;
