import { PlatformType } from '@shared/constants';
import { default as Axios } from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const axiosInstance = Axios.create({
  withCredentials: true,
  baseURL: window.envUrls.BASEURL,
  headers: {
    'X-Process-ID': uuidv4(),
    'Content-type': 'application/json',
    'X-Platform-Type': PlatformType,
  },
});
