import { media } from '@pulse-web-ui/theme';
import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  ${media.desktop} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const FlexItem = styled.div`
  width: 100%;

  ${media.desktop} {
    width: calc(50% - 8px);
  }
`;
