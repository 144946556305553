/* eslint-disable indent */
import i18n from '@app/i18n/config';

import type { PolicyholderValues } from '../types';

import { i18nDefaultValues } from '../i18n';

export const getPolicyholderLabel = (name: keyof PolicyholderValues) =>
  i18n.t(`SMART:Policyholder.label.${name}`, {
    defaultValue: i18nDefaultValues.Policyholder.labels[name],
  });
