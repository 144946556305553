import { v4 as uuid } from 'uuid';

import type { WhoIsDriverValues } from '../types';

import { DEFAULT_DRIVER } from '../constants';

export const getDefaultValues = (
  agesLength: number,
  value?: WhoIsDriverValues
) => {
  const driversLength = value?.drivers?.length ?? 0;

  if (driversLength >= agesLength) {
    return value;
  }

  const newDrivers = Array(agesLength - driversLength).fill({
    ...DEFAULT_DRIVER,
    id: uuid(),
  });

  return {
    ...value,
    drivers: [...(value?.drivers ?? []), ...newDrivers],
  };
};
