import { useStores } from '@shared/hooks';
import { declineWord, formattingPeriod, formattingPrice } from '@shared/utils';
import { formattingFranchise, getMinAge, getMinExperienceAge } from '../utils';
import i18n from '@app/i18n/config';
import { useMemo } from 'react';

const DECLINATION = {
  one: i18n.t('COMMON:declensions.year-1'),
  few: i18n.t('COMMON:declensions.year-2'),
  many: i18n.t('COMMON:declensions.year-3'),
};

export const useGetItems = () => {
  const {
    MainStore: {
      productStore: {
        formState: { Car, Drivers, InsuranceParameters, WhoIsDriver },
      },
    },
  } = useStores();

  const marketPrice = Car?.marketPrice;

  const minDriverAgeFromDrivers = useMemo(
    () => getMinAge(WhoIsDriver?.drivers),
    [WhoIsDriver?.drivers]
  );

  const minDriverExperienceFromDrivers = useMemo(
    () => getMinExperienceAge(WhoIsDriver?.drivers),
    [WhoIsDriver?.drivers]
  );

  const minDriverAge = minDriverAgeFromDrivers ?? Drivers?.minDriverAge;
  const minDriverExperience =
    minDriverExperienceFromDrivers ?? Drivers?.minDriverExperience;
  const period = InsuranceParameters?.period?.period ?? '';
  const franchise = InsuranceParameters?.franchise ?? '';

  return [
    {
      title: i18n.t('COMMON:labels.compensation'),
      value: formattingPrice(Number(marketPrice)),
    },
    {
      title: i18n.t('COMMON:labels.ageAndExperience'),
      value: `${declineWord(Number(minDriverAge), DECLINATION)} · ${declineWord(Number(minDriverExperience), DECLINATION)}`,
    },
    {
      title: i18n.t('COMMON:labels.period'),
      value: formattingPeriod(period),
    },
    {
      title: i18n.t('COMMON:labels.franchise'),
      value: formattingFranchise(franchise),
    },
  ];
};
