export const analyticEvents = {
  webStart: 'Web_Start', // При загрузке анкеты
  start: 'Kasko_Start', // При взаимодействии с ЛЮБЫМ одним элементом этого экрана
  regNumberSelected: 'Kasko_RegNumber_Selected', // Введен рег номер для поиска данных об авто
  regNumberSearch: 'Kasko_RegNumber_Search', // Клик “Найти авто”
  selectCarData: 'Kasko_Select_Car_Data', // Клик “Указать данные авто”
  regNumberNotReceived: 'Kasko_RegNumber_Not_Received', // Клик “Гос номер не получен”
  regNumberFound: 'Kasko_RegNumber_Found', // Данные по авто после ввода рег номера найдены
  regNumberNotFound: 'Kasko_RegNumber_NotFound', // Не удалось получить данные по авто
  changeCarData: 'Kasko_Change_Car_Data', // Клик на “изменить”
  regNumberSelectedError: 'Kasko_RegNumber_Selected_Error', // Не удалось получить данные по авто
  regNumberNotAllDataFound: 'Kasko_RegNumber_Not_All_Data_Found', // Данные по авто после ввода рег номера не найдены
  carBrandSelected: 'Kasko_Car_Brand_Selected', // Выбрана марка авто
  carModelSelected: 'Kasko_Car_Model_Selected', // Выбрана модель авто
  carYearSelected: 'Kasko_Car_Year_Selected', // Выбран год выпуска авто
  carPowerSelected: 'Kasko_Car_Power_Selected', // Выбрана мощность авто
  carSumSelected: 'Kasko_Car_Sum_Selected', // Выбрана стоимость авто
  carMileageSelected: 'Kasko_Car_Mileage_Selected', // Выбран пробег авто
  carVinSelected: 'Kasko_Car_Vin_Selected', // Введен вин
  validKasko: 'Kasko_Valid_Kasko', // Проставлена галочка “есть действующий полис“
  kreditAvto: 'Kasko_Kredit_Avto', // Проставлена галочка “автомобиль в кредите”
  minAgeSelected: 'Kasko_MinAge_Selected', // Введен минимальный возраст водителей
  minExperienceSelected: 'Kasko_MinExperience_Selected', // Введен минимальный стаж водителей
  toStepData: 'Kasko_To_Step_Data', // Клик на Продолжить
  changeCarDataAccepted: 'Kasko_Change_Car_Data_Accepted', // Клик на сохранить изменения
  regionSelected: 'Kasko_Region_Selected', // Выбран регион использования ТС
  periodSelected: 'Kasko_Period_Selected', // Выбран срок страхования
  dateSelected: 'Kasko_Date_Selected', // Выбрана дата начала договора
  franchiseSelected: 'Kasko_Franchise_Selected', // Выбрана франшиза
  toStepRisk: 'Kasko_To_Step_Risk', // Переход - Продолжить
  riskGapSelected: 'Kasko_Risk_GAP_Selected', // Выбран доп риск GAP
  toStepPersonalData: 'Kasko_To_Step_Personal_Data', // Переход - Продолжить
  insurantDataSelected: 'Kasko_Insurant_Data_Selected', // Введены ВСЕ персональные данные Страхователя
  insurantPhoneEntered: 'Kasko_Insurant_Phone_Entered', // Введен номер телефона для регистрации
  insurantMailEntered: 'Kasko_Insurant_Mail_Entered', // Введен адрес почты для регистрации
  authSignupStart: 'Kasko_Auth_Signup_Start', // Начало авторизации зарегистрированного пользователя
  authRegistrationStart: 'Kasko_Auth_Registration_Start', // Начало регистрации нового пользователя
  authSignupSuccess: 'Kasko_Auth_Signup_Success', // По введенному номеру телефона подтянулся уже существующий пользователь
  authRegistrationSuccess: 'Kasko_Auth_Registration_Success', // Успешно зарегистрирован новый пользователь
  insurantisOwner: 'Kasko_Insurant_is_Owner', // Проставлена галочка страхователь -> собственник
  ownerDataSelected: 'Kasko_Owner_Data_Selected', // Введена вручную ВСЯ инфо о Владельце
  carDocEntered: 'Kasko_CarDoc_Entered', // Введена вся инфо - документ ТС
  driverSelected: 'Kasko_Driver_Selected', // Введена ВСЯ инфо первого водителя
  driverAdded: 'Kasko_Driver_Added', // Добавлен водитель и введена ВСЯ инфо о нем
  toStepDocs: 'Kasko_To_Step_Docs', // Клик на Продолжить
  decline: 'Kasko_Decline', // Отказ от онлайн-оформления
  toStepPay: 'Kasko_To_Step_Pay', // Клик на Продолжить
  paymentSuccess: 'Kasko_Payment_Success', // Прошла оплата и выведен экран успешной оплаты
  paymentDecline: 'Kasko_Payment_Decline', // Неудачная попытка оплаты
} as const;
