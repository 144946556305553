import { Button } from '@pulse-web-ui/button';
import { media, styled } from '@pulse-web-ui/theme';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 24px;
`;

export const LeftSlotContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${({ theme }) => theme.colors.neutral[10]};
  border-radius: calc(${({ theme }) => theme.common.radius} * 8);
  color: ${({ theme }) => theme.colors.text.interactive};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${media.desktop} {
    flex-direction: row;
    column-gap: 12px;
  }
`;

export const FlexItem = styled.div`
  flex: 1;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${media.desktop} {
    flex-direction: row;
    justirfy-content: space-between;
    column-gap: 24px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const NotRegistredButton = styled(Button)`
  margin-top: 12px;

  ${media.desktop} {
    margin-top: 7px;
  }
`;
