import { Modal } from '@pulse-web-ui/modal';
import { StateBlock } from '@pulse-web-ui/state-block';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

const InfoIcon = lazy(() => import('@shared/icons/info'));

export const GlobalError = observer(() => {
  const {
    MainStore: {
      errorStore: { hasError, refetch, isLoading },
    },
  } = useStores();
  const { t } = useTranslation();

  if (!hasError) return;

  const errorHandler = () => {
    if (refetch instanceof Function) {
      refetch();
    }
  };

  return (
    <Modal showDialog={hasError} closeIcon={false} dangerouslyBypassScrollLock>
      <StateBlock
        title={t('COMMON:headers.globalError')}
        icon={
          <Suspense>
            <InfoIcon />
          </Suspense>
        }
        submitButtonText={t('COMMON:labels.tryAgain')}
        onSubmit={errorHandler}
        isLoading={isLoading}
      />
    </Modal>
  );
});
