import i18n from '@app/i18n/config';
import { CAR_NUMBER_REG_EXP } from '@shared/constants/reg-ex';
import { boolean, object, number, string } from 'yup';

import type { ValidationContext } from './types';

import {
  MIN_MILEAGE,
  MAX_MILEAGE,
  MIN_LENGTH_VIN,
  ONLY_NUMBERS_REG_EXP,
} from './constants';
import { i18nDefaultValues } from './i18n';

const getCarOptionSchema = (errorMessage: string) =>
  object({
    label: string().optional(),
    value: string().test('is-required', errorMessage, (value, { options }) => {
      const isManualCarInput = options.context;

      if (isManualCarInput) return !!value;

      return true;
    }),
  }).optional();

export const getCarNumberSchema = () =>
  string()
    .transform((value) => value.replace(/[\s_]/g, ''))
    .when((_, schema, options) => {
      if (options.context.isCarNumberRequired) {
        return schema
          .required(
            i18n.t(
              'SMART:Car.errors.required',
              i18nDefaultValues.Car.errors.requiredCarNumber
            )
          )
          .matches(
            CAR_NUMBER_REG_EXP,
            i18n.t(
              'SMART:Car.errors.invalid',
              i18nDefaultValues.Car.errors.invalidCarNmber
            )
          );
      }

      return schema;
    });

export const getCarSchema = () =>
  object({
    carNumber: getCarNumberSchema(),
    marketPrice: number()
      .transform((val, orig) => {
        return orig === '' ? undefined : val;
      })
      .required(
        i18n.t(
          'SMART:Car.errors.requiredMarketPrice',
          i18nDefaultValues.Car.errors.requiredMarketPrice
        )
      )
      .test('is-valid-range', (value, context) => {
        const { options, createError, path, parent } = context;
        const { minPrice, maxPrice, minProductLimit, maxProductLimit } =
          options.context as ValidationContext;

        const { car } = parent;

        const hasCarData =
          !!car &&
          !!car.model?.value &&
          !!car.brand?.value &&
          !!car.manufactureYear?.value &&
          !!car.power?.value;

        const minMarketPrice =
          hasCarData && minPrice ? minPrice : minProductLimit;
        const maxMarketPrice =
          hasCarData && maxPrice ? maxPrice : maxProductLimit;

        if (value >= minMarketPrice && value <= maxMarketPrice) return true;

        return createError({
          path,
          message: i18n.t('SMART:Car.errors.rangeMarketPrice', {
            defaultValue: i18nDefaultValues.Car.errors.rangeMarketPrice,
            minMarketPrice,
            maxMarketPrice,
          }),
        });
      })
      .nullable(),
    mileage: number()
      .transform((val, orig) => {
        return orig === '' ? undefined : val;
      })
      .min(
        MIN_MILEAGE,
        i18n.t(
          'SMART:Car.errors.rangeMileage',
          i18nDefaultValues.Car.errors.rangeMileage
        )
      )
      .max(
        MAX_MILEAGE,
        i18n.t(
          'SMART:Car.errors.rangeMileage',
          i18nDefaultValues.Car.errors.rangeMileage
        )
      )
      .required(
        i18n.t(
          'SMART:Car.errors.requiredMileage',
          i18nDefaultValues.Car.errors.requiredMileage
        )
      ),
    vin: string()
      .transform((val) => val.trim())
      .required(
        i18n.t(
          'SMART:Car.errors.requiredVIN',
          i18nDefaultValues.Car.errors.requiredVIN
        )
      )
      .min(
        MIN_LENGTH_VIN,
        i18n.t(
          'SMART:Car.errors.minLengthMileage',
          i18nDefaultValues.Car.errors.minLengthMileage
        )
      )
      .test(
        'is-not-only-numbers',
        i18n.t(
          'SMART:Car.errors.formatVIN',
          i18nDefaultValues.Car.errors.formatVIN
        ),
        (value) => !ONLY_NUMBERS_REG_EXP.test(value)
      ),
    haveValidPolicy: boolean(),
    carOnCredit: boolean(),
    isValid: boolean(),
    isManualVINInput: boolean(),
    car: object({
      model: getCarOptionSchema(
        i18n.t(
          'SMART:Car.errors.requiredModel',
          i18nDefaultValues.Car.errors.requiredModel
        )
      ),
      power: getCarOptionSchema(
        i18n.t(
          'SMART:Car.errors.requiredPower',
          i18nDefaultValues.Car.errors.requiredPower
        )
      ),
      brand: getCarOptionSchema(
        i18n.t(
          'SMART:Car.errors.requiredBrand',
          i18nDefaultValues.Car.errors.requiredBrand
        )
      ),
      manufactureYear: getCarOptionSchema(
        i18n.t(
          'SMART:Car.errors.requiredManufactureYear',
          i18nDefaultValues.Car.errors.requiredManufactureYear
        )
      ),
    }).optional(),
  });
