import { Endpoints } from '@shared/constants';
import type { VehicleData } from '@shared/types';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

interface Params {
  code?: string;
  brandId?: string;
  modelId?: string;
  yearId?: string;
}

interface GetPowersResponse {
  requestId: string;
  data: VehicleData[];
}

export const useGetPowers = ({ code, brandId, modelId, yearId }: Params) => {
  return useQuery(
    ['get-powers', code, brandId, modelId, yearId],
    fetchData<GetPowersResponse>({
      url: `${Endpoints.GET_BRANDS}/${brandId}/models/${modelId}/powers?year=${yearId}&productVersion=${code}`,
      method: 'GET',
    }),
    { enabled: !!code && !!brandId && !!modelId && !!yearId }
  );
};
