import { Step } from '@pulse-web-ui/stepper';
import { useTranslation } from 'react-i18next';
import { type FC, Suspense, memo } from 'react';
import { getDefaultValue, lazyIcons } from './smart-header.helpers';

import type { HeaderItemProps } from './smart-header.types';

export const SmartHeaderItem: FC<HeaderItemProps> = memo(
  ({ item: { icon, name }, value }) => {
    const { t } = useTranslation();
    const Icon = lazyIcons[icon];
    const defaultValue = getDefaultValue(name);

    return (
      <Step
        label={t(`SMART:SmartHeader.labels.${name}`, {
          defaultValue,
        })}
        content={
          <Suspense>
            <Icon color="currentColor" />
          </Suspense>
        }
        value={value}
      />
    );
  }
);

SmartHeaderItem.displayName = 'SmartHeaderItem';
