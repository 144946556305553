import type { GetSubmitOrderRequest } from '../types';
import { INSURANSE_SUM_DEFAULT } from '../constants';
import { getRisks } from './get-risks';
import { getFormattedDate, getOptions, prepareDate } from '@shared/utils';
import { differenceInDays } from 'date-fns';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

const birthDate = new Date(today);
birthDate.setFullYear(tomorrow.getFullYear() - 30);

const RUSSIA_COUNTRY_CODE = '643';
const TERRITORIES_DEFAULT = [RUSSIA_COUNTRY_CODE];

export const getOrderData = ({
  formState,
  initState,
  currency,
  promoCode,
  agentLogin,
}: {
  formState: any;
  initState: any;
  currency: any;
  promoCode: string;
  agentLogin?: string;
}) => {
  const dateBegin = new Date(
    formState?.WhereAndHowLong?.travelDates?.start || new Date()
  );

  const dateEnd = new Date(
    formState?.WhereAndHowLong?.travelDates?.end || tomorrow
  );

  const insuranceSum = Number(
    formState?.WhoAndHow?.coverageSum || INSURANSE_SUM_DEFAULT
  );

  const territoriesFromStore = formState?.WhereAndHowLong?.countries?.map(
    (country: any) => country.value
  );
  const territories =
    territoriesFromStore?.length > 0
      ? territoriesFromStore
      : TERRITORIES_DEFAULT;

  const durationInDays = differenceInDays(dateEnd, dateBegin) + 1;

  const insuranceObjects =
    formState?.WhoAndHowPaperwork?.travelers?.map(
      (traveler: { birthday: Date; name: string; surname: string }) => {
        return {
          objectType: 'TOURIST',
          firstName: traveler.name,
          lastName: traveler.surname,
          birthDate: getFormattedDate(traveler.birthday || new Date()),
        };
      }
    ) || [];
  const numberOfDays = formState?.WhereAndHowLong?.policy
    ? formState?.WhereAndHowLong?.travelDates?.duration
    : differenceInDays(dateEnd, dateBegin) + 1;

  const sportHas = formState?.WhoAndHow?.sportHas;
  const sportKinds = formState?.WhoAndHow?.sportKinds || [];
  const activities = sportHas
    ? sportKinds.map((sport: { value: string }) => sport.value)
    : [];

  const data: GetSubmitOrderRequest = {
    baseParameters: {
      effectiveSince: prepareDate(dateBegin),
      productCode: initState.code || '',
      contractDuration: formState?.WhereAndHowLong?.policy
        ? 'P1Y'
        : `P${durationInDays}D`,
      riskObjects: getRisks({
        fullOptionsRisks: formState?.WantImprovePolicy?.fullOptionsRisks || [],
        coverageSum: formState?.WhoAndHow?.coverageSum || INSURANSE_SUM_DEFAULT,
      }),
      promoCode: promoCode || undefined,
      ...(!!agentLogin && { agentLogin }),
    },
    productProperty: {
      kind: 'TRAVEL',
      numberOfDays: Number(numberOfDays),
      annualPolicy: formState?.WhereAndHowLong?.policy || false,
      currency,
      activities,
      territories,
      options: getOptions({
        fullOptionsRisks: formState?.WantImprovePolicy?.fullOptionsRisks || [],
        traveling: Boolean(formState?.WhereAndHowLong?.traveling),
      }),
      insuranceSum,
      insuranceObjects,
    },
  };

  return data;
};
