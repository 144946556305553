/* eslint-disable */
import { media, styled } from '@pulse-web-ui/theme';

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.desktop} {
    flex-direction: row;
  }
`;

export const RowSection = styled.div<{ isHalf?: boolean }>`
  display: flex;
  width: 100%;

  ${media.desktop} {
    flex: ${(props) => (props.isHalf ? '0 0 calc(50% - 8px)' : 1)};
  }
  & > * {
    width: 100%;
  }
`;
