import { Endpoints } from '@shared/constants';
import type { VehicleData } from '@shared/types';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

interface GetBrandsResponse {
  requestId: string;
  data: VehicleData[];
}

export const useGetBrands = (code: string | undefined) => {
  return useQuery(
    ['get-brands', code],
    fetchData<GetBrandsResponse>({
      url: `${Endpoints.GET_BRANDS}?productVersion=${code}`,
      method: 'GET',
    }),
    { enabled: false }
  );
};
