import { toNum } from '@shared/utils';

export const getPercentageDiff = (
  premiumAndDelta: string,
  premiumAndDeltaPromo: string
) => {
  const premiumAndDeltaNum = toNum(premiumAndDelta) || 0;
  const premiumAndDeltaPromoNum = toNum(premiumAndDeltaPromo) || 0;

  if (!premiumAndDeltaNum) {
    return 0;
  }

  return Math.round(
    (premiumAndDeltaNum - premiumAndDeltaPromoNum) / (premiumAndDeltaNum / 100)
  );
};
