import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';
import type { FranchiseResponse } from './types';
import { CULPRIT_FRANCHISE } from './constants';

export const useHandleFranchise = (code?: string) => {
  const enabled = Boolean(code);

  return useQuery(
    ['get-franchise'],
    fetchData<FranchiseResponse>({
      url: Endpoints.GET_FRANCHISE,
      method: 'POST',
      data: {
        baseParameters: {
          productVersion: code,
        },
        referenceProperty: {
          franchiseType: CULPRIT_FRANCHISE,
        },
      },
    }),
    { enabled }
  );
};
