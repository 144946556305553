import { styled } from '@pulse-web-ui/theme';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;
