import { StepPanel } from '@pulse-web-ui/stepper';
import { useCallback, useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import type { SmartComponent } from '@shared/types';
import type { Control, ControllerProps, FieldValues } from 'react-hook-form';
import { useStores } from '@shared/hooks';

type Props = {
  component: SmartComponent<any>;
  activeStep: number;
  control: Control<FieldValues, any>;
  isSubmitting: boolean;
  setRef: (e: HTMLDivElement, name: string) => void;
};

type RenderType = ControllerProps['render'];

const rules = {
  validate: (value: any) => Boolean(value?.isValid),
};

export const FormBuilderStepItem: FC<Props> = observer(
  ({
    component: { component: Component, props },
    activeStep,
    control,
    isSubmitting,
    setRef,
  }) => {
    const {
      MainStore: {
        applicationStore: { isSmartComponentsShowing },
      },
    } = useStores();

    const myRef = useRef(null);

    const render: RenderType = useCallback(
      ({ field: { onChange, value, name }, fieldState }) => {
        return (
          <Component
            onChange={onChange}
            value={value}
            name={name}
            ref={myRef}
            key={props.id}
            fieldState={fieldState}
            isSubmitting={isSubmitting}
          />
        );
      },
      [isSubmitting, props.id]
    );

    useEffect(() => {
      if (myRef?.current) setRef(myRef.current, props.name);
    }, [props.name]);

    if (!isSmartComponentsShowing) return;

    return (
      <StepPanel
        value={activeStep}
        index={activeStep}
        key={`${activeStep}${props.id}`}
      >
        <Controller
          rules={rules}
          render={render}
          name={props.id}
          control={control}
        />
      </StepPanel>
    );
  }
);

FormBuilderStepItem.displayName = 'FormBuilderStepItem';
