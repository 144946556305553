import { mixins, styled, typography } from '@pulse-web-ui/theme';

export const Container = styled.div`
  ${mixins.flex({
    horizontal: false,
    gap: 8,
    justifyContent: 'flex-start',
  })}
  padding-bottom: 9px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[30]};
  box-sizing: border-box;
`;

export const Label = styled.div`
  ${typography.regular14};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const Value = styled.div`
  ${typography.bold16};
  color: ${({ theme }) => theme.colors.text.primary};
`;
