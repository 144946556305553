import { Endpoints } from '@shared/constants';
import type { VehicleData } from '@shared/types';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

interface Params {
  code?: string;
  brandId?: string;
}
interface GetModelsResponse {
  requestId: string;
  data: VehicleData[];
}

export const useGetModels = ({ code, brandId }: Params) => {
  return useQuery(
    ['get-models', code, brandId],
    fetchData<GetModelsResponse>({
      url: `${Endpoints.GET_BRANDS}/${brandId}/models?productVersion=${code}`,
      method: 'GET',
    }),
    { enabled: !!code && !!brandId }
  );
};
