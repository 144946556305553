import { AddressSuggestions } from '@pulse-web-ui/dadata';

import { DaDataAddressSuggestionProps } from './types';
import {
  GetDadataSuggestionsRequestData,
  GetDadataSuggestionsResponse,
} from '@shared/queries';
import { useCallback } from 'react';
import { axiosInstance } from '@shared/utils';
import { Endpoints } from '@shared/constants';

export const DaDataAddressSuggestion = (
  props: DaDataAddressSuggestionProps
) => {
  const customRequest = useCallback((data: GetDadataSuggestionsRequestData) => {
    return axiosInstance<GetDadataSuggestionsResponse>({
      url: `${Endpoints.DADATA}/suggest/address`,
      method: 'POST',
      data,
    });
  }, []);

  return (
    <AddressSuggestions
      customRequest={
        customRequest as unknown as (
          dada: Record<string, unknown>
        ) => Promise<GetDadataSuggestionsResponse>
      }
      {...props}
    />
  );
};
