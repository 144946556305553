import { useStores } from '@shared/hooks';
import { useEffect } from 'react';

import type { AnalyticEventValues, Dependence } from '../types';

export const useSendSingleAnalyticEvent = (
  dependence: Dependence,
  event: AnalyticEventValues
) => {
  const {
    MainStore: {
      applicationStore: { sendSingleAnalyticEvent },
    },
  } = useStores();

  useEffect(() => {
    if (dependence) {
      sendSingleAnalyticEvent(event);
    }
  }, [dependence]);
};
