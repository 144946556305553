import { useStores } from '@shared/hooks';
import { useEffect } from 'react';

const CAR_NUMBER = 'А 938 ХО 198';

const logMessage = ({
  component,
  field,
  value,
}: {
  component: string;
  field: string;
  value: string;
}) => {
  console.log(`В '${component}' поле '${field}' изменилось на '${value}'`);
};

export const useDeveloperMode = () => {
  const {
    MainStore: {
      productStore: { formState, setFormState },
    },
  } = useStores();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setFormState({
        ...formState,
        CarNumber: {
          carNumber: CAR_NUMBER,
          isValid: true,
        },
      });

      console.group('Changes in developer mode');
      logMessage({
        component: 'CarNumber',
        field: 'carNumber',
        value: CAR_NUMBER,
      });

      console.groupEnd();
    }
  }, []);
};
